import React, { useMemo } from 'react'
import styled from 'styled-components'
import { v4 as generateUUID } from 'uuid'
import { useNotifications } from 'redux/state/notifications'
import { Button, H4, Input, Label, Modal } from '@chordco/component-library'
import { FormInput, FormTextArea } from 'components/common'
import { usePublishDashboard } from './usePublishDashboard'
import { usePublishLook } from './usePublishLook'
import { useValidateDashboardName } from './useValidateDashboardName'
import { useValidateLookName } from './useValidateLookName'
import { valTypes } from 'validationSchemas'
import { AvailableUserReport, LookerReportType } from 'types'
import { useForm } from 'hooks'

interface PublishReportModalProps {
  reportName: string
  reportDescription: string
  reportSlug: string
  reportType: LookerReportType
  onClose: () => void
  onPublish: (report: AvailableUserReport) => void
  onNameAlreadyTaken: (data: { name: string; description: string; slug: string; type: LookerReportType }) => void
}

export const PublishReportModal: React.FC<PublishReportModalProps> = ({
  reportName,
  reportDescription,
  reportSlug,
  reportType,
  onClose,
  onPublish,
  onNameAlreadyTaken,
}) => {
  const { addNotification } = useNotifications()

  const { validate: validateDashboard } = useValidateDashboardName()
  const { publish: publishDashboard, isLoading: isPublishingDashboard } = usePublishDashboard(onPublish)

  const { validate: validateLook } = useValidateLookName()
  const { publish: publishLook, isLoading: isPublishingLook } = usePublishLook(onPublish)

  const submitDashboard = async () => {
    const dashboardData = {
      name: fields.reportName.value.toString(),
      description: fields.reportDescription.value.toString(),
      slug: fields.reportSlug.value.toString(),
      type: 'dashboard' as LookerReportType,
    }

    const isValid = await validateDashboard(dashboardData.name)

    return isValid
      ? await publishDashboard({
          dashboardName: dashboardData.name,
          dashboardDescription: dashboardData.description,
          dashboardSlug: dashboardData.slug,
          replaceExisting: false,
        })
      : onNameAlreadyTaken(dashboardData)
  }

  const submitLook = async () => {
    const lookData = {
      name: fields.reportName.value.toString(),
      description: fields.reportDescription.value.toString(),
      slug: fields.reportSlug.value.toString(),
      type: 'look' as LookerReportType,
    }

    const isValid = await validateLook(lookData.name)

    return isValid
      ? await publishLook({
          lookName: lookData.name,
          lookDescription: lookData.description,
          lookSlug: lookData.slug,
          replaceExisting: false,
        })
      : onNameAlreadyTaken(lookData)
  }

  const schema = {
    reportName: {
      label: 'Report Name',
      type: valTypes.string,
      initialValue: reportName,
      required: true,
    },
    reportDescription: {
      label: 'Report Description',
      type: valTypes.string,
      initialValue: reportDescription,
      required: false,
    },
    reportSlug: {
      label: 'Slug',
      type: valTypes.string,
      initialValue: reportSlug,
      required: true,
    },
  }

  const { fields, onSubmit } = useForm(schema, reportType === 'look' ? submitLook : submitDashboard)

  const link = useMemo(() => {
    return `${window.location.origin}/my-reports/${reportSlug}?type=${reportType}`
  }, [reportSlug, reportType])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link)

    addNotification({
      id: generateUUID() as string,
      type: 'success',
      message: 'Report link successfully copied to clipboard.',
    })
  }

  return (
    <Modal onClose={onClose} title="Share your Reports" width="544px">
      <SectionContainer>
        <Section>
          <H4>Share with other Chord users</H4>
          <StyledLabel>
            Share this link with other Chord users to give them access to this report. This will create a new copy of
            the report in their personal folder.
          </StyledLabel>

          <Row>
            <Input defaultValue={link} customWidth="100%" />
            <StyledCopyButton type="submit" purpose="secondary" onClick={copyToClipboard}>
              Copy Link
            </StyledCopyButton>
          </Row>
        </Section>

        <form onSubmit={onSubmit} noValidate>
          <Section>
            <H4>Share with your entire team</H4>
            <StyledLabel>
              The copy of your custom report will appear in <strong>Analytics</strong> &gt;
              <strong>Shared Reports</strong>.
            </StyledLabel>
            <FormInput field={fields.reportName} customWidth="100%" required />
            <FormTextArea field={fields.reportDescription} customWidth="100%" />
          </Section>

          <BottomSection>
            <Button type="submit" purpose="primary" isLoading={isPublishingDashboard || isPublishingLook}>
              Copy to Shared Reports
            </Button>
          </BottomSection>
        </form>
      </SectionContainer>
    </Modal>
  )
}

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const StyledCopyButton = styled(Button)`
  min-width: 110px;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
