import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import {
  LookerReportType,
  PublishDashboardResponse,
  PublishLookResponse,
} from 'types'
import { PayloadAction } from '@reduxjs/toolkit'

interface PinReportData {
  reportId: string
  reportType: LookerReportType
}

export const usePinReport = () => {
  const { pinDashboard, pinLook } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const pin = async ({ reportId, reportType }: PinReportData) => {
    if (!pinDashboard || !pinLook) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      if (reportType === 'look') {
        const response = (await pinLook(reportId)) as PayloadAction<{
          data: PublishLookResponse
        }>

        if (response.payload.data?.look) {
          setHasSucceeded(true)
        } else {
          setHasFailed(true)
        }
      } else if (reportType === 'lookml_dashboard' || reportType === 'dashboard') {
        const response = (await pinDashboard(reportId)) as PayloadAction<{
          data: PublishDashboardResponse
        }>

        if (response.payload.data?.dashboard) {
          setHasSucceeded(true)
        } else {
          setHasFailed(true)
        }
      } else {
        throw new Error('Invalid report type')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { pin, isLoading, hasSucceeded, hasFailed }
}
