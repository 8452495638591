import React from 'react'
import { AlertModal, standardIcons } from '@chordco/component-library'
import { useUnpinReport } from 'components/looker/useUnpinReport'
import { AvailableUserReport, AvailableReport } from 'types'

type UnpinModalProps = {
  report: AvailableUserReport | AvailableReport
  onUnpin: () => void
  onClose: () => void
}

export const UnpinModal: React.FC<UnpinModalProps> = ({
  report,
  onClose,
  onUnpin,
}) => {
  const { unpin, isLoading } = useUnpinReport()

  const handleUnpin = async () => {
    await unpin({ slug: report.slug, reportType: report.metricType })
    onUnpin()
  }

  return (
    <AlertModal
      onClose={onClose}
      icon={standardIcons.Unpin}
      content={`Please confirm that you want to unpin the report "${report.label}"`}
      centered
      buttons={[
        {
          id: 1,
          text: 'No',
          onClick: onClose,
          purpose: 'secondary',
          disabled: isLoading,
        },
        {
          id: 2,
          text: 'Yes, unpin it',
          onClick: handleUnpin,
          purpose: 'primary',
          isLoading: isLoading,
          disabled: isLoading,
        },
      ]}
    />
  )
}
