import React from 'react'
import styled from 'styled-components'
import { CdpProviderErrors } from '../provider/CdpProvider'
import { H5 } from '@chordco/component-library'

type ErrorsProps = {
  errors: CdpProviderErrors
}

export const Errors: React.FC<ErrorsProps> = ({ errors }) => {
  return (
    <ErrorContainer>
      <H5>Errors occurred while fetching your data:</H5>
      {errors.streams && <p>{errors.streams}</p>}
      {errors.destinations && <p>{errors.destinations}</p>}
      {errors.connectors && <p>{errors.connectors}</p>}
      {errors.links && <p>{errors.links}</p>}
    </ErrorContainer>
  )
}

const ErrorContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${p => p.theme.RED_30};
  border-radius: 8px;
  color: ${p => p.theme.GREY_80};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;

  & > span {
    cursor: pointer;
  }
`
