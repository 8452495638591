import React, { useEffect } from 'react'
import { Section } from 'components/common'
import { useIsMobile } from 'hooks'
import { DestinationCard } from './DestinationCard'
import { StreamCard } from './StreamCard'
import { ConnectionsDiagram } from './ConnectionDiagram'
import { ConnectorCard } from './ConnectorCard'
import { useCdp } from '../provider/CdpProvider'
import { Errors } from './Errors'

type OverviewProps = {}

export const Overview: React.FC<OverviewProps> = () => {
  const isMobile = useIsMobile()

  const {
    streams,
    destinations,
    connectors,
    links,
    loadingStates,
    errors,
    fetchStreams,
    fetchDestinations,
    fetchConnectors,
    fetchLinks,
  } = useCdp()

  useEffect(() => {
    const fetchAllDataAsync = async () => {
      fetchStreams()
      fetchDestinations()
      fetchConnectors()
      fetchLinks()
    }

    fetchAllDataAsync()
  }, [fetchStreams, fetchDestinations, fetchConnectors])

  const handleStreamSelection = (stream: any) => {
    // eslint-disable-next-line no-console
    console.log(stream)
  }

  const handleDestinationSelection = (destination: any) => {
    // eslint-disable-next-line no-console
    console.log(destination)
  }

  const handleConnectorSelection = (connector: any) => {
    // eslint-disable-next-line no-console
    console.log(connector)
  }

  const handleAddNewConnector = () => {
    // eslint-disable-next-line no-console
    console.log('Add new connector')
  }

  const handleAddNewDestination = () => {
    // eslint-disable-next-line no-console
    console.log('Add new destination')
  }

  const handleAddNewSource = () => {
    // eslint-disable-next-line no-console
    console.log('Add new source')
  }

  const isLoading =
    loadingStates.streams ||
    loadingStates.destinations ||
    loadingStates.connectors ||
    loadingStates.links

  const hasErrors = errors.streams || errors.destinations || errors.connectors || errors.links

  return (
    <Section isMobile={isMobile} hasBorder={false} loading={isLoading}>
      {!isLoading && !hasErrors && (
        <ConnectionsDiagram
          connectorSources={connectors.map(connector => ({
            id: connector.id,
            card: (forceSelect: boolean) => (
              <ConnectorCard
                selected={forceSelect}
                key={connector.id}
                connector={connector}
                onSelectConnector={handleConnectorSelection}
              />
            ),
          }))}
          sources={streams.map(stream => ({
            id: stream.id,
            card: (forceSelect: boolean) => (
              <StreamCard
                selected={forceSelect}
                key={stream.id}
                stream={stream}
                onSelectStream={handleStreamSelection}
              />
            ),
          }))}
          destinations={destinations.map(destination => ({
            id: destination.id,
            card: (forceSelect: boolean) => (
              <DestinationCard
                selected={forceSelect}
                key={destination.id}
                destination={destination}
                onSelectDestination={handleDestinationSelection}
              />
            ),
          }))}
          connections={links.map(link => ({
            from: link.fromId,
            to: link.toId,
          }))}
          onAddNewConnector={handleAddNewConnector}
          onAddNewDestination={handleAddNewDestination}
          onAddNewSource={handleAddNewSource}
        />
      )}

      {!isLoading && hasErrors && <Errors errors={errors} />}
    </Section>
  )
}
