import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { H1, H2, TabBar } from '@chordco/component-library'
import { useIsMobile, useAnalyticsTabsHelper } from 'hooks'
import { useLookerData } from 'redux/state/looker'
import { Hr } from '../common'
import { BrowserWarning } from './BrowserWarning'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import { useIsLoading } from 'redux/utils'
import { getAudienceTabs } from './tabs'
import { useTenantsData } from 'redux/state/tenants'
import { useAuthData } from 'redux/state/auth'
import PrebuiltAudiences from './audiences/PrebuiltAudiences'
import { MyAudiences } from './audiences/MyAudiences'
import useAudienceSlug from 'hooks/useAudienceSlug'
import { AddNewAudience } from './audiences/AddNewAudience'

export interface AudiencesFeatureFlags {
  showMyAudiences: boolean
}

interface AudiencesProps {
  featureFlags: AudiencesFeatureFlags
}

export const Audiences: React.FC<AudiencesProps> = ({ featureFlags }) => {
  const { showMyAudiences } = featureFlags

  const history = useHistory()
  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const {
    state: { currentTenant },
  } = useTenantsData()

  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')
  if (!currentTenant) throw new Error('No current tenant')

  const {
    getAvailableUserAudiences,
    state: { availableAudiences, availableUserAudiences },
  } = useLookerData()

  const tabs = useMemo(() => getAudienceTabs(showMyAudiences), [showMyAudiences])

  const [selectedTabId, setSelectedTabId] = useAnalyticsTabsHelper(tabs)

  const { isMyAudiences, isPrebuiltAudiences } = useMemo(() => {
    const path = history.location.pathname
    return {
      isMyAudiences: path.includes('my-audiences'),
      isPrebuiltAudiences: path.includes('prebuilt-audiences'),
    }
  }, [history.location.pathname])

  // This hook will retrieve the slug for the current audience. If the slug cannot be found
  // in the path, it will default to the first available audience for the current active tab.
  const slug = useAudienceSlug(isMyAudiences, availableAudiences, availableUserAudiences)

  const isRefreshingUserAudiences = useIsLoading('getAvailableUserAudiences')

  // Refresh user audiences when the user navigates to the My Audiences tab
  useEffect(() => {
    if (isMyAudiences && getAvailableUserAudiences) {
      getAvailableUserAudiences()
    }
  }, [isMyAudiences])

  const handleDeleteAudience = async () => {
    if (getAvailableUserAudiences) {
      getAvailableUserAudiences().then(() => {
        history.push('/audiences/my-audiences')
      })
    }
  }

  const handleActivateAudience = async () => {
    history.push('/data-activations')
  }

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <Wrapper navOpen={navOpen}>
          <Header>
            <Heading>Audiences</Heading>
            {selectedTabId && (
              <TabBar
                tabs={tabs}
                selectedId={selectedTabId}
                setSelectedId={setSelectedTabId}
                resetOnInvalidSelection
              />
            )}
            <Hr isMobile={isMobile} />

            <BrowserWarning />
          </Header>

          <EmbedContainer>
            {isPrebuiltAudiences && <PrebuiltAudiences />}

            {isMyAudiences &&
              !isRefreshingUserAudiences &&
              (slug ? (
                <MyAudiences
                  slug={slug}
                  options={availableUserAudiences.userAudiences}
                  onDeleteAudience={handleDeleteAudience}
                  onActivateAudience={handleActivateAudience}
                />
              ) : (
                <AddNewAudience />
              ))}
          </EmbedContainer>
        </Wrapper>
      )}
    </NavigationContext.Consumer>
  )
}

const Wrapper = styled.div<{ navOpen: boolean }>`
  color: ${p => p.theme.ContentPrimary};
  height: 100vh;
  width: ${p => (p.navOpen ? 'calc(100vw - 280px)' : '100vw')};
  position: fixed;
  top: 0;
  right: 0;

  // Ensure the content is scrollable due to the fixed position
  overflow-y: scroll;
  overflow-x: hidden;
`

const Header = styled.div`
  padding: 24px 32px 0px 64px;
`

const EmbedContainer = styled.div`
  width: 100%;
  height: 100%;
`
