import React from 'react'
import { Box, Typography } from '@mui/material'
import { SearchOff } from '@mui/icons-material'

interface NoEventsProps {
  title?: string
  description?: string
}

export const NoEvents: React.FC<NoEventsProps> = ({
  title = 'No events found',
  description = 'Try changing your filters or refreshing the page',
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: theme => theme.spacing(4),
      }}
      role="alert"
      aria-label={title}
    >
      <SearchOff sx={{ fontSize: 64, color: 'grey.500' }} />
      <Typography variant="h4">{title}</Typography>
      <Typography variant="subtitle1" sx={{ color: 'grey.700' }}>
        {description}
      </Typography>
    </Box>
  )
}
