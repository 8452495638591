import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'quickstartsFilter'

export enum QuickstartType {
  ALL_REPORTS = 'All Reports',
  DASHBOARDS = 'Dashboards',
  LOOKS = 'Looks',
}

export enum QuickstartCategory {
  ALL_CATEGORIES = 'All Categories',
}

interface InitialState {
  selectedType: QuickstartType
  selectedCategory: QuickstartCategory
  searchTerm?: string
}

export interface QuickstartFilters {
  selectedType: QuickstartType
  selectedCategory: QuickstartCategory
  searchTerm?: string
}

const initialState: InitialState = {
  selectedType: QuickstartType.ALL_REPORTS,
  selectedCategory: QuickstartCategory.ALL_CATEGORIES,
  searchTerm: '',
}

const quickstartsFilterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<QuickstartFilters>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetFilters: () => initialState,
  },
})

export const { setFilters } = quickstartsFilterSlice.actions

export default quickstartsFilterSlice.reducer

export const useQuickstartsFilter = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    setFilters: (filters: QuickstartFilters) => {
      dispatch(setFilters(filters))
    },
  }
}
