import styled from 'styled-components'
import SimpleBar from 'simplebar-react'

const BaseScrollableContainer = styled(SimpleBar)`
  height: 100%;
  padding: 0;
  margin: 0;

  .simplebar-scrollbar::before {
    background-color: ${p => p.theme.BorderStrong};
  }
`

interface ScrollableContainerProps {
  height?: string
  padding?: string
  margin?: string
}

const ScrollableContainer = styled(BaseScrollableContainer)<ScrollableContainerProps>`
  height: ${p => p.height || '100%'};
  padding: ${p => p.padding || 0};
  margin: ${p => p.margin || 0};
`

export default ScrollableContainer
