import { valTypes } from './index'

export const loginSchema = {
  email: {
    label: 'Email',
    type: valTypes.email,
    required: true,
  },
}

export const acceptSchema = {
  firstName: {
    label: 'First Name',
    type: valTypes.string,
    required: true,
  },
  lastName: {
    label: 'Last Name',
    type: valTypes.string,
    required: true,
  },
}

export const forgotPasswordSchema = {
  email: {
    label: 'Email',
    type: valTypes.email,
    required: true,
  },
}
