import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ArrowDiagonal: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0798 5.51139C15.0629 5.12277 14.7517 4.81157 14.3631 4.79467L5.91521 4.75729C5.50139 4.7393 5.15134 5.06018 5.13334 5.47401C5.11535 5.88783 5.43624 6.23788 5.85006 6.25587L12.5515 6.26237L5.13814 13.6757C4.84525 13.9686 4.84525 14.4435 5.13814 14.7364C5.43104 15.0292 5.90591 15.0292 6.1988 14.7364L13.6121 7.32302L13.6186 14.0244C13.6366 14.4383 13.9867 14.7591 14.4005 14.7412C14.8143 14.7232 15.1352 14.3731 15.1172 13.9593L15.0798 5.51139Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
