import React from 'react'
import {
  Box,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@mui/material'
import { ParsedDestinationEventRow } from 'components/chord-cdp/events/destinations'
import { NoEvents } from 'components/chord-cdp/common/NoEvents'

type DestinationEventsTableProps = {
  rows: ParsedDestinationEventRow[]
  onRowClick: (row: ParsedDestinationEventRow) => void
}

export const DestinationEventsTable: React.FC<DestinationEventsTableProps> = ({
  rows = [],
  onRowClick,
}) => {
  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <TableContainer component={Paper}>
        <Table aria-label="Incoming Events" size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'grey.200' }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Date (UTC)</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Batch Size</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Table Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <NoEvents />
                </TableCell>
              </TableRow>
            )}

            {rows.length > 0 &&
              rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? 'white' : 'grey.50',
                    cursor: 'pointer',
                  }}
                  onClick={() => onRowClick(row)}
                >
                  <TableCell sx={{ width: 120, minWidth: 120 }}>{row.date}</TableCell>
                  <TableCell sx={{ width: 80, textAlign: 'center' }}>{row.batchSize}</TableCell>
                  <TableCell sx={{ width: 140, textTransform: 'capitalize' }}>
                    <Tooltip title={`Status: ${row.status}`} placement="top">
                      <Chip
                        label={row.status}
                        variant="filled"
                        color={row.status === 'COMPLETED' ? 'success' : 'error'}
                        size="small"
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: 200 }}>{row.tableName}</TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: 200,
                    }}
                  >
                    {row.summary}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
