import { AnalyticsTab } from 'hooks'

export const getReportTabs = (): AnalyticsTab[] => [
  {
    id: 1,
    slug: 'pinned-reports',
    title: 'Pinned Reports',
    link: '/analytics/pinned-reports',
  },

  {
    id: 2,
    slug: 'my-reports',
    title: 'My Reports',
    link: '/analytics/my-reports',
  },

  {
    id: 3,
    slug: 'shared-reports',
    title: 'Shared Reports',
    link: '/analytics/shared-reports',
  },
  {
    id: 4,
    slug: 'explores',
    title: 'Explores',
    link: '/analytics/explores',
  },
  {
    id: 5,
    slug: 'quickstarts',
    title: 'Quickstarts',
    link: '/analytics/quickstarts',
  },
]

export const getAudienceTabs = (showMyAudiences: boolean): AnalyticsTab[] => [
  ...(showMyAudiences
    ? [
        {
          id: 1,
          slug: 'my-audiences',
          title: 'My Audiences',
          link: '/audiences/my-audiences',
        },
      ]
    : []),
  {
    id: 2,
    slug: 'prebuilt-audiences',
    title: 'Prebuilt Audiences',
    link: '/audiences/prebuilt-audiences',
  },
]
