import { createSlice } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import { RolePermissionDescriptionState } from 'types'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'rolePermissionDescriptions'

const initialState: RolePermissionDescriptionState = {
  rolePermissionDescriptions: [],
}

const { getRolePermissionDescriptions } = reduxApiClient

const rolesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetRolePermissionDescriptions: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getRolePermissionDescriptions.fulfilled, (state, action) => {
      state.rolePermissionDescriptions = action.payload.data
    })
  },
})

export default rolesSlice.reducer

export const useRolePermissionDescriptionsData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    getRolePermissionDescriptions: (tenantId: number) => {
      dispatch(getRolePermissionDescriptions(tenantId))
    },
  }
}
