import React, { useMemo } from 'react'
import moment from 'moment'
import { Chip, standardIcons } from '@chordco/component-library'
import { AvailableReport } from 'types'
import { EnvVarConfig } from 'utils/envVarConfig'
import {
  Card,
  CardContent,
  Header,
  StyledIcon,
  TextContainer,
  Title,
  ChipContainer,
  Description,
  PinButton,
} from 'components/analytics/reports/common'
import useCanPinReport from 'redux/hooks/useCanPinReport'
import usePinnedReport from 'redux/hooks/usePinnedReport'

type ReportCardProps = {
  report: AvailableReport
  onSelectReport: (report: AvailableReport) => void
  onPinReport?: (report: AvailableReport) => void
  onUnpinReport?: (report: AvailableReport) => void
}

const IS_NEW_THRESHOLD = parseInt(EnvVarConfig.REACT_APP_NEW_DASHBOARD_DAYS_THRESHOLD) || 3

export const ReportCard: React.FC<ReportCardProps> = ({ report, onSelectReport, onPinReport, onUnpinReport }) => {
  const { isNew, description } = useMemo(() => {
    return {
      isNew: moment().diff(moment(report.createdAt), 'days') <= IS_NEW_THRESHOLD,
      description: report.description,
    }
  }, [report.createdAt, report.description])

  const canPinUnpin = useCanPinReport()

  const { isPinned } = usePinnedReport(report.slug)

  const handlePinClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (onPinReport) {
      onPinReport(report)
    }
  }

  const handleUnpinClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (onUnpinReport) {
      onUnpinReport(report)
    }
  }

  return (
    <Card onClick={() => onSelectReport(report)}>
      <CardContent>
        <Header>
          <StyledIcon
            icon={report.metricType === 'look' ? standardIcons.Reports : standardIcons.Metrics}
            purpose="ghost"
            size="large"
          />
          <TextContainer>
            <ChipContainer>
              {report.createdBy && (
                <Chip
                  text={`By ${report.createdBy.firstName} ${report.createdBy.lastName.slice(0, 1)}.`}
                  color="neutral"
                  variant="solid-secondary"
                />
              )}
              {isNew && <Chip text="New" color="limeGreen" variant="solid" />}
            </ChipContainer>

            <Title>{report.label}</Title>
            {description && <Description>{description}</Description>}

            {canPinUnpin && !isPinned && (
              <PinButton purpose="ghost" icon={standardIcons.Pin} onClick={handlePinClick} />
            )}

            {canPinUnpin && isPinned && (
              <PinButton
                purpose="ghost"
                icon={isPinned ? standardIcons.Unpin : standardIcons.Pin}
                onClick={handleUnpinClick}
              />
            )}
          </TextContainer>
        </Header>
      </CardContent>
    </Card>
  )
}
