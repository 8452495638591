import React, { useCallback, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import { useIsMobile } from 'hooks'
import { ConnectCard, ConnectedSource } from 'api/fivetran/interfaces'
import {
  Button,
  H4,
  Spinner,
  standardIcons,
  useTheme,
} from '@chordco/component-library'
import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'
import { useFivetran } from '../FivetranContext'

import { AddSourceSheet, EditSourceSheet } from '../sheets'
import { ConnectedSources } from '../components'

import { useEnvironmentsData } from 'redux/state/environments'

import { actions, reducer, initialState } from '../store'
import { ChordDataConnector } from 'types'
import useUserRole from 'redux/hooks/useUserRole'

const { CloseX } = standardIcons

export const MyDataSources: React.FC = () => {
  const userRole = useUserRole()

  const theme = useTheme()
  const { fivetranClient, destinationGroupId, fivetranConnectors, isModeled } =
    useFivetran()

  const [state, dispatch] = useReducer(reducer, initialState)

  const isMobile = useIsMobile()

  const { addNotification } = useNotifications()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  const [showAddSources, setShowAddSources] = useState(false)
  const [editSource, setEditSource] = useState<ConnectedSource>()

  const fetchConnectedSources = useCallback(
    async (cursor?: string) => {
      dispatch(actions.fetchStart())

      try {
        const response = await fivetranClient?.getConnectedSourcesInGroup(
          destinationGroupId,
          cursor,
          10
        )

        const connectors = response?.items ?? []
        const nextCursor = response?.nextCursor ?? undefined
        const prevCursor = cursor

        // This response contains all connectors for both prod and staging environments.
        // We have no way to determine which environment a connector belongs to, unless we
        // look at the schema name (because the schema name contains the environment name).
        // Since we are making the distinction between environments in the UI, we need to filter
        // out connectors that don't match the selected environment.
        const connectorsByEnv = selectedEnvironment
          ? connectors.filter(c => c.schema.includes(selectedEnvironment))
          : connectors

        dispatch(actions.fetchSuccess(connectorsByEnv, nextCursor, prevCursor))
      } catch (error: any) {
        dispatch(actions.fetchError(error))
      }
    },
    [fivetranClient]
  )

  const refreshAndFetch = useCallback(() => {
    const fetchData = async () => {
      try {
        dispatch(actions.refresh())
        await fetchConnectedSources()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in refreshAndFetch:', error)
      }
    }

    fetchData()
  }, [dispatch, actions.refresh, fetchConnectedSources])

  const toggleSourceBrowser = () => setShowAddSources(!showAddSources)

  const handleDisconnectOrSyncSuccess = (message: string) => {
    addNotification({
      id: generateUUID(),
      type: 'success',
      message,
    })

    setEditSource(undefined)

    setTimeout(() => refreshAndFetch(), 2500)
  }

  const handleModalError = (message: string) => {
    addNotification({
      id: generateUUID(),
      type: 'warning',
      message: message,
    })
  }

  const handleFinishSetupSuccess = (connectCard: ConnectCard) => {
    // Start the Connect with Fivetran external flow
    window.open(connectCard.uri, '_blank', 'noreferrer')
  }

  /**
   * Fetch initial batch of connected sources on mount
   */
  useEffect(() => {
    fetchConnectedSources()
  }, [])

  /**
   * Fetch connectors for the next page
   */
  const handleNextPage = useCallback(() => {
    if (state.nextCursor) {
      fetchConnectedSources(state.nextCursor)
    }
  }, [state.nextCursor, fetchConnectedSources])

  /**
   * Fetch connectors for the previous page
   */
  const handlePrevPage = useCallback(() => {
    // Get the cursor in state.cursors that comes before state.prevCursor
    const currentIndex = state.cursors.indexOf(state.prevCursor)

    if (currentIndex >= 0) {
      const prevCursor = state.cursors[currentIndex - 1]
      fetchConnectedSources(prevCursor)
    }
  }, [state.prevCursor, fetchConnectedSources])

  const showAddSourcesButton =
    userRole === 'admin' ||
    userRole === 'superuser' ||
    userRole === 'data_admin'

  return (
    <Container isMobile={isMobile}>
      {showAddSources && <AddSourceSheet onClose={toggleSourceBrowser} />}

      {editSource && (
        <EditSourceSheet
          source={editSource}
          modeled={isModeled(editSource.service)}
          onClose={() => setEditSource(undefined)}
          onDisconnectSuccess={handleDisconnectOrSyncSuccess}
          onToggleSyncSuccess={handleDisconnectOrSyncSuccess}
          onFinishSetupSuccess={handleFinishSetupSuccess}
          onError={handleModalError}
        />
      )}

      <Header>
        <MyDataSourcesContainer>
          <H4>My Data Sources</H4>
          <Button
            type="button"
            purpose="ghost"
            onClick={refreshAndFetch}
            icon={standardIcons.Refresh}
            name="Refresh"
            location="Data Sources"
          >
            Refresh
          </Button>
        </MyDataSourcesContainer>

        {showAddSourcesButton && (
          <Button
            type="button"
            onClick={toggleSourceBrowser}
            icon={standardIcons.Plus}
            name="Add Source"
            location="Data Sources"
          >
            Add Source
          </Button>
        )}
      </Header>

      {state.loading && (
        <LoaderContainer>
          <Spinner scale={30} />
        </LoaderContainer>
      )}

      {!state.loading && (
        <>
          {state.items && state.items.length > 0 ? (
            <ConnectedSources
              sources={state.items as ConnectedSource[]}
              allConnectors={fivetranConnectors as ChordDataConnector[]}
              onSelectSource={setEditSource}
              onPrevPage={handlePrevPage}
              onNextPage={handleNextPage}
              showNextPageButton={state.nextCursor !== undefined}
              showPreviousPageButton={state.prevCursor !== undefined}
            />
          ) : (
            <NoSources>No data sources found</NoSources>
          )}
        </>
      )}

      {state.error && (
        <ErrorContainer>
          <div>An error occurred while fetching your data sources.</div>
          <span onClick={() => dispatch(actions.fetchError(undefined))}>
            <CloseX fill={theme.GREY_80} />
          </span>
        </ErrorContainer>
      )}
    </Container>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  padding: ${p => (p.isMobile ? 12 : 24)}px;
  overflow: auto;
  padding: 0;
  position: relative;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MyDataSourcesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-left: 1rem;
  }
`

const LoaderContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`

const ErrorContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${p => p.theme.RED_30};
  border-radius: 8px;
  color: ${p => p.theme.GREY_80};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;

  & > span {
    cursor: pointer;
  }
`

const NoSources = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${p => p.theme.GREY_30};
  padding: 12px;
  font-size: 12px;
  color: ${p => p.theme.GREY_80};
`
