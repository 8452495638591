import { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { SubNavItemType } from '@chordco/component-library'

export type AnalyticsTab = {
  id: number
  slug: string
  title: string
  link: string
  subItems?: SubNavItemType[]
}

export const useAnalyticsTabsHelper = (tabs: AnalyticsTab[]) => {
  const history = useHistory()
  const path = history.location.pathname

  // A helper function to extract the slug and base path from a given path
  const getSlugAndBasePath = (path: string) => {
    const slug = path.split('/').pop()
    const basePath = path.split('/').slice(0, -1).join('/')
    return { slug, basePath }
  }

  // Memoize initialTab calculation based on the current path and tabs
  const initialTab = useMemo(() => {
    return tabs.find(
      t => t.link === path || t?.subItems?.some(si => si.link === path)
    )
  }, [tabs, path])

  // Initialize selectedTabId to null and defer setting it until the initial mount effect runs.
  // This prevents the initial state from competing with updates from useEffect.
  const [selectedTabId, setSelectedTabId] = useState<number | null>(null)

  // Set initial tab based on initialTab if not already set
  useEffect(() => {
    if (selectedTabId === null && initialTab) {
      setSelectedTabId(initialTab.id)
    }
  }, [initialTab, selectedTabId])

  // Handle tab selection when `selectedTabId` changes (triggered by tab clicks)
  useEffect(() => {
    // Find the selected tab based on the selectedTabId
    const selectedTab = tabs.find(t => t.id === selectedTabId)
    if (!selectedTab) return

    // Extract the slug and base path from the current path
    const { slug, basePath } = getSlugAndBasePath(path)

    // Triggers navigation if the current path does not match the selected tab or its subItems
    if (
      selectedTab.link !== path &&
      !selectedTab.subItems?.some(si => si.link === path)
    ) {
      if (basePath === selectedTab.link) {
        // Ensure that the slug is retained when replacing the path
        history.replace(`${selectedTab.link}/${slug}`)
      } else {
        // We need to navigate to the selected tab
        history.replace(selectedTab.link)
      }
    }
    // If the selected tab has subItems, navigate to the first subItem
    else if (selectedTab.subItems?.length) {
      const matchingSubItem = selectedTab.subItems.find(si => si.link === path)

      // If the current path does not match any subItem, navigate to the first subItem
      if (!matchingSubItem && selectedTab.subItems[0]) {
        // in case the path has a slug, ensure that we retain it when replacing the path
        const { slug: subSlug, basePath: subBasePath } =
          getSlugAndBasePath(path)

        if (subBasePath === selectedTab.subItems[0].link) {
          // Ensure that the slug is retained when replacing the path
          history.replace(`${selectedTab.subItems[0].link}/${subSlug}`)
        } else {
          // We need to navigate to the first subItem
          history.push(selectedTab.subItems[0].link)
        }
      }
    }
  }, [selectedTabId])

  // Update the selected tab based on URL changes (triggered by changes in the path)
  useEffect(() => {
    const relevantTab = tabs.find(
      t =>
        path.startsWith(t.link) ||
        t.subItems?.some(si => path.startsWith(si.link))
    )
    if (relevantTab && relevantTab.id !== selectedTabId) {
      setSelectedTabId(relevantTab.id)
    }
  }, [path, tabs])

  // Update selected tab when `tabs` change (e.g., when tabs are fetched or updated)
  useEffect(() => {
    if (!tabs.length) return

    const currentTab = tabs.find(
      tab =>
        path.startsWith(tab.link) ||
        tab.subItems?.some(si => path.startsWith(si.link))
    )

    if (currentTab && currentTab.id !== selectedTabId) {
      setSelectedTabId(currentTab.id)
    }
  }, [tabs])

  return [selectedTabId, setSelectedTabId] as const
}
