import React from 'react'
import styled from 'styled-components'
import { useIsMobile } from 'hooks'
import { useAuthData } from 'redux/state/auth'
import { Header, Section } from '../common'
import { MetricsTiles } from './MetricsTiles'
import { SystemStatus } from 'components/home/SystemStatus'

export type SetupStep = {
  label: string
  description: string
  complete: boolean
  key: string
}

interface Props {
  enableSystemHealth: boolean
}

export const Home: React.FC<Props> = ({ enableSystemHealth }) => {
  const isMobile = useIsMobile()
  const {
    state: { user },
  } = useAuthData()

  const userName =
    user && (user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}` : ''

  return (
    <>
      <Header title={`Welcome${userName ? ' ' + userName : ''}!`} />
      <Section isMobile={isMobile} hasBorder={false}>
        {isMobile ? (
          <>
            {enableSystemHealth && <SystemStatus />}
            <MetricsTiles />
          </>
        ) : (
          <FlexContainer>
            <Flex flex={2}>
              {enableSystemHealth && <SystemStatus />}
              <MetricsTiles />
            </Flex>
          </FlexContainer>
        )}
      </Section>
    </>
  )
}

export const SubSection = styled.div<{ isMobile: boolean }>`
  position: relative;
  padding-bottom: ${p => (p.isMobile ? 24 : 32)}px;
`
const FlexContainer = styled.div`
  display: flex;

  :not(:last-child) {
    margin-right: 16px;
  }
`

const Flex = styled.div<{ flex?: number }>`
  flex: ${p => p.flex || 1};

  :not(:last-child) {
    margin-right: 16px;
  }
`
