import { hubClient } from 'api/http'
import { ChordConfigSheet, ChordDataConnector } from 'types'

export interface ProvisioningClient {
  getDataConnectors: () => Promise<ChordDataConnector[]>
  getConfigSheets: () => Promise<ChordConfigSheet[]>
}

export const provisioningClient: ProvisioningClient = {
  getDataConnectors: () => hubClient.get('provisioning/data_connectors'),
  getConfigSheets: () => hubClient.get('provisioning/config_sheets'),
}
