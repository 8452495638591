import React, { ReactNode, useState } from 'react'
import { TableRow } from 'components/common'
import styled from 'styled-components'

type CardProps = {
  forceHover?: boolean
  onClick?: () => void
  children: ReactNode
}

export const Card: React.FC<CardProps> = ({ forceHover, onClick, children }) => {
  const [_hover, setHover] = useState(false)
  const hover = forceHover || _hover

  return (
    <HoverBorder hover={hover}>
      <CardContainer
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
      >
        {children}
      </CardContainer>
    </HoverBorder>
  )
}

const HoverBorder = styled.div<{ hover?: boolean }>`
  border-style: solid;
  border-width: ${p => (p.hover ? '3px' : '1px')};
  border-color: ${p =>
    p.hover ? ({ theme }) => theme.PRIMARY_40 : ({ theme }) => theme.ComponentCardBorderElevation0};
  border-radius: ${({ theme }) => theme.CardMediumRadius};
  padding: ${p => (p.hover ? '0px' : '2px')};
`

const CardContainer = styled(TableRow)`
  display: flex;
  align-items: center;
  align-content: center;
  height: 80px;
  width: 100%;
  border-radius: ${({ theme }) => theme.CardMediumRadius};
  border: transparent;

  img {
    margin-right: 16px;
  }
`
