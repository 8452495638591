import React from 'react'
import styled, { css } from 'styled-components'
import { Button, H2, H3, IconProps, Spinner, standardIcons } from '@chordco/component-library'
import { Hr } from './Header'

interface Props {
  children?: React.ReactNode
  isMobile: boolean
  title?: string
  buttonLabel?: string
  onButtonClick?: () => void
  flexButton?: boolean
  hasBorder?: boolean
  id?: string | number
  style?: {}
  loading?: boolean
  buttonIcon?: React.FC<IconProps>
}

const { Plus } = standardIcons

export const Section: React.FC<Props> = ({
  children,
  isMobile,
  title,
  buttonLabel,
  buttonIcon = Plus,
  onButtonClick,
  flexButton = false,
  hasBorder = true,
  style = {},
  loading = false,
}) => {
  const Heading = isMobile ? H3 : H2

  return (
    <>
      <Container style={style}>
        {title && (
          <Heading
            style={{
              marginBottom: '24px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div> {title}</div>
            {buttonLabel && onButtonClick && (
              <StyledButton
                onClick={onButtonClick}
                icon={buttonIcon}
                purpose="secondary"
                flexButton={flexButton}
              >
                {isMobile ? 'New' : `New ${buttonLabel}`}
              </StyledButton>
            )}
          </Heading>
        )}

        {children}
      </Container>

      {loading && (
        <SpinnerContainer>
          <Spinner scale={30} />
        </SpinnerContainer>
      )}

      {hasBorder && <Hr isMobile={isMobile} />}
    </>
  )
}

const Container = styled.div`
  position: relative;
  padding: 24px 0 34px 0;
`

const StyledButton = styled(Button)<{ flexButton: boolean }>`
  ${p =>
    !p.flexButton &&
    css`
      position: absolute;
      top: 24px;
      right: 0;
    `};
`

export const SpinnerContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`

export const SectionWrapper = styled.div<{ isSmall }>`
  margin-top: 16px;
  margin-right: ${p => (p.isSmall ? 0 : 352)}px;
`

export const SectionContainer = styled.div<{
  isSmall: boolean
  flexCenter?: boolean
}>`
  position: relative;
  box-sizing: border-box;
  height: ${p => (p.isSmall ? '96' : '64')}px;
  min-width: 300px;
  padding: ${p => (p.isSmall ? '12px' : '22px 16px')};
  margin: ${p => (p.isSmall ? '16px 0 0 0' : '16px 352px 0 0')};
  background-color: ${p => p.theme.ComponentCardBgElevation1};
  border-radius: ${p => p.theme.CardSmallRadius};

  ${p =>
    p.flexCenter &&
    css`
      display: flex;
      align-items: center;
    `}
`

export const SectionContainerButton = styled(Button)<{ isSmall: boolean }>`
  position: absolute;

  ${p =>
    p.isSmall
      ? css`
          bottom: 8px;
          left: calc(50% - 67px);
        `
      : css`
          right: 16px;
          top: 12px;
        `}
`

export const Absolute = styled.div`
  position: absolute;
  width: 283px;
  z-index: 1;
  right: 64px;
`

const Fixed = styled.div`
  width: 283px;
  top: 46px;
  position: fixed;
`

export const FloatingSection = ({ children }) => {
  return (
    <Absolute>
      <Fixed>{children}</Fixed>
    </Absolute>
  )
}
