import { StripeWebhooks } from 'types/systemHealth'
import { hubClient } from 'api/http'

export interface GetStripeWebhooksRequest {
  tenantId: number
  tenantName: string
}

export type SystemHealthClient = {
  getStripeWebhooks: (request: GetStripeWebhooksRequest) => Promise<StripeWebhooks>
}

export const systemHealthClient: SystemHealthClient = {
  getStripeWebhooks: ({ tenantId, tenantName }) => {
    return hubClient
      .get(`tenants/${tenantId}/stripe_webhooks`)
      .then(({ data }) => (data as StripeWebhooks)?.filter(w => w.url.includes(tenantName)))
  },
}
