import React, { useCallback, useEffect } from 'react'
import { DashboardEmbed } from './DashboardEmbed'
import styled from 'styled-components'
import { useLookerData } from 'redux/state/looker'
import { useIsLoading } from 'redux/utils'

/**
 * This component silently initializes a Looker embed user by loading an invisible iframe that
 * triggers the creation of the embed user and associated resources, such as their personal folder.
 * It is used to ensure the embed user is properly set up without requiring user interaction.
 */
export const EmbedUserAutoCreate = () => {
  const {
    state: { embedUserBootstrapURL },
    getEmbedUserBootstrapURL,
  } = useLookerData()

  const isLoading = useIsLoading('getEmbedUserBootstrapURL')

  const fetchEmbedUserBootstrapURL = useCallback(async () => {
    if (getEmbedUserBootstrapURL && !embedUserBootstrapURL) {
      await getEmbedUserBootstrapURL()
    }
  }, [getEmbedUserBootstrapURL, embedUserBootstrapURL])

  useEffect(() => {
    if (!embedUserBootstrapURL && !isLoading) {
      fetchEmbedUserBootstrapURL()
    }
  }, [embedUserBootstrapURL, isLoading, fetchEmbedUserBootstrapURL])

  return (
    <HiddenContainer>
      {!isLoading && embedUserBootstrapURL && <DashboardEmbed url={embedUserBootstrapURL} />}
    </HiddenContainer>
  )
}

const HiddenContainer = styled.div`
  display: none;
`
