import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Pin: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 9.5L15 11.1667V12.8333H10.8333V17.8333L10 18.6667L9.16667 17.8333V12.8333H5V11.1667L6.66667 9.5V3.66667C6.20643 3.66667 5.83333 3.29357 5.83333 2.83333C5.83333 2.3731 6.20643 2 6.66667 2H13.3333C13.7936 2 14.1667 2.3731 14.1667 2.83333C14.1667 3.29357 13.7936 3.66667 13.3333 3.66667V9.5ZM7.375 11.1667H12.625L11.6667 10.2083V3.66667H8.33333V10.2083L7.375 11.1667Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
