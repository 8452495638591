import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

export type ActiveTabData = {
  isMyReports: boolean
  isPinnedReports: boolean
  isExplores: boolean
  isQuickstarts: boolean
  isSharedReports: boolean
}

const useAnalyticsActiveTab = (): ActiveTabData => {
  const history = useHistory()
  const path = history.location.pathname

  return useMemo(() => {
    const isMyReports = /^\/analytics\/my-reports(\/|$)/.test(path)
    const isPinnedReports = /^\/analytics\/pinned-reports(\/|$)/.test(path)
    const isExplores = /^\/analytics\/explores(\/|$)/.test(path)
    const isQuickstarts = /^\/analytics\/quickstarts(\/|$)/.test(path)
    const isSharedReports = /^\/analytics\/shared-reports(\/|$)/.test(path)

    return { isMyReports, isPinnedReports, isExplores, isQuickstarts, isSharedReports }
  }, [path])
}

export default useAnalyticsActiveTab
