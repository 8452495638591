import React from 'react'
import { IconProps } from '../types'

export const CDPLogo: React.FC<IconProps> = ({ scale = 68, ...props }) => {
  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="34" cy="34" r="34" fill="#F050FF"></circle>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9704 16.7426C31.9704 16.7426 31.9703 16.7426 31.9703 16.7427L29.3617 19.3555C33.8358 17.8119 38.9677 18.719 42.6725 22.0796L48.4629 16.2799C43.7501 12.0508 36.5013 12.2067 31.9704 16.7426ZM27.8386 20.8812L25.2294 23.4946C25.2283 23.4956 25.2274 23.4967 25.2264 23.4977C20.5415 28.1958 20.5406 35.8087 25.2292 40.5025C29.9172 45.1959 37.515 45.2001 42.2028 40.5094C42.205 40.5072 42.2072 40.505 42.2093 40.5028L44.8186 37.8893C40.143 39.5029 34.7515 38.4404 31.0206 34.7017C27.2897 30.9627 26.229 25.5624 27.8386 20.8812ZM48.9506 33.7504L49.9003 34.7018L43.1592 41.4539C43.1572 41.4559 43.1552 41.4579 43.1531 41.46L36.4155 48.2085C36.4155 48.2086 36.4154 48.2086 36.4154 48.2086C31.2032 53.432 22.7501 53.4289 17.536 48.2088C12.3213 42.9882 12.3215 34.5212 17.5358 29.2983L24.2768 22.5462C24.2777 22.5454 24.2786 22.5445 24.2794 22.5436L31.0209 15.7912C36.2351 10.5711 44.6883 10.5681 49.9004 15.7914L50.375 16.267L42.6843 23.9704L42.2094 23.4948C38.7182 19.9979 33.6118 19.1032 29.2949 20.8142C27.5869 25.1383 28.4809 30.2534 31.9705 33.7506C36.6579 38.448 44.2605 38.4482 48.9506 33.7504ZM21.0989 27.6318L18.4856 30.2495C13.7955 34.9471 13.7957 42.5623 18.4854 47.2573C23.1756 51.9528 30.7784 51.9547 35.4656 47.2575L38.0773 44.6416C33.4027 46.2538 28.0116 45.1902 24.2797 41.4541C20.5464 37.7166 19.4873 32.3154 21.0989 27.6318Z"
        fill="black"
      />
    </svg>
  )
}
