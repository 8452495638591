import { useLookerData } from 'redux/state/looker'
import {
  AvailableUserReport,
  CopyDashboardResponse,
  LookerReportType,
} from 'types'
import { useState } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'

interface CopyDashboardData {
  id: string
  title: string
  description: string
  folderId: string
  reportType: LookerReportType
}

export const useCopyDashboard = (
  onCopied: (dashboard?: AvailableUserReport) => void
) => {
  const { copyDashboard } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const copy = async ({
    id,
    title,
    description,
    folderId,
    reportType,
  }: CopyDashboardData) => {
    if (!copyDashboard) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await copyDashboard(
        id,
        title,
        description,
        folderId,
        reportType
      )) as PayloadAction<{ data: CopyDashboardResponse }>

      if (response.payload.data?.dashboard) {
        setHasSucceeded(true)
        onCopied(response.payload.data.dashboard)
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { copy, isLoading, hasSucceeded, hasFailed }
}
