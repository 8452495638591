import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'
import { usePublishDashboard } from './usePublishDashboard'
import { AvailableUserReport, LookerReportType } from 'types'
import { usePublishLook } from './usePublishLook'

const { AlertWarning } = standardIcons

type PublishConfirmationModalProps = {
  name: string
  description: string
  slug: string
  type: LookerReportType
  onCancel: () => void
  onPublish: (report: AvailableUserReport) => void
}

export const PublishConfirmationModal: React.FC<PublishConfirmationModalProps> = ({
  name,
  description,
  slug,
  type,
  onCancel,
  onPublish,
}) => {
  const { publish: publishDashboard, isLoading: isPublishingDashboard } =
    usePublishDashboard(onPublish)
  const { publish: publishLook, isLoading: isPublishingLook } =
    usePublishLook(onPublish)

  const isLoading = isPublishingDashboard || isPublishingLook

  const handlePublish = async () => {
    if (type === 'dashboard') {
      await publishDashboard({
        dashboardName: name,
        dashboardDescription: description,
        dashboardSlug: slug,
        replaceExisting: true,
      })
    } else if (type === 'look') {
      await publishLook({
        lookName: name,
        lookDescription: description,
        lookSlug: slug,
        replaceExisting: true,
      })
    }
  }

  return (
    <AlertModal
      onClose={onCancel}
      icon={AlertWarning}
      content={`A report with the name '${name}' already exists.`}
      subContent="You can overwrite the existing report. Or simply cancel and re-publish your report under a different name."
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onCancel,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Yes, overwrite',
          onClick: handlePublish,
          purpose: 'destructive',
          isLoading: isLoading,
          disabled: false,
        },
      ]}
    />
  )
}
