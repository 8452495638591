import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { updateOmsClient } from 'api/omsHttp'
import { reduxApiClient } from 'redux/api'
import { RootState } from 'redux/store'
import {
  getCurrentEnvironment,
  getCurrentTenant,
  setCurrentEnvironment,
} from 'redux/utils/localStorageHelpers'
import { StoreEnvironment } from 'types'
import { IntegrationSettings } from 'types/envVars'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'environments'

type State = {
  environments: StoreEnvironment[]
  selectedEnvironment?: StoreEnvironment
}

const initialState: State = {
  environments: [],
}

export interface SelectEnvironmentPayload {
  environment: StoreEnvironment
  settings: IntegrationSettings
  environments?: StoreEnvironment[]
}

const { getUserTenants } = reduxApiClient

export const environmentsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    selectEnvironment: (state, action: PayloadAction<SelectEnvironmentPayload>) => {
      const { environment, environments, settings } = action.payload

      state.selectedEnvironment = environment
      if (environments) state.environments = environments
      updateOmsClient(settings.baseUrl, settings.apiKey)
      setCurrentEnvironment(environment)
    },
    resetEnvironments: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getUserTenants.fulfilled, (state, action) => {
      const tenants = action.payload.data
      if (!tenants) return

      const tenant = getCurrentTenant(tenants)
      const store = tenant.stores.find(s => s.environments.length)

      if (!store) return

      const currentEnvironment = getCurrentEnvironment(store.environments)

      state.environments = store.environments
      state.selectedEnvironment = currentEnvironment
    })
  },
})

export default environmentsSlice.reducer

export const useEnvironmentsData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const solidusInfo = useSelector(
    (state: RootState) => state.stores.solidusInfo
  )

  return {
    state,
    selectEnvironment: (environment: StoreEnvironment) => {
      const solidusSettings = solidusInfo[environment]
      const settings = {
        baseUrl: solidusSettings?.apiHost || '',
        apiKey: solidusSettings?.apiKey || '',
        storeId: solidusSettings?.solidusStoreId || 0,
      }

      return dispatch(
        environmentsSlice.actions.selectEnvironment({ environment, settings })
      )
    },
  }
}
