import React from 'react'
import { Card } from '../common/Card'
import { CardContent, Header, TextContainer } from 'components/analytics/reports/common'
import { DestinationConfig } from 'types/cdp'
import { H4 } from '@chordco/component-library'
import { Box } from '@mui/material'
import { coreDestinationsMap } from '../destinations'
import { Language } from '@mui/icons-material'
import { blue } from '@mui/material/colors'

type DestinationCardProps = {
  destination: DestinationConfig
  selected?: boolean
  onSelectDestination: (destination: DestinationConfig) => void
}

export const DestinationCard: React.FC<DestinationCardProps> = ({
  destination,
  selected,
  onSelectDestination,
}) => {
  const icon = coreDestinationsMap[destination.destinationType]?.icon
  const size = { width: 36, height: 36 }

  return (
    <Card onClick={() => onSelectDestination(destination)} forceHover={selected}>
      <CardContent>
        <Header>
          {icon ? <Box sx={size}>{icon}</Box> : <Language sx={{ ...size, color: blue[500] }} />}
          <TextContainer>
            <H4>{destination.name}</H4>
          </TextContainer>
        </Header>
      </CardContent>
    </Card>
  )
}
