import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import EmptyPinnedReportsImg from 'assets/EmptyPinnedReports.svg'
import { Button, standardIcons } from '@chordco/component-library'
import {
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
} from 'components/settings/integrations/requests/Common'

export default function EmptyPinnedReports() {
  const history = useHistory()

  return (
    <Wrapper>
      <CenteredContent gap={48}>
        <ImageContainer>
          <img src={EmptyPinnedReportsImg} alt="Empty Pinned Reports" />
        </ImageContainer>

        <TextCtaContainer>
          <StyledH1>Where are all of the Reports?</StyledH1>
          <StyleBody>
            Pinning reports in Chord is simple! Just navigate to &quot;Shared
            Reports&quot; or the Library of Quickstarts, and pin any report you
            want for quick access.
          </StyleBody>

          <Buttons>
            <Button
              onClick={() => history.push('/analytics/shared-reports')}
              purpose="tertiary"
              trailingIcon={standardIcons.ArrowDiagonal}
            >
              View Shared Reports
            </Button>

            <Button
              onClick={() => history.push('/analytics/quickstarts')}
              purpose="tertiary"
              trailingIcon={standardIcons.ArrowDiagonal}
            >
              Browse Quickstarts
            </Button>
          </Buttons>
        </TextCtaContainer>
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: calc(100vh - 110px);
`

const TextCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
