import React from 'react'

export default (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="4" fill="#4F44E0" />
    <circle cx="8" cy="14" r="3" fill="white" />
    <circle cx="16" cy="14" r="2" fill="white" />
    <circle cx="22" cy="14" r="1" fill="white" />
  </svg>
)
