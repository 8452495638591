import React from 'react'
import { Box, Typography } from '@mui/material'

interface EmptyViewProps {
  title: string
  description: string
  icon: React.ReactNode
}

export const EmptyView: React.FC<EmptyViewProps> = ({ title, description, icon }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: theme => theme.spacing(4),
        borderRadius: 4,
        border: 1,
        borderColor: 'grey.300',
        borderStyle: 'dashed',
      }}
      role="alert"
      aria-label={title}
    >
      {icon}
      <Typography variant="h5">{title}</Typography>
      <Typography variant="subtitle1" sx={{ color: 'grey.700', textAlign: 'center' }}>
        {description}
      </Typography>
    </Box>
  )
}
