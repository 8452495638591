import { Notification } from 'types'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { useStateSliceAndDispatch } from 'redux/utils'

const SLICE_NAME = 'notifications'

type State = {
  notifications: Notification[]
}

const initialState: State = {
  notifications: [],
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      const { id, message, type, undoAction } = action.payload
      state.notifications = state.notifications.concat({
        id,
        message,
        type,
        undoAction,
      })
    },

    clearNotification: (state, action: PayloadAction<{ id: string }>) => {
      state.notifications = state.notifications.filter(({ id }) => id !== action.payload.id)
    },
    resetNotifications: () => initialState,
  },
})

export default notificationsSlice.reducer

export const useNotifications = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const clearNotificationAfterDelay = (id: string, delay: number) => {
    setTimeout(
      () =>
        dispatch({
          type: 'notifications/clearNotification',
          payload: { id },
        }),
      delay
    )
  }

  return {
    state,
    addNotification: (notification: Notification, delay = 8000) => {
      dispatch({
        type: 'notifications/addNotification',
        payload: notification,
      })
      clearNotificationAfterDelay(notification.id, delay)
    },
  }
}
