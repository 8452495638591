import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import { SharedLooksResponse } from 'types'

export const useValidateLookName = () => {
  const { getSharedLooks } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const validate = async (lookName: string) => {
    if (!getSharedLooks) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await getSharedLooks()) as PayloadAction<{
        data: SharedLooksResponse
      }>

      if (response.payload.data?.looks) {
        setHasSucceeded(true)
        return response.payload.data.looks.every(d => d.label !== lookName)
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { validate, isLoading, hasSucceeded, hasFailed }
}
