import React, { useMemo } from 'react'
import moment from 'moment'
import { EnvVarConfig } from 'utils/envVarConfig'
import { Chip, standardIcons } from '@chordco/component-library'
import { AvailableUserReport } from 'types'
import {
  Card,
  CardContent,
  Header,
  StyledIcon,
  TextContainer,
  Title,
  ChipContainer,
  Description,
  PinButton,
} from 'components/analytics/reports/common'
import useCanPinReport from 'redux/hooks/useCanPinReport'
import usePinnedReport from 'redux/hooks/usePinnedReport'

type QuickstartCardProps = {
  report: AvailableUserReport
  onSelectReport: (report: AvailableUserReport) => void
  onPinReport: (report: AvailableUserReport) => void
  onUnpinReport: (report: AvailableUserReport) => void
  isLoading: boolean
}

const IS_NEW_THRESHOLD =
  parseInt(EnvVarConfig.REACT_APP_NEW_DASHBOARD_DAYS_THRESHOLD) || 3

export const QuickstartCard: React.FC<QuickstartCardProps> = ({
  report,
  onSelectReport,
  onPinReport,
  onUnpinReport,
  isLoading,
}) => {
  const isNew = useMemo(
    () => moment().diff(moment(report.createdAt), 'days') <= IS_NEW_THRESHOLD,
    [report.createdAt]
  )

  const canPin = useCanPinReport()
  const canUnpin = canPin && report.createdBy !== null

  const { isPinned } = usePinnedReport(report.slug)

  const handlePinClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onPinReport(report)
  }

  const handleUnpinClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onUnpinReport(report)
  }

  return (
    <Card onClick={() => onSelectReport(report)}>
      <CardContent>
        <Header>
          <StyledIcon
            icon={
              report.metricType === 'dashboard' ||
              report.metricType === 'lookml_dashboard'
                ? standardIcons.Reports
                : standardIcons.Metrics
            }
            purpose="ghost"
            size="large"
          />
          <TextContainer>
            <ChipContainer>
              <Chip
                text={report.folderName}
                color="neutral"
                variant="solid-secondary"
              />
              {isNew && <Chip text="New" color="limeGreen" variant="solid" />}
            </ChipContainer>

            <Title>{report.label}</Title>

            {report.description && (
              <Description>{report.description}</Description>
            )}

            {canPin && !isPinned && (
              <PinButton
                purpose="ghost"
                icon={standardIcons.Pin}
                onClick={handlePinClick}
                isLoading={isLoading}
                disabled={isLoading}
              />
            )}

            {canUnpin && isPinned && (
              <PinButton
                purpose="ghost"
                icon={standardIcons.Unpin}
                onClick={handleUnpinClick}
              />
            )}
          </TextContainer>
        </Header>
      </CardContent>
    </Card>
  )
}
