import React from 'react'
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { AvailableUserReport } from 'types'

type ReportFolderDropdownProps = {
  title: string
  dashboards: AvailableUserReport[]
  looks: AvailableUserReport[]
  onChange: (event: SelectChangeEvent<string>) => void
  defaultValue?: string
}

export const ReportFolderDropdown: React.FC<ReportFolderDropdownProps> = ({
  title,
  dashboards,
  looks,
  onChange,
  defaultValue,
}) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 300 }}>
      <InputLabel htmlFor="my-report-dropdown">{title}</InputLabel>
      <Select
        value={defaultValue}
        id="my-report-dropdown"
        label={title}
        onChange={(event: SelectChangeEvent<string>) => onChange(event)}
      >
        <ListSubheader>Dashboards</ListSubheader>
        {dashboards.length > 0 &&
          dashboards.map((dashboard, idx) => (
            <MenuItem key={`${dashboard.slug}-${idx}`} value={dashboard.id}>
              {dashboard.label}
            </MenuItem>
          ))}

        {dashboards.length === 0 && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}

        <ListSubheader>Looks</ListSubheader>
        {looks.length > 0 &&
          looks.map((look, idx) => (
            <MenuItem key={`${look.slug}-${idx}`} value={look.id}>
              {look.label}
            </MenuItem>
          ))}

        {looks.length === 0 && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
