import React, { useEffect, useState } from 'react'
import { useForm } from 'hooks'
import {
  FormInput,
  FormSecureInput,
  FormRadioButton,
  FormCheckbox,
  Loading,
} from 'components/common'
import { useCreateTenantForm } from 'redux/hooks/useCreateTenantForm'
import { Button, Chip, H4, Modal } from '@chordco/component-library'
import styled from 'styled-components'
import { StoreEnvironment, TenantPlanEnum } from 'types'
import { useProvisioningData } from 'redux/state/provisioning'
import createTenantSchema from './CreateTenantSchema'
import { useIsLoading } from 'redux/utils'
import { CreateTenantConfirmation } from './CreateTenantConfirmation'

type Props = {
  selectedEnvironment: StoreEnvironment
  onClose: () => void
}

enum CreateTenantSections {
  TenantInfo = 'tenant_info',
  DataSources = 'data_sources',
  DataActivations = 'data_activations',
  LookerConfiguration = 'looker_configuration',
  SolidusConfiguration = 'solidus_configuration',
  ConfirmTenantCreation = 'confirm_tenant_creation',
}

enum LookerModels {
  SnowflakePerformance = 'snowflake_performance',
  SnowflakeAutonomy = 'snowflake_autonomy',
  SnowflakeSalesforce = 'snowflake_salesforce',
}

export const CreateTenantForm: React.FC<Props> = ({
  selectedEnvironment,
  onClose,
}) => {
  const [section, setSection] = useState(CreateTenantSections.TenantInfo)

  const sectionTransitions = {
    [CreateTenantSections.TenantInfo]: {
      next: selectedPlan =>
        selectedPlan === TenantPlanEnum.Autonomy
          ? CreateTenantSections.SolidusConfiguration
          : CreateTenantSections.DataSources,
    },
    [CreateTenantSections.DataSources]: {
      next: () => CreateTenantSections.DataActivations,
      prev: selectedPlan =>
        selectedPlan === TenantPlanEnum.Autonomy
          ? CreateTenantSections.SolidusConfiguration
          : CreateTenantSections.TenantInfo,
    },
    [CreateTenantSections.DataActivations]: {
      next: () => CreateTenantSections.LookerConfiguration,
      prev: () => CreateTenantSections.DataSources,
    },
    [CreateTenantSections.LookerConfiguration]: {
      next: () => CreateTenantSections.ConfirmTenantCreation,
      prev: () => CreateTenantSections.DataActivations,
    },
    [CreateTenantSections.SolidusConfiguration]: {
      next: () => CreateTenantSections.DataSources,
      prev: () => CreateTenantSections.TenantInfo,
    },
    [CreateTenantSections.ConfirmTenantCreation]: {
      prev: () => CreateTenantSections.LookerConfiguration,
    },
  }

  const navigateSection = direction => {
    const transition = sectionTransitions[section][direction]
    if (transition) {
      setSection(transition(selectedPlan))
    }
  }

  const goToNextSection = () => navigateSection('next')
  const goToPreviousSection = () => navigateSection('prev')

  const [selectedPlan, setSelectedPlan] = useState<TenantPlanEnum>(
    TenantPlanEnum.DataOnly
  )

  const [selectedConnectors, setSelectedConnectors] = useState<string[]>([])
  const [selectedModels, setSelectedModels] = useState<string[]>([])

  const { submit, isLoading } = useCreateTenantForm(onClose)
  const tenantSchema = createTenantSchema(selectedEnvironment)
  const { fields, onSubmit } = useForm(tenantSchema, submit)

  const {
    state: { dataConnectors },
    getDataConnectors,
  } = useProvisioningData()

  const isLoadingConnectors = useIsLoading('getDataConnectors')

  useEffect(() => {
    if (!dataConnectors.length && !isLoadingConnectors) {
      getDataConnectors()
    }
  }, [dataConnectors.length, getDataConnectors])

  const isValidTenantInfo = () => {
    return fields.name.value && fields.uuid.value
  }

  const isValidSolidusConfig = () => {
    return (
      fields.solidusApiKey.value &&
      fields.solidusApiHost.value &&
      fields.solidusStoreId.value &&
      fields.solidusTenantId.value
    )
  }

  return (
    <Modal onClose={onClose} title="Create Tenant" width="480px">
      <form onSubmit={onSubmit} noValidate>
        {isLoadingConnectors && <Loading />}

        {!isLoadingConnectors && (
          <>
            <Container>
              {section === CreateTenantSections.TenantInfo && (
                <Section>
                  <HeaderChip>
                    <H4>Tenant Information</H4>
                    <Chip
                      color={
                        selectedEnvironment !== 'staging' ? 'red' : 'amber'
                      }
                      text={selectedEnvironment}
                      variant="solid"
                    />
                  </HeaderChip>

                  <FormInput field={fields.name} customWidth="100%" />
                  <FormInput field={fields.uuid} customWidth="100%" />
                  <FormInput field={fields.adminEmails} customWidth="100%" />

                  <PlanContainer>
                    <Label>Plan</Label>
                    <PlanList>
                      {Object.keys(TenantPlanEnum).map(plan => {
                        const slug = TenantPlanEnum[plan as TenantPlanEnum]

                        return (
                          <FormRadioButton
                            key={plan}
                            field={{
                              type: 'string',
                              name: plan,
                              label: plan,
                              value: selectedPlan === slug,
                              setValue: () => {
                                setSelectedPlan(slug)
                                fields.plan.setValue(slug)
                              },
                            }}
                          />
                        )
                      })}
                    </PlanList>
                  </PlanContainer>
                </Section>
              )}

              {section === CreateTenantSections.SolidusConfiguration && (
                <Section>
                  <H4>Chord OMS Configuration</H4>
                  <FormSecureInput
                    field={fields.solidusApiKey}
                    customWidth="100%"
                    required
                  />
                  <FormInput
                    field={fields.solidusApiHost}
                    customWidth="100%"
                    required
                  />
                  <Row>
                    <FormInput
                      field={fields.solidusStoreId}
                      customWidth="100%"
                      required
                    />
                    <FormInput
                      field={fields.solidusTenantId}
                      customWidth="100%"
                      required
                    />
                  </Row>
                </Section>
              )}

              {section === CreateTenantSections.DataSources && (
                <Section>
                  <H4>Data Sources (optional)</H4>
                  <Label>
                    You can retrieve your Destination Group ID on the
                    destination page of your Fivetran dashboard.
                  </Label>
                  <FormInput
                    field={fields.fivetranGroupId}
                    customWidth="100%"
                  />

                  {dataConnectors.length > 0 && (
                    <CheckboxListContainer>
                      <Label>Available Connectors</Label>
                      <CheckboxList>
                        {dataConnectors
                          .filter(connector => connector.active)
                          .map((connector, i) => (
                            <StyledCheckbox
                              key={i}
                              field={{
                                name: 'Connector',
                                label: connector.name,
                                type: 'boolean',
                                value: selectedConnectors.includes(
                                  connector.slug
                                ),
                                setValue: val => {
                                  let updatedConnectors

                                  if (val) {
                                    updatedConnectors = [
                                      ...selectedConnectors,
                                      connector.slug,
                                    ]
                                    fields.filterConnectors.setValue(
                                      updatedConnectors
                                        .concat(connector.slug)
                                        .join(',')
                                    )
                                  } else {
                                    updatedConnectors =
                                      selectedConnectors.filter(
                                        c => c !== connector.slug
                                      )
                                    fields.filterConnectors.setValue(
                                      updatedConnectors.join(',')
                                    )
                                  }

                                  setSelectedConnectors(updatedConnectors)
                                },
                              }}
                            />
                          ))}
                      </CheckboxList>
                    </CheckboxListContainer>
                  )}
                </Section>
              )}

              {section === CreateTenantSections.DataActivations && (
                <Section>
                  <H4>Data Activations (optional)</H4>
                  <Label>
                    You can retrieve your workspace API key from the Census
                    workspace settings under the <b>API Access</b> section.
                  </Label>
                  <FormSecureInput
                    field={fields.censusApiKey}
                    customWidth="100%"
                  />
                </Section>
              )}

              {section === CreateTenantSections.LookerConfiguration && (
                <Section>
                  <H4>Looker Configuration (optional)</H4>
                  <Row>
                    <FormInput
                      field={fields.lookerClientId}
                      customWidth="100%"
                    />
                    <FormSecureInput
                      field={fields.lookerClientSecret}
                      customWidth="100%"
                    />
                  </Row>
                  <FormInput field={fields.lookerGroupIds} customWidth="100%" />
                  <FormInput
                    field={fields.lookerSharedFolderId}
                    customWidth="100%"
                  />

                  <CheckboxListContainer>
                    <Label>Models</Label>
                    <CheckboxList>
                      {Object.values(LookerModels).map((model, i) => (
                        <StyledCheckbox
                          key={i}
                          field={{
                            name: 'Model',
                            label: model,
                            type: 'boolean',
                            value: selectedModels.includes(model),
                            setValue: val => {
                              let updatedModels

                              if (val) {
                                updatedModels = [...selectedModels, model]
                              } else {
                                updatedModels = selectedModels.filter(
                                  c => c !== model
                                )
                              }

                              // Keep track of the selected models in state for our UI purposes
                              setSelectedModels(updatedModels)

                              // Update the form field with the selected models so we can save them
                              // to the database as a comma-separated string
                              fields.lookerModels.setValue(
                                updatedModels.join(',')
                              )
                            },
                          }}
                        />
                      ))}
                    </CheckboxList>
                  </CheckboxListContainer>
                </Section>
              )}

              {section === CreateTenantSections.ConfirmTenantCreation && (
                <CreateTenantConfirmation
                  fields={fields}
                  selectedConnectors={selectedConnectors}
                  selectedModels={selectedModels}
                  selectedPlan={selectedPlan}
                  dataConnectors={dataConnectors}
                  selectedEnvironment={selectedEnvironment}
                />
              )}
            </Container>

            <Bottom>
              {section === CreateTenantSections.TenantInfo && (
                <>
                  <Button onClick={onClose} purpose="secondary">
                    Cancel
                  </Button>

                  <Button
                    variant="tertiary"
                    onClick={goToNextSection}
                    disabled={!isValidTenantInfo()}
                  >
                    Next
                  </Button>
                </>
              )}

              {(section === CreateTenantSections.DataSources ||
                section === CreateTenantSections.DataActivations ||
                section === CreateTenantSections.LookerConfiguration) && (
                <>
                  <Button variant="tertiary" onClick={goToPreviousSection}>
                    Back
                  </Button>

                  <Button variant="tertiary" onClick={goToNextSection}>
                    Next
                  </Button>
                </>
              )}

              {section === CreateTenantSections.SolidusConfiguration && (
                <>
                  <Button variant="tertiary" onClick={goToPreviousSection}>
                    Back
                  </Button>
                  <Button
                    variant="tertiary"
                    onClick={goToNextSection}
                    disabled={!isValidSolidusConfig()}
                  >
                    Next
                  </Button>
                </>
              )}

              {section === CreateTenantSections.ConfirmTenantCreation && (
                <>
                  <Button variant="tertiary" onClick={goToPreviousSection}>
                    Back
                  </Button>

                  <Button
                    type="submit"
                    purpose="primary"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Create Tenant
                  </Button>
                </>
              )}
            </Bottom>
          </>
        )}
      </form>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
  margin-bottom: 70px;
`

const HeaderChip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  gap: 8px;
  > * {
    flex: 1;
  }
`

const Label = styled.div`
  margin-bottom: 4px;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre-wrap;
`

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
`

const PlanList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;
  z-index: 1;
  height: 70px;
  border-radius: 0 0 ${p => p.theme.CardLargeRadius} ${p => p.theme.CardLargeRadius};
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const CheckboxListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`

const CheckboxList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
`

const StyledCheckbox = styled(FormCheckbox)`
  width: 100%;
  flex-basis: 50%;
  margin-top: 0;
`
