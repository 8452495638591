import { useMemo } from 'react'
import { useLookerData } from 'redux/state/looker'

const usePinnedReport = slug => {
  const {
    state: { availableReports },
    getAvailableReports,
  } = useLookerData()

  const pinnedReports = useMemo(
    () => [
      ...availableReports.dashboards,
      ...availableReports.explores,
      ...availableReports.looks,
    ],
    [availableReports]
  )

  const report = useMemo(
    () => pinnedReports.find(r => r.slug === slug),
    [pinnedReports, slug]
  )

  return {
    report,
    isPinned: report !== undefined,
    getAvailableReports,
  }
}

export default usePinnedReport
