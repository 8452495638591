import React, { useRef, useEffect, useState } from 'react'
import { LookerEmbedSDK } from '@looker/embed-sdk'
import { useTheme } from '@chordco/component-library'
import { CloseX } from '@chordco/component-library/components/icons/standardIcons/CloseX'
import { EmbedContainer } from './EmbedContainer'
import { ErrorContainer } from './ErrorContainer'
import { EnvVarConfig } from 'utils/envVarConfig'

export interface LookEmbedProps {
  url: string
}

export const LookEmbed = ({ url }: LookEmbedProps) => {
  const theme = useTheme()

  const ref = useRef<HTMLDivElement>(null)

  const [error, setError] = useState<string | null>(null)

  const lookerHost = EnvVarConfig.REACT_APP_LOOKER_HOST

  useEffect(() => {
    const el = ref.current
    if (el && lookerHost && url) {
      el.innerHTML = ''

      LookerEmbedSDK.createLookWithUrl(url)
        .withApiHost(lookerHost)
        .withDialogScroll(true)
        .withDynamicIFrameHeight(true)
        .withScrollMonitor(true)
        .appendTo(el)
        .build()
        .connect()
        .catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.error('Error loading Look:', error)
          setError('We are unable to load the Look. Please try again later.')
        })
    }
  }, [url, lookerHost])

  return (
    <>
      {error && (
        <ErrorContainer>
          <div>{error}</div>
          <span onClick={() => setError(null)}>
            <CloseX fill={theme.GREY_80} />
          </span>
        </ErrorContainer>
      )}
      <EmbedContainer id="embedcontainer" ref={ref} />
    </>
  )
}
