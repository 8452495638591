import React from 'react'
import {
  Box,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@mui/material'
import { ParsedIncomingEventRow } from 'components/chord-cdp/events/incoming'
import { TrackChanges, Person, HelpOutline, LanguageOutlined, Link } from '@mui/icons-material'
import { NoEvents } from 'components/chord-cdp/common/NoEvents'

type IncomingEventsTableProps = {
  rows: ParsedIncomingEventRow[]
  onRowClick: (row: ParsedIncomingEventRow) => void
}

export const IncomingEventsTable: React.FC<IncomingEventsTableProps> = ({
  rows = [],
  onRowClick,
}) => {
  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <TableContainer component={Paper}>
        <Table aria-label="Incoming Events" size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'grey.200' }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Date (UTC)</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Page Path</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <NoEvents />
                </TableCell>
              </TableRow>
            )}

            {rows.length > 0 &&
              rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? 'white' : 'grey.50',
                    cursor: 'pointer',
                  }}
                  onClick={() => onRowClick(row)}
                >
                  <TableCell sx={{ width: 120, minWidth: 120 }}>{row.date}</TableCell>
                  <TableCell sx={{ width: 140, textTransform: 'capitalize' }}>
                    <Tooltip title={`Event Type: ${row.event}`} placement="top">
                      <Chip
                        label={row.event}
                        variant="filled"
                        color="primary"
                        size="small"
                        icon={<TrackChanges />}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: 320,
                    }}
                  >
                    <Tooltip title={row.pagePath} placement="top">
                      <a href={row.pageUrl} target="_blank" rel="noopener noreferrer">
                        {row.pagePath}
                      </a>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        minHeight: 26,
                      }}
                    >
                      {row.summary['host'] && (
                        <Tooltip title={`Host: ${row.summary['host']}`} placement="top">
                          <Chip
                            label={row.summary['host']}
                            variant="filled"
                            color="secondary"
                            icon={<LanguageOutlined />}
                            size="small"
                          />
                        </Tooltip>
                      )}

                      {row.summary['email'] && (
                        <Tooltip title="Email" placement="top">
                          <Chip
                            label={row.summary['email']}
                            variant="filled"
                            color="secondary"
                            icon={<Person />}
                            size="small"
                          />
                        </Tooltip>
                      )}

                      {row.summary['useId'] && !row.summary['email'] && (
                        <Tooltip title={`User ID: ${row.summary['userId']}`} placement="top">
                          <Chip
                            label={row.summary['userId']}
                            variant="filled"
                            color="secondary"
                            icon={<Person />}
                            size="small"
                          />
                        </Tooltip>
                      )}

                      {row.summary['referringDomain'] &&
                        row.summary['host'] !== row.summary['referringDomain'] && (
                          <Tooltip
                            title={`Referring Domain: ${row.summary['referringDomain']}`}
                            placement="top"
                          >
                            <Chip
                              label={row.summary['referringDomain']}
                              variant="filled"
                              color="success"
                              icon={<Link />}
                              size="small"
                              sx={{ maxWidth: 300 }}
                            />
                          </Tooltip>
                        )}

                      {!row.summary['userId'] && row.summary['anonymousId'] && (
                        <Tooltip
                          title={`Anonymous ID: ${row.summary['anonymousId']}`}
                          placement="top"
                        >
                          <Chip
                            label={row.summary['anonymousId']}
                            variant="filled"
                            color="default"
                            icon={<HelpOutline />}
                            size="small"
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
