import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Label } from '@chordco/component-library'
import { FormControl, Select, SelectChangeEvent, MenuItem } from '@mui/material'
import { ReportCategory, ReportFilters, ReportType, useReportsFilter } from 'redux/state/reportsFilter'
import SearchBar from './SearchBar'

type ReportToolbarProps = {
  onFiltersChange: (filters: ReportFilters) => void
}

export const ReportToolbar: React.FC<ReportToolbarProps> = ({ onFiltersChange }) => {
  const {
    state: { selectedType = ReportType.ALL_REPORTS, selectedCategory = ReportCategory.ALL_CATEGORIES, searchTerm = '' },
    setFilters,
  } = useReportsFilter()

  const optionCategories = Object.values(ReportCategory).map(category => ({
    id: category,
    label: category,
  }))

  const optionTypes = Object.values(ReportType).map(type => ({
    id: type,
    label: type,
  }))

  const handleTypeSelect = (type: ReportType) => {
    const filters = {
      selectedType: type,
      selectedCategory,
      searchTerm,
    }
    setFilters(filters)
    onFiltersChange(filters)
  }

  const handleCategorySelect = (category: ReportCategory) => {
    const filters = {
      selectedType,
      selectedCategory: category,
      searchTerm,
    }
    setFilters(filters)
    onFiltersChange(filters)
  }

  const handleOnSearchChange = (searchTerm: string) => {
    const filters = {
      selectedType,
      selectedCategory,
      searchTerm,
    }
    setFilters(filters)
    onFiltersChange(filters)
  }

  useEffect(() => {
    // Set initial filters so that the parent component can filter the reports on load
    const initialFilters = {
      selectedType,
      selectedCategory,
      searchTerm,
    }

    onFiltersChange(initialFilters)
  }, [])

  return (
    <Wrapper>
      <SearchBar placeholder="Search reports..." onSearchChange={handleOnSearchChange} />
      <RightContainer>
        <FilterContainer>
          <Label>Type</Label>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Select
              value={optionTypes.find(o => o.id === selectedType)?.id}
              onChange={(event: SelectChangeEvent<string>) => {
                handleTypeSelect(event.target.value as ReportType)
              }}
            >
              {optionTypes.length > 0 &&
                optionTypes.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}

              {optionTypes.length === 0 && (
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </FilterContainer>
        <FilterContainer>
          <Label>Category</Label>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Select
              value={optionCategories.find(o => o.id === selectedCategory)?.id}
              onChange={(event: SelectChangeEvent<string>) => {
                handleCategorySelect(event.target.value as ReportCategory)
              }}
            >
              {optionCategories.length > 0 &&
                optionCategories.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}

              {optionCategories.length === 0 && (
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </FilterContainer>
      </RightContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-top: 8px;
  padding-bottom: 24px;
`

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`
