import React, { useEffect, useState } from 'react'
import { AuthWrapper, Form, SubHeading } from './AuthWrapper'
import { Button, H1, H2, standardIcons } from '@chordco/component-library'
import { useIsMobile, useForm } from 'hooks'
import { loginSchema } from 'validationSchemas/auth'
import { FormInputUnstyled as Input } from '../common'
import { useAuthData } from 'redux/state/auth'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useEverySucceeded, useIsLoading } from 'redux/utils'
import { EnvVarConfig } from 'utils/envVarConfig'

const { GoogleLogo } = standardIcons

const magicLink = `${window.location.origin}/authenticate`

export const Login: React.FC = () => {
  const { state, sendEmail } = useAuthData()

  const isSendEmailLoading = useIsLoading('sendEmail')
  const hasSendEmailSucceeded = useEverySucceeded('sendEmail')

  const { fields, onSubmit } = useForm(loginSchema, ({ email }) =>
    sendEmail({
      user: { email: email.toLowerCase() },
      callback: magicLink,
    })
  )

  const [emailSent, setEmailSent] = useState(false)
  useEffect(() => {
    if (hasSendEmailSucceeded) setEmailSent(true)
  }, [hasSendEmailSucceeded])

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()
  if (state.isAuthenticated) history.push('')

  const tryAgain = () => {
    setEmailSent(false)
    fields.email.setValue('')
  }

  const onClick = emailSent ? tryAgain : onSubmit

  const onGoogleClick = () => {
    window.open(EnvVarConfig.REACT_APP_STYTCH_GOOGLE_CLIENT_URL, '_self')
  }

  return (
    <AuthWrapper>
      <Form isMobile={isMobile} onSubmit={onClick}>
        {emailSent ? (
          <>
            <Heading>Check your email</Heading>
            <HasAccount>
              If <Bold>{fields.email.value}</Bold> has an account, you&apos;ll
              see a link to sign in shortly!
            </HasAccount>
            <Button
              onClick={onClick}
              name="Didn't get one? Try again."
              location="Log In"
            >
              Didn&apos;t get one? Try again.
            </Button>
          </>
        ) : (
          <>
            <Heading>Log In</Heading>
            <SubHeading isMobile={isMobile}>
              We&apos;ll email you a magic link for a password-free sign in
            </SubHeading>
            <Input field={fields.email} customWidth="100%" />
            <Button
              onClick={onClick}
              isLoading={isSendEmailLoading}
              name="Continue with Email"
              location="Log In"
            >
              Continue with email
            </Button>
            <Separator>Or</Separator>
            <Button
              purpose="tertiary"
              icon={GoogleLogo}
              onClick={onGoogleClick}
              centreContent={true}
              variant="outlined"
              name="Continue with Google"
              location="Log In"
            >
              Continue with Google
            </Button>
          </>
        )}
      </Form>
    </AuthWrapper>
  )
}

const HasAccount = styled.div`
  margin: 32px 0;
`

const Bold = styled.span`
  font-weight: 600;
`

const Separator = styled.p`
  text-align: center;
`
