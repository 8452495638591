import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { useIsMobile, useResponsiveHeight } from '../../hooks'
import { logos, useTheme } from '@chordco/component-library'
import SimpleBarReact from 'simplebar-react'
import { ForceThemeProvider } from 'themes/ThemeSwitcherProvider'

export const AuthWrapper: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const isMobile = useIsMobile()
  const ref = useRef() as any
  const height = useResponsiveHeight(ref)
  const isSmall = isMobile || height < 900

  const theme = useTheme()

  const { ChordCommerceFullLogo, ChordLogo } = logos

  return (
    <ForceThemeProvider theme="light">
      <Wrapper ref={ref}>
        <SubWrapper isSmall={isSmall}>
          <Header isSmall={isSmall}>
            <LogoFullContainer>
              <ChordCommerceFullLogo fill={theme.WHITE} />
            </LogoFullContainer>
          </Header>
          <SimpleBarReact
            style={{ maxHeight: `calc(100vh - ${isSmall ? 115 : 192}px)` }}
          >
            <Content isSmall={isSmall}>{children}</Content>
          </SimpleBarReact>
        </SubWrapper>
        <Footer isSmall={isSmall}>
          <LogoContainer isSmall={isSmall}>
            <ChordLogo fill={theme.BLACK} size={isSmall ? 'medium' : 'large'} />
          </LogoContainer>
        </Footer>
      </Wrapper>
    </ForceThemeProvider>
  )
}

const Wrapper = styled.div`
  background-color: ${p => p.theme.ReverseBackgroundBase1};
  height: 100vh;
  color: ${p => p.theme.ContentPrimary};
`

const SubWrapper = styled.div<{ isSmall: boolean }>`
  background-color: ${p => p.theme.ReverseBackgroundBase1};
  height: calc(100vh - ${p => (p.isSmall ? 57 : 90)}px);
  border-radius: 0 0 32px 32px;
`

const Header = styled.div<{ isSmall: boolean }>`
  // height: ${p => (p.isSmall ? 58 : 102)}px;
  height: auto;
  background-color: transparent;
  border-radius: 0 0 32px 32px;
  padding: ${p => (p.isSmall ? 24 : 32)}px;
`

const LogoFullContainer = styled.div`
  margin: 0 auto;
  display: flex;
  width: 300px;
  justify-content: center;
`

const Content = styled.div<{ isSmall: boolean }>`
  margin: 0 auto 12px auto;
  text-align: center;
  padding-top: ${p => (p.isSmall ? 18 : 96)}px;
`

const Footer = styled.div<{ isSmall: boolean }>`
  position: fixed;
  bottom: 0;
  height: ${p => (p.isSmall ? 57 : 90)}px;
  width: 100vw;
  padding-top: ${p => (p.isSmall ? 12 : 20)}px; ;
`

const LogoContainer = styled.div<{ isSmall: boolean }>`
  margin: 0 auto;
  width: ${p => (p.isSmall ? 29 : 43)}px;
`

const FormStyles = css<{
  isMobile: boolean
  inputMarginBottom?: string
}>`
  box-sizing: border-box;
  width: ${p => (p.isMobile ? 'calc(100vw - 24px)' : '480px')};
  margin: 0 auto;
  // border: solid 1px ${p => p.theme.PRIMARY_50};
  background-color: ${p => p.theme.BgBase1};
  border-radius: ${p => p.theme.CardLargeRadius};
  padding: ${p => (p.isMobile ? 24 : 56)}px;

  input {
    margin-bottom: ${p => p.inputMarginBottom || `${p.isMobile ? 12 : 28}px`};
  }

  button {
    width: 100%;
  }
`

export const Form = styled.form`
  ${FormStyles}
`

export const CentralContent = styled.div<{
  isConfirmation?: boolean
  isMobile: boolean
}>`
  ${FormStyles}
  ${p =>
    p.isConfirmation &&
    css`
      padding-top: 33px;
    `}
`

export const SubHeading = styled.div<{ isMobile: boolean }>`
  margin: ${p => (p.isMobile ? '8px 0 18px 0' : '16px 0 36px 0')};
  padding: ${p => (p.isMobile ? '0 0px' : '0 68px')};
`
