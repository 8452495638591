import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import { Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Icon, Page, standardIcons } from '@chordco/component-library'
import { useIsMobile, useExperiments } from 'hooks'
import { useAuthData } from 'redux/state/auth'
import { useStoresData } from 'redux/state/stores'
import { useTenantsData } from 'redux/state/tenants'
import { LoadingPage } from './common'
import { Navigation } from './navigation'
import { UserHeader } from './navigation/Header'
import { Insights } from './notifications/Insights'
import { useEmbeddedOmsPage } from 'hooks/useEmbeddedOmsPage'
import { usePage } from 'hooks/usePage'

export const NavigationContext = React.createContext({} as any)

export const AuthenticatedRoutes: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const [navOpen, setNavOpen] = useState(true)

  const isMobile = useIsMobile()
  const isSmall = useMediaQuery({ query: '(max-width: 750px)' })
  const isLarge = useMediaQuery({ query: '(min-width: 1199px)' })
  const hasInsights = useExperiments('hub_copilot')
  const isOmsEmbedded = useEmbeddedOmsPage()
  const isLookerEmbedded = usePage('analytics')
  const isModelAmplificationsPage = usePage('model-amplifications')
  const isAudiencesPage = usePage('audiences')
  const isCdpPage = usePage('chord-cdp')
  const isIFrame = isOmsEmbedded || isLookerEmbedded
  const isFullWidth = isModelAmplificationsPage || isAudiencesPage || isCdpPage

  useEffect(() => {
    if (isMobile || isSmall) setNavOpen(false)
    else if (isLarge) setNavOpen(true)
  }, [isMobile, isSmall, isLarge])

  const {
    state: { user, isAuthenticated },
    getAuthenticatedUser,
  } = useAuthData()
  const {
    state: { tenants, currentTenant, currentStore },
    getUserTenants,
  } = useTenantsData()
  useEffect(() => {
    if (!user && isAuthenticated) getAuthenticatedUser()
    if (!tenants.length) getUserTenants()
  }, [user])

  const {
    state: { solidusInfo },
    fetchStores,
  } = useStoresData()
  useEffect(() => {
    if (currentTenant?.id && Object.keys(solidusInfo).length === 0)
      fetchStores(currentTenant.id)
  }, [currentTenant])

  const history = useHistory()

  if (!isAuthenticated) {
    history.push('/login')
    return null
  }
  if (!user) return <LoadingPage />
  if (!currentTenant) return <LoadingPage />

  if (!Object.keys(solidusInfo).length && currentStore?.plan === 'autonomy')
    return <LoadingPage />

  if (!user.firstName || !user.lastName) {
    history.push('/accept')
  }

  const toggleHideNav = () => setNavOpen(curVal => !curVal)

  return (
    <Container>
      <Navigation
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        onHide={toggleHideNav}
        user={user}
      />
      <Page
        navOpen={navOpen}
        data-testid="page"
        isIframe={isIFrame}
        isFullWidth={isFullWidth}
      >
        <NavigationContext.Provider value={{ navOpen }}>
          <Switch>{children}</Switch>
        </NavigationContext.Provider>
      </Page>
      {!isMobile && (
        <Hide navOpen={navOpen} data-testid="hideButton">
          <Icon
            icon={navOpen ? standardIcons.BurgerArrow : standardIcons.Burger}
            purpose="ghost"
            size="small"
            onClick={toggleHideNav}
          />
        </Hide>
      )}
      {hasInsights?.enabled && <Insights />}
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  overflow-y: hidden;
`

const Hide = styled.div<{ navOpen: boolean }>`
  position: absolute;
  top: 16px;
  left: ${p => (p.navOpen ? '232px' : '16px')};
  transition: 300ms;

  path {
    fill: ${p => p.theme.PRIMARY_60};
  }

  :hover {
    ${UserHeader} {
      background-color: ${p => p.theme.GREY_30};
    }

    path {
      fill: ${p => p.theme.PRIMARY_60};
    }
  }
`
