import React, { useEffect } from 'react'
import { Label, Button, standardIcons } from '@chordco/component-library'
import { FormControl, Select, SelectChangeEvent, MenuItem } from '@mui/material'
import {
  QuickstartFilters,
  QuickstartCategory,
  QuickstartType,
  useQuickstartsFilter,
} from 'redux/state/quickstartsFilter'
import styled from 'styled-components'
import SearchBar from './SearchBar'
import useUserRole from 'redux/hooks/useUserRole'

type QuickstartToolbarProps = {
  onFiltersChange: (filters: QuickstartFilters) => void
  onRefresh: () => void
  categories: string[]
}

export const QuickstartToolbar: React.FC<QuickstartToolbarProps> = ({
  onFiltersChange,
  onRefresh,
  categories,
}) => {
  const role = useUserRole()

  const {
    state: {
      selectedType = QuickstartType.ALL_REPORTS,
      selectedCategory = QuickstartCategory.ALL_CATEGORIES,
      searchTerm = '',
    },
    setFilters,
  } = useQuickstartsFilter()

  const optionTypes = Object.values(QuickstartType).map(type => ({
    id: type,
    label: type,
  }))

  const optionCategories = categories.map(category => ({
    id: category,
    label: category,
  }))

  const handleTypeSelect = (type: QuickstartType) => {
    const filters = {
      selectedType: type,
      selectedCategory,
      searchTerm,
    }
    setFilters(filters)
    onFiltersChange(filters)
  }

  const handleCategorySelect = (category: QuickstartCategory) => {
    const filters = {
      selectedType,
      selectedCategory: category,
      searchTerm,
    }
    setFilters(filters)
    onFiltersChange(filters)
  }

  const handleSearchChange = (searchTerm: string) => {
    const filters = {
      selectedType,
      selectedCategory,
      searchTerm,
    }
    setFilters(filters)
    onFiltersChange(filters)
  }

  useEffect(() => {
    // Set initial filters so that the parent component can filter the reports on load
    const initialFilters = {
      selectedType,
      selectedCategory,
      searchTerm,
    }

    onFiltersChange(initialFilters)
  }, [])

  return (
    <Wrapper>
      <SearchBar
        placeholder="Search quickstarts..."
        onSearchChange={handleSearchChange}
      />
      <RightContainer>
        <FilterContainer>
          <Label>Type</Label>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Select
              value={optionTypes.find(o => o.id === selectedType)?.id}
              onChange={(event: SelectChangeEvent<string>) => {
                handleTypeSelect(event.target.value as QuickstartType)
              }}
            >
              {optionTypes.length > 0 &&
                optionTypes.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}

              {optionTypes.length === 0 && (
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </FilterContainer>
        <FilterContainer>
          <Label>Category</Label>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Select
              value={optionCategories.find(o => o.id === selectedCategory)?.id}
              onChange={(event: SelectChangeEvent<string>) => {
                handleCategorySelect(event.target.value as QuickstartCategory)
              }}
            >
              {optionCategories.length > 0 &&
                optionCategories.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}

              {optionCategories.length === 0 && (
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </FilterContainer>

        {(role === 'admin' || role === 'superuser') && (
          <Button
            purpose="tertiary"
            onClick={onRefresh}
            icon={standardIcons.Refresh}
            tooltip="Clear cache and refresh quickstarts. Use this if you are not seeing the latest quickstarts."
            tooltipDirection="input-bottom-right"
          >
            Refresh
          </Button>
        )}
      </RightContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-top: 8px;
  padding-bottom: 24px;
`

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`
