import React, { createContext, useState, useCallback, useMemo, useContext } from 'react'
import {
  RawIncomingEvent,
  RawDestinationEvent,
  ConfigurationObjectLink,
  DestinationConfig,
  ServiceConfig,
  StreamConfig,
} from 'types/cdp'
import { StoreEnvironment } from 'types'
import { cdpClient } from 'api/clients/cdpClient'
import { IncomingEventFilters } from 'components/chord-cdp/events/incoming/IncomingEventsToolbar'
import { DestinationEventFilters } from '../events/destinations/DestinationEventsToolbar'

interface CdpProviderProps {
  children: React.ReactNode
  tenantId: number
  storeId: number
  environment: StoreEnvironment
}

export type CdpProviderErrors = {
  incomingEvents: string | null
  destinationEvents: string | null
  streams: string | null
  destinations: string | null
  connectors: string | null
  links: string | null
}

type CdpContextValue = {
  incomingEvents: RawIncomingEvent[]
  destinationEvents: RawDestinationEvent[]
  streams: StreamConfig[]
  destinations: DestinationConfig[]
  connectors: ServiceConfig[]
  links: ConfigurationObjectLink[]
  loadingStates: {
    incomingEvents: boolean
    destinationEvents: boolean
    streams: boolean
    destinations: boolean
    connectors: boolean
    links: boolean
  }
  errors: CdpProviderErrors
  fetchIncomingEvents: (sourceId: string, filters: IncomingEventFilters) => Promise<void>
  fetchDestinationEvents: (destinationId: string, filters: DestinationEventFilters) => Promise<void>
  fetchStreams: () => Promise<void>
  fetchDestinations: () => Promise<void>
  fetchConnectors: () => Promise<void>
  fetchLinks: () => Promise<void>
}

const CdpContext = createContext<CdpContextValue | undefined>(undefined)

export const useCdp = () => {
  const context = useContext(CdpContext)
  if (!context) {
    throw new Error('useCdp must be used within a CdpProvider')
  }
  return context
}

export const CdpProvider: React.FC<CdpProviderProps> = ({
  children,
  tenantId,
  storeId,
  environment,
}) => {
  const [incomingEvents, setIncomingEvents] = useState<RawIncomingEvent[]>([])
  const [destinationEvents, setDestinationEvents] = useState<RawDestinationEvent[]>([])
  const [streams, setStreams] = useState<StreamConfig[]>([])
  const [destinations, setDestinations] = useState<DestinationConfig[]>([])
  const [connectors, setConnectors] = useState<ServiceConfig[]>([])
  const [links, setLinks] = useState<ConfigurationObjectLink[]>([])

  const [loadingStates, setLoadingStates] = useState({
    incomingEvents: false,
    destinationEvents: false,
    streams: false,
    destinations: false,
    connectors: false,
    links: false,
  })

  const [errors, setErrors] = useState<CdpProviderErrors>({
    streams: null,
    destinations: null,
    connectors: null,
    links: null,
    incomingEvents: null,
    destinationEvents: null,
  })

  const fetchIncomingEvents = useCallback(
    async (sourceId: string, filters: IncomingEventFilters) => {
      setLoadingStates(prev => ({ ...prev, incomingEvents: true }))
      setErrors(prev => ({ ...prev, incomingEvents: null }))
      try {
        const events = await cdpClient.getIncomingEvents({
          tenantId,
          storeId,
          sourceId,
          environment,
          dateFrom: filters.dateFrom,
          dateTo: filters.dateTo,
          statusLevel: filters.statusLevel,
        })
        setIncomingEvents(events)
      } catch (error) {
        setErrors(prev => ({ ...prev, incomingEvents: 'Failed to fetch incoming events' }))

        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        setLoadingStates(prev => ({ ...prev, incomingEvents: false }))
      }
    },
    [tenantId, storeId, environment]
  )

  const fetchDestinationEvents = useCallback(
    async (destinationId: string, filters: DestinationEventFilters) => {
      setLoadingStates(prev => ({ ...prev, destinationEvents: true }))
      setErrors(prev => ({ ...prev, destinationEvents: null }))
      try {
        const events = await cdpClient.getDestinationEvents({
          tenantId,
          storeId,
          destinationId,
          environment,
          dateFrom: filters.dateFrom,
          dateTo: filters.dateTo,
          statusLevel: filters.statusLevel,
          mode: filters.mode,
        })
        setDestinationEvents(events)
      } catch (error) {
        setErrors(prev => ({ ...prev, destinationEvents: 'Failed to fetch destination events' }))

        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        setLoadingStates(prev => ({ ...prev, destinationEvents: false }))
      }
    },
    [tenantId, storeId, environment]
  )

  const fetchStreams = useCallback(async () => {
    setLoadingStates(prev => ({ ...prev, streams: true }))
    setErrors(prev => ({ ...prev, streams: null }))
    try {
      const data = await cdpClient.getStreams({ tenantId, storeId, environment })
      setStreams(data.objects)
    } catch (error) {
      setErrors(prev => ({ ...prev, streams: 'Failed to fetch streams' }))

      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setLoadingStates(prev => ({ ...prev, streams: false }))
    }
  }, [tenantId, storeId, environment])

  const fetchDestinations = useCallback(async () => {
    setLoadingStates(prev => ({ ...prev, destinations: true }))
    setErrors(prev => ({ ...prev, destinations: null }))
    try {
      const data = await cdpClient.getDestinations({ tenantId, storeId, environment })
      setDestinations(data.objects)
    } catch (error) {
      setErrors(prev => ({ ...prev, destinations: 'Failed to fetch destinations' }))

      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setLoadingStates(prev => ({ ...prev, destinations: false }))
    }
  }, [tenantId, storeId, environment])

  const fetchConnectors = useCallback(async () => {
    setLoadingStates(prev => ({ ...prev, connectors: true }))
    setErrors(prev => ({ ...prev, connectors: null }))
    try {
      const data = await cdpClient.getConnectors({ tenantId, storeId, environment })
      setConnectors(data.objects)
    } catch (error) {
      setErrors(prev => ({ ...prev, connectors: 'Failed to fetch connectors' }))

      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setLoadingStates(prev => ({ ...prev, connectors: false }))
    }
  }, [tenantId, storeId, environment])

  const fetchLinks = useCallback(async () => {
    setLoadingStates(prev => ({ ...prev, links: true }))
    try {
      const data = await cdpClient.getLinks({ tenantId, storeId, environment })
      setLinks(data.links)
    } catch (error) {
      setErrors(prev => ({ ...prev, links: 'Failed to fetch links' }))

      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setLoadingStates(prev => ({ ...prev, links: false }))
    }
  }, [tenantId, storeId, environment])

  const contextValue: CdpContextValue = useMemo(
    () => ({
      incomingEvents,
      destinationEvents,
      streams,
      destinations,
      connectors,
      links,
      loadingStates,
      errors,
      fetchIncomingEvents,
      fetchDestinationEvents,
      fetchStreams,
      fetchDestinations,
      fetchConnectors,
      fetchLinks,
    }),
    [
      incomingEvents,
      destinationEvents,
      streams,
      destinations,
      connectors,
      links,
      loadingStates,
      errors,
      fetchIncomingEvents,
      fetchDestinationEvents,
      fetchStreams,
      fetchDestinations,
      fetchConnectors,
      fetchLinks,
    ]
  )

  return <CdpContext.Provider value={contextValue}>{children}</CdpContext.Provider>
}
