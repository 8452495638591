import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Unpin: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 2.16667C14.6269 2.16667 15 2.53976 15 3C15 3.46024 14.6269 3.83333 14.1667 3.83333V10.6458L12.5 8.97917V3.83333H9.16667V5.64583L6.66667 3.14583V2.16667H14.1667ZM10.8333 18.8333L10 18V13H5.83333V11.3333L7.5 9.66667V8.70833L2.58003 3.75109C2.25934 3.42797 2.26032 2.90635 2.58223 2.58443C2.90501 2.26166 3.42833 2.26166 3.7511 2.58443L17.8957 16.7291C18.2277 17.0611 18.223 17.6008 17.8852 17.9269C17.5557 18.245 17.0321 18.2404 16.7082 17.9166L11.7917 13H11.6667V18L10.8333 18.8333ZM8.20833 11.3333H10.125L9.16667 10.375L8.20833 11.3333Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
