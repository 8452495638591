import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Sheet, H1, Spinner } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { ConfirmModal } from '../dialogs/ConfirmModal'
import { useAuthData } from 'redux/state/auth'
import useCensusSlackEvent from 'hooks/useCensusSlackEvent'
import { useTenantsData } from 'redux/state/tenants'
import { CensusConnectLink, CensusConnector, CensusDestination } from 'api/census/interfaces'
import { useCensus } from '../CensusContext'
import { EnvVarConfig } from 'utils/envVarConfig'
import { HealthChip } from '../components/HealthChip'
import { LastTestResults } from '../components'
import { formatRelativeTime } from 'utils/dateTime'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

type Props = {
  destination: CensusDestination
  onClose: () => void
  onDeleteSuccess: (message: string) => void
  onFinishSetupSuccess: (connectLink: CensusConnectLink) => void
  onError: (message: string) => void
}

export const EditDestinationSheet: React.FC<Props> = ({
  destination,
  onClose,
  onDeleteSuccess,
  onFinishSetupSuccess,
  onError,
}) => {
  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')

  const {
    state: { currentTenant },
  } = useTenantsData()

  if (!currentTenant) throw new Error('Current Tenant not found!')

  const { censusClient } = useCensus()
  const { trackEvent } = useMixpanel()
  const { sendSlackEvent } = useCensusSlackEvent()

  const isMobile = useIsMobile()

  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false)
  const [connector, setConnector] = useState<CensusConnector | undefined>()

  const handleDelete = async () => {
    setShowDeleteAlertModal(false)
    setIsLoading(true)

    try {
      const result = await censusClient?.deleteDestination(destination.id)

      if (result?.status === 'deleted') {
        await sendSlackEvent('DESTINATION_DELETED', {
          tenantName: currentTenant.name,
          destinationName: destination.name,
          hubUser: user,
        })

        onDeleteSuccess(`${destination.name} has been successfully deleted`)
      } else {
        throw new Error(
          'An error occurred while deleting your destination. Please try again.'
        )
      }
    } catch (error: any) {
      onError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const finishSetup = async () => {
    setIsLoading(true)

    try {
      const response = await censusClient?.reauthorizeDestinationConnectLink({
        destinationId: destination.id,
        redirectUri: EnvVarConfig.REACT_APP_CENSUS_CONNECT_CARD_REDIRECT_URI,
      })

      const { status, data } = response ?? {}

      if (status === 'success' && data) {
        onFinishSetupSuccess(data)
      } else {
        throw new Error(
          'An error occurred while reauthorizing your destination. Please try again.'
        )
      }
    } catch (error: any) {
      onError(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  const isAdminOrSuperuser =
    user?.role === 'admin' || user?.role === 'superuser'

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const connectorRes = await censusClient?.getConnector(destination.type)

        if (connectorRes?.status === 'success' && connectorRes?.data) {
          setConnector(connectorRes.data)
        }

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [censusClient, destination.type])

  const onClickLearnMore = () => {
    trackEvent(MixpanelEventType.LinkClicked, {
      'Link Name': `Learn More about ${connector?.label}`,
      'Link URL': `https://docs.getcensus.com/destinations/${connector?.documentationSlug}`,
      'Page Name': 'Data Activations',
      'Slidesheet Name': 'Destination Details',
    })
  }

  return (
    <>
      <Sheet
        title="Destination Details"
        onClose={onClose}
        isLarge
        name="Destination Details"
        location="Data Activations"
      >
        <Container isMobile={isMobile}>
          <Logo src={connector?.logoSmall} alt={connector?.logoSmall} size={32} />
          <H1>{connector?.label}</H1>
          <Link
            href={`https://docs.getcensus.com/destinations/${connector?.documentationSlug}`}
            target="_blank"
            onClick={onClickLearnMore}
          >
            Learn more about {connector?.label}
          </Link>

          {!isLoading && (
            <>
              <Details>
                <Property>
                  <PropertyLabel>Status:</PropertyLabel>
                  <PropertyValue>
                    <HealthChip
                      healthy={destination.lastTestSucceeded}
                      iconOnly={isMobile}
                    />
                  </PropertyValue>
                </Property>

                <Property>
                  <PropertyLabel>Created At:</PropertyLabel>
                  <PropertyValue>
                    {formatRelativeTime(destination.createdAt)}
                  </PropertyValue>
                </Property>

                <LastTestResults
                  results={destination.lastTestResults}
                  lastTestedAt={destination.lastTestedAt}
                  lastTestSucceeded={destination.lastTestSucceeded}
                  canFinishSetup={isAdminOrSuperuser}
                  isLoading={isLoading}
                  handleFinishSetup={finishSetup}
                />
              </Details>
            </>
          )}

          {isLoading && (
            <LoaderContainer>
              <Spinner scale={30} />
            </LoaderContainer>
          )}

          {isAdminOrSuperuser && (
            <Bottom isMobile={isMobile}>
              <Button
                onClick={() => setShowDeleteAlertModal(true)}
                purpose="destructive"
                variant="outlined"
                type="button"
                disabled={isLoading}
                name={`Delete ${destination.name}`}
                location="Data Activations"
                slideSheet="Destination Details"
              >
                Delete {destination.name}
              </Button>
            </Bottom>
          )}
        </Container>
      </Sheet>

      {showDeleteAlertModal && (
        <ConfirmModal
          title={`Are you sure you want to delete ${destination.name}?`}
          subtitle={`This will permanently remove your connection to ${destination.name} and all associated syncs. This action cannot be undone.`}
          confirmText="Delete Destination"
          closeText="Cancel"
          onConfirm={handleDelete}
          onClose={() => setShowDeleteAlertModal(false)}
        />
      )}
    </>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  min-height: calc(100vh - 76px);
  overflow: auto;
  padding: ${p =>
    `24px ${p.isMobile ? 12 : 24}px 100px ${p.isMobile ? 12 : 24}px`};
  position: relative;

  & > div {
    margin-top: 16px;
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
`

const Property = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  margin-bottom: 6px;
`

const PropertyLabel = styled.span`
  font-weight: 600;
  margin-right: 8px;
`

const PropertyValue = styled.div``

const Link = styled.a<{ pink?: boolean }>`
  color: ${p => (p.pink ? p.theme.ContentAccentPrimary : 'inherit')};
`

const LoaderContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`

const Logo = styled.img<{ size?: number }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 4px;
`

const Bottom = styled.div<{ isMobile: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;

  button {
    ${p => (p.isMobile ? 'width: 100%;' : '')}
  }
`
