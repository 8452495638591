import { getNavLinks, NavLinks } from 'components/navigation/links'
import useFilteredNavLinks from './useFilteredNavLinks'
import { useMemo } from 'react'
import { Plan } from 'types'

/**
 * Hook to get the navigation links based on the current plan and production status.
 * It also filters the links based on the Split.io experiments being enabled/disabled.
 */
export const useNavigationLinks = (
  plan: Plan,
  isProduction: boolean
): NavLinks => {
  // Get all the navigation links
  const navLinks = getNavLinks(plan, isProduction)

  // Get the links to filter (based on experiments being enabled/disabled)
  const navLinksToFilter = useFilteredNavLinks()

  // Filter the links
  const filterLinks = (links: NavLinks): NavLinks => {
    // Clone navLinks to avoid direct mutation
    const clonedLinks = { ...links }

    navLinksToFilter.forEach(({ section, slug, childSlug }) => {
      if (!clonedLinks[section]) return

      if (childSlug) {
        clonedLinks[section] = clonedLinks[section].map(link => {
          if (link.slug === slug && link.subItems) {
            return {
              ...link,
              subItems: link.subItems.filter(
                subItem => subItem.slug !== childSlug
              ),
            }
          }
          return link
        })
      } else {
        clonedLinks[section] = clonedLinks[section].filter(
          link => link.slug !== slug
        )
      }
    })

    return clonedLinks
  }

  const filteredNavLinks = useMemo(
    () => filterLinks(navLinks),
    [navLinks, navLinksToFilter]
  )

  return {
    storeNavLinks: filteredNavLinks.storeNavLinks,
    dataNavLinks: filteredNavLinks.dataNavLinks,
    hubNavLinks: filteredNavLinks.hubNavLinks,
    hiddenLinks: filteredNavLinks.hiddenLinks,
  }
}
