import React, { useState, useEffect, createContext, ReactNode } from 'react'
import { EnvVarConfig } from 'utils/envVarConfig'
import Mixpanel from './mixpanel'
import { User } from 'types'
import { MixpanelEventData, MixpanelEventType } from './types'
import { useTenantsData } from 'redux/state/tenants'

const MIXPANEL_TOKEN = EnvVarConfig.REACT_APP_MIXPANEL_TOKEN
const IS_DEV_MODE = process.env.NODE_ENV === 'development'

type TrackEventFunction = <T extends MixpanelEventType>(
  eventName: T,
  eventData: MixpanelEventData[T]
) => void

interface MixpanelContextType {
  trackEvent: TrackEventFunction
  trackPageView: () => void
  identify: (user: User) => void
  resetUser: () => void
}

export const MixpanelContext = createContext<MixpanelContextType | null>(null)

interface MixpanelProviderProps {
  children: ReactNode
}

export const MixpanelProvider: React.FC<MixpanelProviderProps> = ({ children }) => {
  const [mixpanel, setMixpanel] = useState<Mixpanel | null>(null)
  const {
    state: { currentTenant },
  } = useTenantsData()
  const tenantName = currentTenant?.name

  useEffect(() => {
    const initializeMixpanel = async () => {
      if (!MIXPANEL_TOKEN) {
        // eslint-disable-next-line no-console
        console.warn(
          'Missing REACT_APP_MIXPANEL_TOKEN environment variable. Mixpanel will not be initialized.'
        )
        return
      }

      const mixpanelInstance = new Mixpanel()

      await mixpanelInstance.initialize(MIXPANEL_TOKEN, {
        debug: IS_DEV_MODE,
        persistence: 'localStorage',
      })

      setMixpanel(mixpanelInstance)
    }

    initializeMixpanel()
  }, [])

  const trackEvent = (eventName: MixpanelEventType, eventData?: Record<string, any>) => {
    const payload = tenantName ? { ...eventData, tenant: tenantName } : eventData
    mixpanel?.trackEvent(eventName, payload)
  }

  const trackPageView = () => {
    tenantName ? mixpanel?.trackPageView({ tenant: tenantName }) : mixpanel?.trackPageView()
  }

  const identify = (user: User) => {
    mixpanel?.identify(user)
  }

  const resetUser = () => {
    mixpanel?.resetUser()
  }

  const contextValue: MixpanelContextType = {
    trackEvent,
    trackPageView,
    identify,
    resetUser,
  }

  return (
    <MixpanelContext.Provider value={contextValue}>
      {children}
    </MixpanelContext.Provider>
  )
}
