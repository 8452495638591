import React from 'react'
import { Card } from '../common/Card'
import { CardContent, Header, TextContainer } from 'components/analytics/reports/common'
import { ServiceConfig } from 'types/cdp'
import { H4 } from '@chordco/component-library'
import { Api } from '@mui/icons-material'
import { blue } from '@mui/material/colors'

type ConnectorCardProps = {
  connector: ServiceConfig
  selected?: boolean
  onSelectConnector: (connector: ServiceConfig) => void
}

export const ConnectorCard: React.FC<ConnectorCardProps> = ({
  connector,
  selected,
  onSelectConnector,
}) => {
  return (
    <Card onClick={() => onSelectConnector(connector)} forceHover={selected}>
      <CardContent>
        <Header>
          <Api sx={{ width: 36, height: 36, color: blue[500] }} />
          <TextContainer>
            <H4>{connector.name}</H4>
          </TextContainer>
        </Header>
      </CardContent>
    </Card>
  )
}
