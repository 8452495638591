import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Sheet, H1 } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { ConnectCard, FivetranConnector } from 'api/fivetran/interfaces'
import { useFivetran } from '../FivetranContext'
import useFivetranConnectorConfig from 'hooks/useFivetranConnectorConfig'
import useFivetranSlackEvent from 'hooks/useFivetranSlackEvent'
import { useTenantsData } from 'redux/state/tenants'
import { useAuthData } from 'redux/state/auth'
import { ModeledChip } from '../components/ModeledChip'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

type Props = {
  source: FivetranConnector
  modeled: boolean
  onClose: () => void
  onConnectSuccess: (connectCard: ConnectCard) => void
  onConnectError: (message: string) => void
}

export const ConnectSourceSheet: React.FC<Props> = ({
  source,
  modeled,
  onClose,
  onConnectSuccess,
  onConnectError,
}) => {
  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')

  const {
    state: { currentTenant },
  } = useTenantsData()
  const { trackEvent } = useMixpanel()

  if (!currentTenant) throw new Error('Current Tenant not found!')

  const { fivetranClient, destinationGroupId } = useFivetran()
  const isMobile = useIsMobile()
  const size = isMobile ? 48 : 64

  const { config } = useFivetranConnectorConfig(source)
  const { sendSlackEvent } = useFivetranSlackEvent()

  const [isLoading, setIsLoading] = useState(false)

  const onLearnMoreClick = () => {
    trackEvent(MixpanelEventType.LinkClicked, {
      'Link Name': 'Learn More',
      'Link URL': source.linkToDocs,
      'Page Name': 'Data Sources',
      'Slidesheet Name': 'Add Data Sources',
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      const response = await fivetranClient?.createConnector({
        connectorId: source.id,
        groupId: destinationGroupId,
        config,
      })

      if (response?.code === 'Success' && response?.data.connectCard) {
        await sendSlackEvent('CONNECTOR_CREATED', {
          tenantName: currentTenant.name,
          connectorName: source.name,
          destinationGroupId,
          hubUser: user,
        })

        onConnectSuccess(response.data.connectCard)
      } else {
        throw new Error(
          response?.message ??
            'An error occurred while creating your data source. Please try again.'
        )
      }
    } catch (error: any) {
      onConnectError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Sheet
      title={source.name}
      onClose={onClose}
      isLoading={isLoading}
      isLarge
      location="Data Sources"
      name={source.name}
    >
      <Container isMobile={isMobile}>
        <Logo src={source.iconUrl} alt={source.name} size={size} />
        <TitleContainer>
          <H1>{source.name}</H1>
          <ModeledChip modeled={modeled} />
        </TitleContainer>
        <DescriptionContainer>{source.description}</DescriptionContainer>
        <Link
          onClick={onLearnMoreClick}
          href={source.linkToDocs}
          target="_blank"
        >
          Learn more about {source.name}
        </Link>
        <Form noValidate>
          <Button
            onClick={handleSubmit}
            purpose="primary"
            type="submit"
            isLoading={isLoading}
            name={`Add ${source.name}`}
            location="Data Sources"
          >
            Add {source.name}
          </Button>
        </Form>
      </Container>
    </Sheet>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  min-height: calc(100vh - 76px);
  overflow: auto;
  padding: ${p =>
    `24px ${p.isMobile ? 12 : 24}px 100px ${p.isMobile ? 12 : 24}px`};
  position: relative;

  & > div {
    margin-top: 16px;
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const Link = styled.a<{ pink?: boolean }>`
  color: ${p => (p.pink ? p.theme.ContentAccentPrimary : 'inherit')};
`

const Logo = styled.img<{ size?: number }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 4px;
`

const Form = styled.form`
  padding-top: 24px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

const DescriptionContainer = styled.p`
  font-size: 14px;
`
