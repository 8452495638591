import React, { useMemo } from 'react'
import { Box, Table, TableBody, TableCell, TableRow, Chip } from '@mui/material'
import { Sheet } from '@chordco/component-library'
import { ConfigurationObjectLink, DestinationConfig } from 'types/cdp'
import { JSONViewer } from 'components/chord-cdp/common/JSONViewer'
import { ParsedDestinationEventRow } from '.'

type DestinationEventDrawerProps = {
  event: ParsedDestinationEventRow
  mappedDestinations?: ConfigurationObjectLink[]
  destinations?: DestinationConfig[]
  onClose: () => void
}

export const DestinationEventDrawer: React.FC<DestinationEventDrawerProps> = ({
  event,
  mappedDestinations,
  destinations,
  onClose,
}) => {
  const drawerData = useMemo(() => {
    const rows: { name: React.ReactNode; value: React.ReactNode }[] = []

    if (event) {
      rows.push({ name: 'Date (UTC)', value: event.date })
      rows.push({ name: 'Level', value: event.level })
      rows.push({ name: 'Batch Size', value: event.batchSize })

      // Status with color-coded chips
      rows.push({
        name: 'Status',
        value: (
          <Chip
            color={event.status === 'COMPLETED' ? 'success' : 'error'}
            label={event.status}
            size="small"
          />
        ),
      })

      // Event Payload
      if (event.rawEvent) {
        rows.push({
          name: 'Raw Event',
          value: <JSONViewer data={event.rawEvent} />,
        })
      }
    }

    return rows
  }, [event, mappedDestinations, destinations])

  return (
    <Sheet
      onClose={onClose}
      title="Event Details"
      isLarge={false}
      customWidth="70%"
      location="CDP Incoming Events Details"
      name="CDP Event Details"
    >
      <Box sx={{ padding: 2 }}>
        <Table>
          <TableBody>
            {drawerData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', verticalAlign: 'top' }}>
                  {row.name}
                </TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Sheet>
  )
}
