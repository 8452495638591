import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'
import { useGoogleSheets } from './SheetsContext'

const { Download } = standardIcons

type Props = {
  slug: string
  onClose: () => void
}

export const DownloadSheetModal: React.FC<Props> = ({ onClose, slug }) => {
  const { googleSheetsClient } = useGoogleSheets()
  const [downloadingSheet, setDownloadingSheet] = React.useState(false)

  async function download() {
    if (downloadingSheet) return

    setDownloadingSheet(true)
    const response = (await googleSheetsClient?.downloadSheet(slug)) as Blob
    const blob = new Blob([response], { type: 'text/csv' })
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = `${slug}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(blobUrl)
    setDownloadingSheet(false)
    onClose()
  }

  return (
    <AlertModal
      onClose={onClose}
      icon={Download}
      title="Download CSV"
      content="The downloaded CSV will display a new column called 'Key'. Note that these values are used for internal purposes. Please do not modify or change any values within this column in the CSV."
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Download',
          onClick: download,
          purpose: 'primary',
          disabled: downloadingSheet,
        },
      ]}
    ></AlertModal>
  )
}
