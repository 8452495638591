import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { NavSection } from '@chordco/component-library'
import { useEnvironmentsData } from 'redux/state/environments'
import { useTenantsData } from 'redux/state/tenants'
import useUserRole from 'redux/hooks/useUserRole'
import { useNavigationLinks } from 'redux/hooks/useNavigationLinks'
import useNavSelection from './useNavSelection'

interface Props {
  isMobile?: boolean
  closeNav?: Function
}

export const Content: React.FC<Props> = ({ isMobile = false, closeNav }) => {
  const [selectedNavId, setSelectedNavId] = useState<number | undefined>(
    undefined
  )
  const history = useHistory()

  const [isTop, setIsTop] = useState(true)
  const contentRef = useRef() as any

  const role = useUserRole()
  const isOmsAdmin = role === 'oms_admin'

  const {
    state: { currentTenant, currentStore, currentStoreIsAutonomy },
  } = useTenantsData()

  if (!currentTenant || !currentStore) {
    throw new Error('currentTenant or currentStore is not defined')
  }

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  const isProduction = selectedEnvironment === 'production'

  const { storeNavLinks, dataNavLinks, hubNavLinks, hiddenLinks } =
    useNavigationLinks(currentStore.plan, isProduction)

  // This hook is used to determine which navigation links should be highlighted
  useNavSelection(
    storeNavLinks,
    dataNavLinks,
    hubNavLinks,
    hiddenLinks,
    setSelectedNavId,
    currentStoreIsAutonomy,
    isProduction
  )

  const navigate = (location: string) => {
    if (location.includes('http')) {
      open(location)
    } else {
      history.push(location)
    }

    closeNav && closeNav()
  }

  if (!storeNavLinks.length && !dataNavLinks.length && !hubNavLinks.length) {
    return null
  }

  return (
    <Container
      isMobile={isMobile}
      data-testid="navigation"
      ref={contentRef}
      onScroll={() => setIsTop(contentRef?.current?.scrollTop === 0)}
      isTop={isTop}
    >
      {currentStoreIsAutonomy ? (
        <>
          <NavSection
            items={storeNavLinks}
            selectedId={selectedNavId}
            navigate={navigate}
            text="Store"
          />
          {!isOmsAdmin && (
            <NavSection
              items={dataNavLinks}
              selectedId={selectedNavId}
              navigate={navigate}
              text="Data"
            />
          )}
          <NavSection
            items={hubNavLinks}
            selectedId={selectedNavId}
            navigate={navigate}
            text="Hub"
          />
        </>
      ) : (
        <NavSection
          items={[...storeNavLinks, ...dataNavLinks, ...hubNavLinks]}
          selectedId={selectedNavId}
          navigate={navigate}
        />
      )}
    </Container>
  )
}

const Container = styled.div<{ isMobile: boolean; isTop: boolean }>`
  position: relative;
  box-sizing: border-box;
  padding: 0 0 32px;

  > div:first-child {
    padding-top: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none;
`
