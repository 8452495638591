import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { H1, H2, Spinner, TabBar } from '@chordco/component-library'
import { Explore } from 'components/looker/Explore'
import { useIsMobile, useAnalyticsTabsHelper } from 'hooks'
import { useLookerData } from 'redux/state/looker'
import { Hr } from '../common'
import { BrowserWarning } from './BrowserWarning'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import { MyReports } from 'components/looker/MyReports'
import { useIsLoading } from 'redux/utils'
import useAnalyticsActiveTab from 'hooks/useAnalyticsActiveTab'
import { PinnedReports } from './reports/PinnedReports'
import { getReportTabs } from './tabs'
import LookerStatus, { LookerStatusType } from './LookerStatus'
import { Quickstarts } from './reports/Quickstarts'
import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'
import { SharedReports } from 'components/looker/SharedReports'
import { AvailableUserReport } from 'types'

export interface AnalyticsFeatureFlags {
  showLookerUnderReview: boolean
  showLookerIsSample: boolean
  showLookerUnconfigured: boolean
}

interface AnalyticsProps {
  featureFlags: AnalyticsFeatureFlags
}

export const Analytics: React.FC<AnalyticsProps> = ({ featureFlags }) => {
  const { showLookerUnderReview, showLookerIsSample, showLookerUnconfigured } = featureFlags

  const { addNotification } = useNotifications()

  const history = useHistory()
  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const {
    getAvailableReports,
    getAvailableUserReports,
    getAvailableQuickstarts,
    getSharedDashboards,
    getSharedLooks,
    state: { availableReports, availableUserReports, availableQuickstarts, availableSharedReports },
  } = useLookerData()

  const tabs = useMemo(() => getReportTabs(), [])

  const [selectedTabId, setSelectedTabId] = useAnalyticsTabsHelper(tabs)

  const { isMyReports, isPinnedReports, isExplores, isQuickstarts, isSharedReports } =
    useAnalyticsActiveTab()

  const isLoading = useIsLoading(
    'getAvailableReports',
    'getAvailableUserReports',
    'getAvailableQuickstarts',
    'getSharedDashboards',
    'getSharedLooks'
  )

  const refreshMyFolderAndRedirect = async () => {
    if (getAvailableUserReports) {
      await getAvailableUserReports()
      history.push('/analytics/my-reports')
    }
  }

  const notifyMyFolderEvent = (message: string) => {
    const id = generateUUID() as string
    addNotification({
      id,
      type: 'success',
      message,
    })
  }

  const handleSavedLook = async (report: AvailableUserReport) => {
    notifyMyFolderEvent('Look saved successfully')

    // There is an intermittent issue with the Looker SDK where the save look request
    // (initiated from the iframe) remained 'pending' if we immediately refresh the
    // user reports. I'm not sure why this is happening, but adding a minor delay seems
    // to fix the issue.
    await new Promise(resolve => setTimeout(resolve, 1000))

    if (getAvailableUserReports) {
      await getAvailableUserReports()
      history.push(`/analytics/my-reports/${report.slug}`)
    }
  }

  const handleDeletedLook = async () => {
    notifyMyFolderEvent('Look deleted successfully')

    // There is an intermittent issue with the Looker SDK where the delete look request
    // (initiated from the iframe) remained 'pending' if we immediately refresh the
    // user reports. I'm not sure why this is happening, but adding a minor delay seems
    // to fix the issue.
    await new Promise(resolve => setTimeout(resolve, 1000))

    await refreshMyFolderAndRedirect()
  }

  const handleDeletedDashboard = async () => {
    notifyMyFolderEvent('Dashboard deleted successfully')
    await refreshMyFolderAndRedirect()
  }

  const handlePublishedReport = async (report: AvailableUserReport) => {
    if (getSharedDashboards && getSharedLooks) {
      await getSharedDashboards()
      await getSharedLooks()
      history.push(`/analytics/shared-reports/${report.slug}`)
    }
  }

  const handleRefreshQuickstarts = async () => {
    if (getAvailableQuickstarts) {
      getAvailableQuickstarts(true)
    }
  }

  const handleSavedSharedLook = async (report: AvailableUserReport) => {
    notifyMyFolderEvent('Shared Look saved successfully')
    if (getSharedLooks) {
      getSharedLooks().then(() => {
        history.push(`/analytics/shared-reports/${report.slug}`)
      })
    }
  }

  const handleDeletedSharedLook = async () => {
    notifyMyFolderEvent('Shared Look deleted successfully')
    if (getSharedLooks) {
      getSharedLooks().then(() => {
        history.push('/analytics/shared-reports')
      })
    }
  }

  const handleDeletedSharedDashboard = async () => {
    notifyMyFolderEvent('Shared Dashboard deleted successfully')
    if (getSharedDashboards) {
      getSharedDashboards().then(() => {
        history.push('/analytics/shared-reports')
      })
    }
  }

  const fetchSharedReports = async () => {
    if (getSharedDashboards && getSharedLooks) {
      await getSharedDashboards()
      await getSharedLooks()
    }
  }

  const fetchPinnedReports = async () => {
    if (getAvailableReports) {
      await getAvailableReports()
    }
  }

  const fetchQuickstarts = async () => {
    if (getAvailableQuickstarts) {
      await getAvailableQuickstarts()
    }
  }

  const fetchMyReports = async () => {
    if (getAvailableUserReports) {
      await getAvailableUserReports()
    }
  }

  useEffect(() => {
    const fetchReports = async () => {
      // Ensure those pages have the pinned reports ready. This is a common use case
      // to ensure that we can display the "pinned" status of reports on those pages.
      if (isPinnedReports || isExplores || isSharedReports || isQuickstarts) {
        await fetchPinnedReports()
      }

      if (isMyReports) await fetchMyReports()
      if (isSharedReports) await fetchSharedReports()
      if (isQuickstarts) await fetchQuickstarts()
    }

    fetchReports()
  }, [isPinnedReports, isExplores, isMyReports, isQuickstarts, isSharedReports])

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <Wrapper navOpen={navOpen}>
          <Header>
            <Heading>Analytics</Heading>
            {selectedTabId && (
              <TabBar
                tabs={tabs}
                selectedId={selectedTabId}
                setSelectedId={setSelectedTabId}
                resetOnInvalidSelection
              />
            )}
            <Hr isMobile={isMobile} />

            <BrowserWarning />
          </Header>

          {showLookerUnconfigured && <LookerStatus status={LookerStatusType.Unconfigured} />}

          {showLookerUnderReview && <LookerStatus status={LookerStatusType.UnderReview} />}

          {showLookerIsSample && <LookerStatus status={LookerStatusType.IsSample} />}

          {!showLookerUnconfigured && !showLookerUnderReview && (
            <>
              {isLoading && <Spinner scale={30} />}

              {!isLoading && (
                <EmbedContainer>
                  {isExplores && <Explore options={availableReports.explores} />}

                  {isPinnedReports && (
                    <PinnedReports
                      reports={[...availableReports.dashboards, ...availableReports.looks]}
                      onUnpinReport={fetchPinnedReports}
                    />
                  )}

                  {isQuickstarts && (
                    <Quickstarts
                      dashboards={availableQuickstarts.dashboards}
                      looks={availableQuickstarts.looks}
                      onRefresh={handleRefreshQuickstarts}
                    />
                  )}

                  {isMyReports && (
                    <MyReports
                      dashboards={availableUserReports.userDashboards}
                      looks={availableUserReports.userLooks}
                      onDeletedDashboard={handleDeletedDashboard}
                      onDeletedLook={handleDeletedLook}
                      onSavedLook={handleSavedLook}
                      onPublishedReport={handlePublishedReport}
                    />
                  )}

                  {isSharedReports && (
                    <SharedReports
                      sharedReports={[
                        ...availableSharedReports.dashboards,
                        ...availableSharedReports.looks,
                      ]}
                      onDeletedDashboard={handleDeletedSharedDashboard}
                      onDeletedLook={handleDeletedSharedLook}
                      onSavedLook={handleSavedSharedLook}
                    />
                  )}
                </EmbedContainer>
              )}
            </>
          )}
        </Wrapper>
      )}
    </NavigationContext.Consumer>
  )
}

const Wrapper = styled.div<{ navOpen: boolean }>`
  color: ${p => p.theme.ContentPrimary};
  height: 100vh;
  width: ${p => (p.navOpen ? 'calc(100vw - 280px)' : '100vw')};
  position: fixed;
  top: 0;
  right: 0;

  // Ensure the content is scrollable due to the fixed position
  overflow-y: scroll;
  overflow-x: hidden;
`

const Header = styled.div`
  padding: 24px 32px 0px 64px;
`

const EmbedContainer = styled.div`
  width: 100%;
  height: 100%;
`
