import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Button, standardIcons } from '@chordco/component-library'
import { UserDashboardEmbed } from './UserDashboardEmbed'
import { UserLookEmbed } from './UserLookEmbed'
import { CreateDashboardModal } from './CreateDashboardModal'
import { AvailableUserReport, LookerReportType } from 'types'
import { ImportReportModal } from './ImportReportModal'
import { PublishReportModal } from './PublishReportModal'
import { PublishConfirmationModal } from './PublishConfirmationModal'
import { SelectChangeEvent } from '@mui/material'
import { ReportFolderDropdown } from './ReportFolderDropdown'
import { AddNewReportButton } from './AddNewReportButton'
import {
  QuickstartCategory,
  QuickstartType,
  useQuickstartsFilter,
} from 'redux/state/quickstartsFilter'
import { CreateLookModal } from './CreateLookModal'
import { LookEvent } from '@looker/embed-sdk'
import { MyEmptyReports } from './MyEmptyReports'

type MyReportsProps = {
  dashboards: AvailableUserReport[]
  looks: AvailableUserReport[]
  onDeletedDashboard: () => void
  onDeletedLook: (event: LookEvent) => void
  onSavedLook: (report: AvailableUserReport) => void
  onPublishedReport: (report: AvailableUserReport) => void
}

export const MyReports: React.FC<MyReportsProps> = ({
  dashboards,
  looks,
  onDeletedDashboard,
  onDeletedLook,
  onSavedLook,
  onPublishedReport,
}) => {
  if (!dashboards.length && !looks.length) return <MyEmptyReports />

  const history = useHistory()
  const slug = history.location.pathname.split('/').pop()

  const selectedOption =
    dashboards.find(d => d.slug === slug) || looks.find(l => l.slug === slug) || dashboards[0] || looks[0]

  if (!selectedOption) return null

  const [showCreateLookModal, setShowCreateLookModal] = useState(false)
  const [showCreateDashboard, setShowCreateDashboard] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showPublishReportModal, setShowPublishReportModal] = useState(false)
  const [showNameAlreadyTaken, setShowNameAlreadyTaken] = useState<
    | {
        name: string
        description: string
        slug: string
        type: LookerReportType
      }
    | undefined
  >(undefined)

  const { setFilters } = useQuickstartsFilter()

  const redirectToReport = (report: AvailableUserReport) => {
    history.push(`/analytics/my-reports/${report.slug}`)
  }

  const handleSelectChanged = (event: SelectChangeEvent<string>) => {
    const report = [...dashboards, ...looks].find(d => d.id === event.target.value)
    if (report) redirectToReport(report)
  }

  const handleCreateLook = () => {
    // There is no way to create a completely "blank" look without defining some query upfront.
    // For that reason, we are redirecting users to our Quickstarts page to create a new look from
    // an existing template.
    setFilters({
      selectedType: QuickstartType.LOOKS,
      selectedCategory: QuickstartCategory.ALL_CATEGORIES,
      searchTerm: '',
    })

    history.push('/analytics/quickstarts')
  }

  const handleImport = () => {
    setShowImportModal(true)
  }

  const handleNameAlreadyTaken = (data: {
    name: string
    description: string
    slug: string
    type: LookerReportType
  }) => {
    setShowNameAlreadyTaken(data)
  }

  const handleCreateDashboard = (dashboard?: AvailableUserReport) => {
    setShowCreateDashboard(false)
    if (dashboard) redirectToReport(dashboard)
  }

  const handleImportedReport = (report?: AvailableUserReport) => {
    setShowImportModal(false)
    if (report) redirectToReport(report)
  }

  const handleSavedLook = async () => {
    onSavedLook(selectedOption)
  }

  return (
    <Wrapper>
      <Top>
        <ReportFolderDropdown
          title="My Reports"
          dashboards={dashboards}
          looks={looks}
          onChange={handleSelectChanged}
          defaultValue={selectedOption.id}
        />

        <RightButtons>
          <Button purpose="tertiary" icon={standardIcons.Import} onClick={handleImport}>
            Import
          </Button>

          <Button purpose="tertiary" icon={standardIcons.Publish} onClick={() => setShowPublishReportModal(true)}>
            Share
          </Button>

          <AddNewReportButton
            onCreateNewDashboard={() => setShowCreateDashboard(true)}
            onCreateNewLook={() => setShowCreateLookModal(true)}
          />
        </RightButtons>
      </Top>
      <EmbedContainer>
        {selectedOption.metricType === 'dashboard' && (
          <UserDashboardEmbed dashboardId={selectedOption.slug} onDeletedDashboard={onDeletedDashboard} />
        )}

        {selectedOption.metricType === 'look' && (
          <UserLookEmbed lookId={selectedOption.slug} onDeletedLook={onDeletedLook} onSavedLook={handleSavedLook} />
        )}
      </EmbedContainer>

      {showCreateDashboard && (
        <CreateDashboardModal onClose={() => setShowCreateDashboard(false)} onCreateDashboard={handleCreateDashboard} />
      )}

      {showCreateLookModal && (
        <CreateLookModal onClose={() => setShowCreateLookModal(false)} onCreateLook={handleCreateLook} />
      )}

      {showImportModal && selectedOption.folderId && (
        <ImportReportModal
          folderId={selectedOption.folderId}
          onClose={() => setShowImportModal(false)}
          onImported={handleImportedReport}
        />
      )}

      {showPublishReportModal && (
        <PublishReportModal
          reportName={selectedOption.label}
          reportDescription={selectedOption.description}
          reportSlug={selectedOption.slug}
          reportType={selectedOption.metricType}
          onClose={() => setShowPublishReportModal(false)}
          onPublish={onPublishedReport}
          onNameAlreadyTaken={handleNameAlreadyTaken}
        />
      )}

      {showNameAlreadyTaken && (
        <PublishConfirmationModal
          name={showNameAlreadyTaken.name}
          description={showNameAlreadyTaken.description}
          slug={showNameAlreadyTaken.slug}
          type={showNameAlreadyTaken.type}
          onCancel={() => setShowNameAlreadyTaken(undefined)}
          onPublish={onPublishedReport}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 64px 0 64px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const RightButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const EmbedContainer = styled.div`
  border-radius: 0 0 0 ${p => p.theme.PageRadius};
  border-top: 1px solid ${p => p.theme.BorderOptimal};
  height: 100%;
`
