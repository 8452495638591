import React, { useState } from 'react'
import {
  IconContainer,
  Trailing,
  Text,
  SpinnerContainer,
  ButtonBase,
} from './styles/Button'
import { Spinner, Tooltip, TooltipDirection } from '../information'
import { useTheme, Palette } from '@chordco/component-library'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

export type Size = 'tiny' | 'small' | 'medium' | 'large'

type PurposeV1 =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'darkGhost'
  | 'lightGhost'
  | 'destructive'

type PurposeV2 =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'ghost-primary'
  | 'ghost-secondary'
  | 'delete-primary'
  | 'delete-secondary'

export type Purpose = PurposeV1 | PurposeV2
export type Variant = 'solid' | 'tertiary' | 'outlined' | 'ghost'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<Omit<HTMLButtonElement, 'color'>> {
  size?: Size
  purpose?: Purpose
  trailingIcon?: any
  flipTrailingIcon?: boolean
  icon?: any
  tooltip?: string
  tooltipDirection?: TooltipDirection
  tooltipWidth?: string
  selected?: boolean
  isLoading?: boolean
  variant?: Variant
  color?: Palette
  tabbed?: boolean
  centreContent?: boolean
  name?: string
  location?: string
  slideSheet?: string
}

const purposeMap: Record<Purpose, { color: Palette; variant: Variant }> = {
  primary: {
    color: 'primary',
    variant: 'solid',
  },
  secondary: {
    color: 'primary',
    variant: 'outlined',
  },
  tertiary: {
    color: 'neutral',
    variant: 'tertiary',
  },
  'ghost-primary': {
    color: 'primary',
    variant: 'ghost',
  },
  'ghost-secondary': {
    color: 'neutral',
    variant: 'ghost',
  },
  'delete-primary': {
    color: 'error',
    variant: 'solid',
  },
  'delete-secondary': {
    color: 'error',
    variant: 'outlined',
  },
  // v1 purposes
  ghost: {
    color: 'primary',
    variant: 'ghost',
  },
  lightGhost: {
    color: 'neutral',
    variant: 'ghost',
  },
  darkGhost: {
    color: 'neutral',
    variant: 'ghost',
  },
  destructive: {
    color: 'error',
    variant: 'solid',
  },
}

// @TODO - remove this once all buttons are updated to use v2 purposes
// const buttons = {
//   primary: {
//     component: PrimaryButton,
//     light: true,
//   },
//   secondary: {
//     component: SecondaryButton,
//     light: false,
//   },
//   ghost: {
//     component: GhostButton,
//     light: false,
//   },
//   darkGhost: {
//     component: DarkGhostButton,
//     light: false,
//   },
//   lightGhost: {
//     component: LightGhostButton,
//     light: false,
//   },
//   destructive: {
//     component: DestructiveButton,
//     light: false,
//   },
// }

const iconSizes = {
  tiny: 12,
  small: 16,
  medium: 16,
  large: 20,
}

export const Button: React.FC<ButtonProps> = ({
  purpose = 'primary',
  size = 'medium',
  trailingIcon: TrailingIcon,
  flipTrailingIcon = false,
  icon: Icon,
  tooltip,
  tooltipDirection,
  tooltipWidth,
  selected,
  isLoading,
  centreContent = false,
  type = 'button',
  name,
  location,
  slideSheet,
  ...props
}) => {
  const { trackEvent } = useMixpanel()

  const theme = useTheme()
  const [tabbed, setTabbed] = useState(false)
  const { children } = props // do we need to do this? If so, should probably use a comment
  const disabled = props.disabled || isLoading
  // const ButtonComponent = buttons[purpose].component
  // const iconFill = buttons[purpose].light ? theme.WHITE : theme.GREY_80
  const hasIcon = !!(TrailingIcon && !disabled) || !!Icon
  const hasText = !!children
  const isRight = !!(TrailingIcon && !disabled)
  const purposeTheme = purposeMap[purpose]
  const { color = purposeTheme.color, variant = purposeTheme.variant } = props
  let iconFill = theme.ComponentButtonPrimaryContent

  switch (variant) {
    case 'solid':
      iconFill = theme.ComponentButtonPrimaryContent
      break
    case 'outlined':
      iconFill = theme.ComponentButtonSecondaryContent
      break
    case 'ghost':
      iconFill = theme.ComponentButtonGhostContentPrimary
      break
  }

  const onClick = (e: any) => {
    if (name && location) {
      trackEvent(MixpanelEventType.ButtonClicked, {
        'Button Name': name,
        'Button Purpose': purpose,
        'Page Name': location,
        'Slidesheet Name': slideSheet,
      })
    }

    setTabbed(false)
    props.onClick && props.onClick(e)
  }

  const onKeyDown = () => {
    setTabbed(true)
  }

  return (
    <ToolTipWrapper
      disabled={disabled}
      tooltip={tooltip}
      tooltipDirection={tooltipDirection}
      tooltipWidth={tooltipWidth}
    >
      <ButtonBase
        {...props}
        type={type}
        size={size}
        purpose={purpose}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabbed={tabbed}
        selected={selected}
        isLoading={isLoading}
        disabled={disabled}
        color={color}
        variant={variant}
      >
        {Icon && !isLoading && (
          <IconContainer size={size}>
            <Icon scale={iconSizes[size]} fill={iconFill} />
          </IconContainer>
        )}
        {isLoading && (
          <SpinnerContainer size={size}>
            <Spinner scale={iconSizes[size]} fill={iconFill} />
          </SpinnerContainer>
        )}
        <Text
          hasText={hasText}
          size={size}
          hasIcon={hasIcon}
          isRight={isRight}
          centreContent={centreContent}
        >
          {children}
        </Text>
        {TrailingIcon && !disabled && (
          <Trailing
            size={size}
            scale={iconSizes[size]}
            flipTrailingIcon={flipTrailingIcon}
          >
            <TrailingIcon scale={iconSizes[size]} />
          </Trailing>
        )}
      </ButtonBase>
    </ToolTipWrapper>
  )
}

const ToolTipWrapper: React.FC<
  React.PropsWithChildren<
    Pick<
      ButtonProps,
      'disabled' | 'tooltip' | 'tooltipDirection' | 'tooltipWidth'
    >
  >
> = ({ children, tooltip, tooltipDirection, tooltipWidth }) =>
  tooltip ? (
    <Tooltip
      text={tooltip}
      direction={tooltipDirection}
      customWidth={tooltipWidth}
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  )
