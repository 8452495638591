import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import {
  LookerReportType,
  UnpinLookResponse,
  UnpinDashboardResponse,
} from 'types'
import { PayloadAction } from '@reduxjs/toolkit'

interface UnpinReportData {
  slug: string
  reportType: LookerReportType
}

export const useUnpinReport = () => {
  const { unpinDashboard, unpinLook } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const unpin = async ({ slug, reportType }: UnpinReportData) => {
    if (!unpinDashboard || !unpinLook) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      if (reportType === 'look') {
        const response = (await unpinLook(slug)) as PayloadAction<{
          data: UnpinLookResponse
        }>

        if (response.payload.data?.look) {
          setHasSucceeded(true)
        } else {
          setHasFailed(true)
        }
      } else if (reportType === 'lookml_dashboard' || reportType === 'dashboard') {
        const response = (await unpinDashboard(slug)) as PayloadAction<{
          data: UnpinDashboardResponse
        }>

        if (response.payload.data?.dashboard) {
          setHasSucceeded(true)
        } else {
          setHasFailed(true)
        }
      } else {
        throw new Error('Invalid report type')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { unpin, isLoading, hasSucceeded, hasFailed }
}
