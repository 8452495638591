import { createSlice } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import { useStateSliceAndDispatch } from 'redux/utils'
import { ChordConfigSheet, ChordDataConnector } from 'types'

const SLICE_NAME = 'provisioning'

export interface ProvisioningInitialState {
  dataConnectors: ChordDataConnector[]
  configSheets: ChordConfigSheet[]
}

const initialState: ProvisioningInitialState = {
  dataConnectors: [],
  configSheets: [],
}

const { getDataConnectors, getConfigSheets } = reduxApiClient

const provisioningSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDataConnectors.fulfilled, (state, action) => {
      state.dataConnectors = action.payload.data
    })

    builder.addCase(getConfigSheets.fulfilled, (state, action) => {
      state.configSheets = action.payload.data
    })
  },
})

export default provisioningSlice.reducer

export const useProvisioningData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    getDataConnectors: () => dispatch(getDataConnectors()),
    getConfigSheets: () => dispatch(getConfigSheets()),
  }
}
