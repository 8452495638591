import React from 'react'
import { Card } from '../common/Card'
import { CardContent, Header, TextContainer } from 'components/analytics/reports/common'
import { StreamConfig } from 'types/cdp'
import { H4 } from '@chordco/component-library'
import { Language } from '@mui/icons-material'
import { blue } from '@mui/material/colors'

type StreamCardProps = {
  stream: StreamConfig
  selected?: boolean
  onSelectStream: (stream: StreamConfig) => void
}

export const StreamCard: React.FC<StreamCardProps> = ({ stream, selected, onSelectStream }) => {
  return (
    <Card onClick={() => onSelectStream(stream)} forceHover={selected}>
      <CardContent>
        <Header>
          <Language sx={{ width: 36, height: 36, color: blue[500] }} />
          <TextContainer>
            <H4>{stream.name}</H4>
          </TextContainer>
        </Header>
      </CardContent>
    </Card>
  )
}
