import axios, { AxiosInstance, AxiosRequestConfig as BaseAxiosRequestConfig } from 'axios'
import humps from 'humps'
import { EnvVarConfig } from 'utils/envVarConfig'

export interface AxiosRequestConfig extends BaseAxiosRequestConfig {
  skipCamelize?: boolean
}

const hubClient: AxiosInstance = axios.create({
  baseURL: EnvVarConfig.REACT_APP_CHORD_HUB_API,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
})

hubClient.interceptors.response.use(
  response => {
    const config = response.config as AxiosRequestConfig

    // Check if the request configuration has `skipCamelize`. If it does, return the raw response
    // data instead (useful for some Jitsu CDP endpoints)
    if (config.skipCamelize) return response.data

    return humps.camelizeKeys(response.data)
  },
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('session_token')
      localStorage.removeItem('b2b')

      window.location.replace('/login')
      return // hide notifications for 401 responses
    }

    return Promise.reject(error)
  }
)

hubClient.interceptors.request.use(
  request => {
    if (
      request.headers &&
      request.headers['Content-Type'] !== 'multipart/form-data'
    ) {
      request.data = humps.decamelizeKeys(request.data)
    }

    const token = localStorage.getItem('token')
    if (token) {
      request.headers = {
        ...request.headers,
        authorization: `Bearer ${token}`,
      }
    }

    request.params = humps.decamelizeKeys(request.params)

    return request
  },
  error => Promise.reject(error)
)

export { hubClient }
