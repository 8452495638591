import styled from 'styled-components'

const EmbedContainer = styled.div`
  width: 100%;
  height: 100%;

  > iframe {
    display: block;
    width: 100%;
    height: 100% !important;
    border: none;
  }
`

export { EmbedContainer }
