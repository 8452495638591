import { PayloadAction } from '@reduxjs/toolkit'
import { useState } from 'react'
import { useLookerData } from 'redux/state/looker'
import { AvailableUserAudience, CopyAudienceResponse } from 'types'

interface CopyAudienceData {
  audienceId: string
  audienceTitle: string
  audienceDescription: string
  folderId: string
}

export const useCopyAudience = (
  onCopied: (audience?: AvailableUserAudience) => void
) => {
  const { copyAudience } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const copy = async ({
    audienceId,
    audienceTitle,
    audienceDescription,
    folderId,
  }: CopyAudienceData) => {
    if (!copyAudience) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await copyAudience(
        audienceId,
        audienceTitle,
        audienceDescription,
        folderId
      )) as PayloadAction<{ data: CopyAudienceResponse }>

      if (response.payload.data?.audience) {
        setHasSucceeded(true)
        onCopied(response.payload.data.audience)
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { copy, isLoading, hasSucceeded, hasFailed }
}
