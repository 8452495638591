import { MetricType } from './store'
import { DateDropdownPeriod } from '@chordco/component-library'

export type APIResponse<T, K extends string> = {
  status?: string
  error?: string
} & {
  [P in K]?: T
}

export type CreateDashboardResponse = APIResponse<AvailableUserReport, 'dashboard'>
export type CopyDashboardResponse = APIResponse<AvailableUserReport, 'dashboard'>
export type PublishDashboardResponse = APIResponse<AvailableUserReport, 'dashboard'>
export type PinDashboardResponse = APIResponse<AvailableUserReport, 'dashboard'>
export type UnpinDashboardResponse = APIResponse<AvailableUserReport, 'dashboard'>
export type ImportDashboardResponse = APIResponse<AvailableUserReport, 'dashboard'>
export type SharedDashboardsResponse = APIResponse<AvailableUserReport[], 'dashboards'>
export type CreateLookResponse = APIResponse<AvailableUserReport, 'look'>
export type CopyLookResponse = APIResponse<AvailableUserReport, 'look'>
export type PublishLookResponse = APIResponse<AvailableUserReport, 'look'>
export type PinLookResponse = APIResponse<AvailableUserReport, 'look'>
export type UnpinLookResponse = APIResponse<AvailableUserReport, 'look'>
export type ImportLookResponse = APIResponse<AvailableUserReport, 'look'>
export type SharedLooksResponse = APIResponse<AvailableUserReport[], 'looks'>
export type CopyAudienceResponse = APIResponse<AvailableUserAudience, 'audience'>
export type ActivateAudienceResponse = APIResponse<AvailableUserAudience, 'audience'>

export type AnalyticsDashboardSlug =
  | 'business_overview'
  | 'customer'
  | 'ecommerce'
  | 'marketing'
  | 'supply_chain'
  | 'products'
  | 'subscriptions'

export type ReportCreator = {
  firstName: string
  lastName: string
}

export type AvailableReport = {
  label: string
  slug: string
  metricType: LookerReportType
  url: string
  description: string
  createdBy: ReportCreator
  createdAt: string
  updatedAt: string
}

export type AudienceCategory = {
  name: string
  slug: string
}

export type AvailableAudience = {
  name: string
  slug: string
  url: string
  description: string
  enabled: boolean
  plan: string
  categories: AudienceCategory[]
}

export type AvailableUserReport = AvailableReport & {
  id: string
  folderId: string
  folderName?: string
}

export type AvailableReports = {
  dashboards: AvailableUserReport[]
  explores: AvailableUserReport[]
  looks: AvailableUserReport[]
}

export type AvailableUserReports = {
  userDashboards: AvailableUserReport[]
  userLooks: AvailableUserReport[]
}

export type AvailableAudiences = {
  audiences: AvailableAudience[]
}

export type AvailableSharedReports = {
  dashboards: AvailableUserReport[]
  looks: AvailableUserReport[]
}

export type AvailableUserAudience = AvailableAudience & {
  id: string
  label: string
  folderId: string
}

export type AvailableUserAudiences = {
  userAudiences: AvailableUserAudience[]
}

export type GetTokenResponse = {
  accessToken: string
}

export type GetEmbedUrlResponse = {
  url: string
}

export type GetEmbedUserBootstrapURLResponse = {
  url: string
}

export type AvailableQuickstarts = {
  dashboards: AvailableUserReport[]
  looks: AvailableUserReport[]
}

export type LookerReportType = 'dashboard' | 'explore' | 'audience' | 'look' | 'lookml_dashboard'

export type GetTilesRequest = {
  tenantId: number
  storeId: number
  timePeriod: DateDropdownPeriod
}

export type GetTilesResponse = {
  id: number
  label: string
  value: number
  change: number
  previous: number
  type: MetricType
}

export type LookerState = {
  token?: string
  looks: { [id: number]: any }
  tiles: GetTilesResponse[]
  metrics: Record<number, GetTilesResponse>
  availableReports: AvailableReports
  availableUserReports: AvailableUserReports
  availableAudiences: AvailableAudiences
  availableUserAudiences: AvailableUserAudiences
  availableQuickstarts: AvailableQuickstarts
  availableSharedReports: AvailableSharedReports
  embedUrls: { [slug: string]: string }
  embedUserUrls: { [slug: string]: string }
  embedAudienceUrls: { [slug: string]: string }
  embedUserAudienceUrls: { [slug: string]: string }
  embedQuickstartUrls: { [slug: string]: string }
  embedSharedReportUrls: { [slug: string]: string }
  embedUser?: LookerEmbedUser
  embedUserBootstrapURL?: string
}

export enum MetricsLookupIds {
  LifetimeNetRevenue = 1,
  ActiveSubscriptions = 2,
  Products = 3,
}

export type LookerEmbedUser = {
  id: string
  firstName: string
  lastName: string
  email: string
  personalFolderId: string
}
