import { useLookerData } from 'redux/state/looker'
import { AvailableUserReport, CreateDashboardResponse } from 'types'
import { useState } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'

interface CreateDashboardData {
  title: string
  description: string
  folderId: string
}

export const useCreateDashboard = (onCreated: (dashboard?: AvailableUserReport) => void) => {
  const { createDashboard } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const create = async ({ title, description, folderId }: CreateDashboardData) => {
    if (!createDashboard) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await createDashboard(title, description, folderId)) as PayloadAction<{
        data: CreateDashboardResponse
      }>

      if (response.payload.data?.dashboard) {
        setHasSucceeded(true)
        onCreated(response.payload.data.dashboard)
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { create, isLoading, hasSucceeded, hasFailed }
}
