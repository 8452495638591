import React from 'react'
import styled from 'styled-components'
import { TableRow } from 'components/common/table'
import { H5, standardIcons, Button, H6 } from '@chordco/component-library'
import { CensusConnector } from 'api/census/interfaces'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

type Props = {
  connector: CensusConnector
  onSelectConnector: (connector: CensusConnector) => void
}

export const ConnectorCard: React.FC<Props> = ({ connector, onSelectConnector }) => {
  const { trackEvent } = useMixpanel()
  const onClick = () => {
    onSelectConnector(connector)
    trackEvent(MixpanelEventType.ActivationTileSelected, {
      'Destination Name': connector.label,
      'Page Name': 'Data Activations',
      'Slidesheet Name': 'Add Destination',
    })
  }

  if (connector.creatableViaConnectLink === false) {
    return (
      <DisabledCard key={connector.serviceName}>
        <CardContent>
          <Header>
            <Logo src={connector.logoSmall} alt={connector.logoSmall} />
          </Header>
          <Title>{connector.label}</Title>
          <H6>This connector is not yet available for self-service setup.</H6>
        </CardContent>
      </DisabledCard>
    )
  } else {
    return (
      <Card key={connector.serviceName} onClick={onClick}>
        <CardContent>
          <Header>
            <Logo src={connector.logoSmall} alt={connector.logoSmall} />
            <Button icon={standardIcons.Plus} purpose="ghost" size="small" />
          </Header>
          <Title>{connector.label}</Title>
        </CardContent>
      </Card>
    )
  }
}

const Card = styled(TableRow)`
  height: 150px;
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};

  display: flex;
  align-content: center;

  img {
    margin-right: 16px;
  }

  :hover {
    border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
`

const DisabledCard = styled(Card)`
  cursor: not-allowed;
  opacity: 0.5;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 36px;
`

const Title = styled(H5)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  height: 24px;
  margin-top: 4px;
`

const Logo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 2px;
`
