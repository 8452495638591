import React, { useState } from 'react'
import styled from 'styled-components'
import { AvailableReport } from 'types'
import { ReportCard } from './ReportCard'
import { useHistory } from 'react-router-dom'
import { ReportToolbar } from './ReportToolbar'
import { NoResults } from './NoResults'
import {
  ReportCategory,
  ReportFilters,
  ReportType,
} from 'redux/state/reportsFilter'
import { H2 } from '@chordco/component-library'
import { UnpinModal } from './UnpinModal'
import EmptyPinnedReports from './EmptyPinnedReports'
import ScrollableContainer from 'components/common/ScrollableContainer'

export type PinnedReportsProps = {
  reports: AvailableReport[]
  onUnpinReport: () => void
}

export const PinnedReports: React.FC<PinnedReportsProps> = ({ reports, onUnpinReport }) => {
  if (reports.length === 0) return <EmptyPinnedReports />

  const history = useHistory()

  const [filteredReports, setFilteredReports] = useState(reports)
  const [showUnpinModal, setShowUnpinModal] = useState<AvailableReport | null>(null)

  const handleSelectReport = (report: AvailableReport) => {
    history.push({
      pathname: `/analytics/pinned-reports/${report.slug}`,
      state: {
        title: report.label,
        createdBy: report.createdBy,
        type: report.metricType,
      },
    })
  }

  const handleFiltersChange = (filters: ReportFilters) => {
    let filtered = reports

    switch (filters.selectedType) {
      case ReportType.DASHBOARDS:
        filtered = reports.filter(report => report.metricType === 'dashboard')
        break
      case ReportType.LOOKS:
        filtered = reports.filter(report => report.metricType === 'look')
        break
      case ReportType.EXPLORES:
        filtered = reports.filter(report => report.metricType === 'explore')
        break
      case ReportType.ALL_REPORTS:
      default:
        // Use all types of reports
        break
    }

    switch (filters.selectedCategory) {
      case ReportCategory.SHARED_REPORTS:
        filtered = filtered.filter(report => report.createdBy !== null)
        break
      case ReportCategory.CHORD_REPORTS:
        filtered = filtered.filter(report => report.createdBy === null)
        break
      case ReportCategory.ALL_CATEGORIES:
      default:
        // Use all categories of reports
        break
    }

    // Filter by searchTerm if provided
    if (filters.searchTerm && filters.searchTerm.trim() !== '') {
      const searchTerm = filters.searchTerm.toLowerCase()
      filtered = filtered.filter(
        report =>
          report.label?.toLowerCase().includes(searchTerm) ||
          report.description?.toLowerCase().includes(searchTerm)
      )
    }

    // Sort reports alphabetically
    const sorted = [...filtered].sort((a, b) => a.label.localeCompare(b.label))

    setFilteredReports(sorted)
  }

  const handleUnpinReport = (report: AvailableReport) => {
    setShowUnpinModal(report)
  }

  const closeUnpinModal = () => {
    setShowUnpinModal(null)
    onUnpinReport()
  }

  return (
    <ScrollableContainer height="calc(100vh - 120px)" padding="16px 64px 32px 64px">
      <ReportToolbar onFiltersChange={handleFiltersChange} />
      <StyledH2>Pinned Reports</StyledH2>

      {filteredReports.length > 0 && (
        <ReportsContainer>
          {filteredReports.map((report, index) => (
            <ReportCard
              key={`${report.slug}-${index}`}
              report={report}
              onSelectReport={handleSelectReport}
              onUnpinReport={handleUnpinReport}
            />
          ))}
        </ReportsContainer>
      )}

      {filteredReports.length === 0 && <NoResults />}

      {showUnpinModal && (
        <UnpinModal report={showUnpinModal} onUnpin={closeUnpinModal} onClose={closeUnpinModal} />
      )}
    </ScrollableContainer>
  )
}

const ReportsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 16px;
`

const StyledH2 = styled(H2)`
  margin-bottom: 16px;
`
