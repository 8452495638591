import React from 'react'
import JSONPretty from 'react-json-pretty'

const JSONTheme = {
  main: `
    background: #f6f8fa;
    color: #24292e;
    font-family: monospace;
    font-size: 12px;
    padding: 12px;
    line-height: 1.5;
    border-radius: 8px;
    border: 1px solid #e1e4e8;
  `,
  key: `
    color: #0366d6;
    font-weight: bold;
  `,
  string: `
    color: #032f62;
  `,
  value: `
    color: #005cc5;
  `,
  boolean: `
    color: #d73a49;
  `,
  number: `
    color: #22863a;
  `,
  error: `
    background: #ffeef0;
    color: #d73a49;
    font-weight: bold;
    padding: 4px;
  `,
  punctuation: `
    color: #6a737d;
    font-weight: bold;
  `,
  bracket: `
    color: #6a737d;
  `,
}

export const JSONViewer: React.FC<{ data?: any }> = ({ data }) => {
  if (!data) {
    return null
  }

  return <JSONPretty data={data} theme={JSONTheme} />
}
