import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Sheet, H1, Spinner } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { ConnectCard, ConnectedSource } from 'api/fivetran/interfaces'
import { useFivetran } from '../FivetranContext'
import { formatRelativeTime } from 'utils/dateTime'
import { DeleteSourceModal } from '../dialogs/DeleteSourceModal'
import { ToggleSyncModal } from '../dialogs/ToggleSyncModal'
import { SetupChip, SyncChip } from '../components'
import { ToggleSyncNowModal } from '../dialogs/ToggleSyncNowModal'
import { useAuthData } from 'redux/state/auth'
import useFivetranSlackEvent from 'hooks/useFivetranSlackEvent'
import { useTenantsData } from 'redux/state/tenants'
import { ModeledChip } from '../components/ModeledChip'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

type Props = {
  source: ConnectedSource
  modeled: boolean
  onClose: () => void
  onDisconnectSuccess: (message: string) => void
  onToggleSyncSuccess: (message: string) => void
  onFinishSetupSuccess: (connectCard: ConnectCard) => void
  onError: (message: string) => void
}

export const EditSourceSheet: React.FC<Props> = ({
  source,
  modeled,
  onClose,
  onDisconnectSuccess,
  onToggleSyncSuccess,
  onFinishSetupSuccess,
  onError,
}) => {
  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')

  const {
    state: { currentTenant },
  } = useTenantsData()

  if (!currentTenant) throw new Error('Current Tenant not found!')

  const { fivetranClient } = useFivetran()
  const { sendSlackEvent } = useFivetranSlackEvent()
  const { trackEvent } = useMixpanel()

  const isMobile = useIsMobile()
  const size = isMobile ? 48 : 64

  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false)
  const [showToggleSyncModal, setShowToggleSyncModal] = useState(false)
  const [showSyncNowModal, setShowSyncNowModal] = useState(false)

  const handleDisconnect = async () => {
    setShowDeleteAlertModal(false)
    setShowToggleSyncModal(false)
    setShowSyncNowModal(false)
    setIsLoading(true)

    try {
      const result = await fivetranClient?.deleteConnector(source.id)

      if (result?.code === 'Success') {
        await sendSlackEvent('CONNECTOR_DISCONNECTED', {
          tenantName: currentTenant.name,
          connectorName: source.service,
          destinationGroupId: source.groupId,
          hubUser: user,
        })

        onDisconnectSuccess(
          `${source.metadata.name} has been successfully disconnected`
        )
      } else {
        throw new Error(
          result?.message ??
            'An error occurred while disconnecting your data source. Please try again.'
        )
      }
    } catch (error: any) {
      onError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleToggleSync = async () => {
    setShowToggleSyncModal(false)
    setShowDeleteAlertModal(false)
    setShowSyncNowModal(false)
    setIsLoading(true)

    try {
      const isPaused = source.status.syncState === 'paused'
      const action = isPaused ? 'enabled' : 'paused'

      const result = await fivetranClient?.toggleConnectorPauseState(
        source.id,
        !isPaused
      )

      if (result?.code === 'Success') {
        onToggleSyncSuccess(
          `Syncing has been successfully ${action} for your data source ${source.metadata.name}`
        )
      } else {
        throw new Error(
          result?.message ??
            'An error occurred while updating your data source. Please try again.'
        )
      }
    } catch (error: any) {
      onError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleToggleSyncNow = async () => {
    setShowToggleSyncModal(false)
    setShowDeleteAlertModal(false)
    setShowSyncNowModal(false)
    setIsLoading(true)

    try {
      const result = await fivetranClient?.syncConnector(source.id)

      if (result?.code === 'Success') {
        onToggleSyncSuccess(
          `Sync has been successfully triggered for ${source.metadata.name}`
        )
      } else {
        throw new Error(
          result?.message ??
            'An error occurred while attempting to sync your data source. Please try again.'
        )
      }
    } catch (error: any) {
      onError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const finishSetup = async (connectorId: string) => {
    setIsLoading(true)

    try {
      const response = await fivetranClient?.createConnectCard(connectorId)

      if (response?.code === 'Success' && response?.data.connectCard) {
        onFinishSetupSuccess(response.data.connectCard)
      } else {
        throw new Error(
          response?.message ??
            'An error occurred while finishing your data source setup. Please try again.'
        )
      }
    } catch (error: any) {
      onError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const lastSyncedAt = source.succeededAt
    ? formatRelativeTime(source.succeededAt)
    : 'Never'

  const isSuperuser = user?.role === 'superuser'
  const isAdmin = user?.role === 'admin'

  const healthySyncSetup = source.status.setupState === 'connected'

  const scheduledSync =
    source.status.syncState === 'scheduled' ||
    source.status.syncState === 'rescheduled'

  // Only Chord superusers can access sync features, as they can be very disruptive.
  const shouldAllowToggleSync = isSuperuser && healthySyncSetup
  const shouldAllowSyncNow = shouldAllowToggleSync && scheduledSync

  const showFinishSetupButton = !healthySyncSetup && (isSuperuser || isAdmin)
  const showDisconnectButton = isSuperuser || isAdmin

  const trackLearnMoreLink = () => {
    trackEvent(MixpanelEventType.LinkClicked, {
      'Link Name': `Learn more about ${source.metadata.name}`,
      'Link URL': source.metadata.linkToDocs,
      'Page Name': 'Data Sources',
      'Slidesheet Name': 'Connection Details',
    })
  }

  return (
    <>
      <Sheet
        title="Connection Details"
        name="Connection Details"
        location="Data Sources"
        onClose={onClose}
        isLarge
      >
        <Container isMobile={isMobile}>
          <Logo
            src={source.metadata.iconUrl}
            alt={source.metadata.name}
            size={size}
          />
          <TitleContainer>
            <H1>{source.metadata.name}</H1>
            <ModeledChip modeled={modeled} />
          </TitleContainer>
          <DescriptionContainer>
            {source.metadata.description}
          </DescriptionContainer>
          <Link
            onClick={trackLearnMoreLink}
            href={source.metadata.linkToDocs}
            target="_blank"
          >
            Learn more about {source.metadata.name}
          </Link>

          {!isLoading && (
            <>
              <Details>
                {isSuperuser && (
                  <>
                    <Property>
                      <PropertyLabel>Source ID:</PropertyLabel>
                      <PropertyValue>{source.id}</PropertyValue>
                    </Property>

                    <Property>
                      <PropertyLabel>Schema:</PropertyLabel>
                      <PropertyValue>{source.schema}</PropertyValue>
                    </Property>

                    <Property>
                      <PropertyLabel>Group ID:</PropertyLabel>
                      <PropertyValue>{source.groupId}</PropertyValue>
                    </Property>
                  </>
                )}

                {!healthySyncSetup && (
                  <Property>
                    <PropertyLabel>Setup Status:</PropertyLabel>
                    <PropertyValue>
                      <SetupChip setupState={source.status.setupState} />
                    </PropertyValue>
                  </Property>
                )}

                <Property>
                  <PropertyLabel>Sync Status:</PropertyLabel>
                  <PropertyValue>
                    <SyncChip syncState={source.status.syncState} />
                  </PropertyValue>
                </Property>

                <Property>
                  <PropertyLabel>Last Successful Sync:</PropertyLabel>
                  <PropertyValue>{lastSyncedAt}</PropertyValue>
                </Property>
              </Details>

              {healthySyncSetup && (
                <SyncButtonsContainer>
                  {shouldAllowToggleSync && (
                    <Button
                      onClick={() => setShowToggleSyncModal(true)}
                      purpose="primary"
                      type="button"
                      disabled={isLoading}
                      name={
                        source.status.syncState === 'paused'
                          ? `Resume Sync ${source.metadata.name}`
                          : `Pause Sync ${source.metadata.name}`
                      }
                      location="Data Sources"
                    >
                      {source.status.syncState === 'paused'
                        ? 'Resume Sync'
                        : 'Pause Sync'}
                    </Button>
                  )}

                  {shouldAllowSyncNow && (
                    <Button
                      onClick={() => setShowSyncNowModal(true)}
                      purpose="secondary"
                      type="button"
                      disabled={isLoading}
                      name={`Sync Now ${source.metadata.name}`}
                      location="Data Sources"
                    >
                      Sync Now
                    </Button>
                  )}
                </SyncButtonsContainer>
              )}

              {showFinishSetupButton && (
                <Button
                  onClick={() => finishSetup(source.id)}
                  purpose="primary"
                  type="button"
                  disabled={isLoading}
                  name={`Finish Setup ${source.metadata.name}`}
                  location="Data Sources"
                >
                  Finish Setup
                </Button>
              )}
            </>
          )}

          {isLoading && (
            <LoaderContainer>
              <Spinner scale={30} />
            </LoaderContainer>
          )}

          {showDisconnectButton && (
            <Bottom isMobile={isMobile}>
              <Button
                onClick={() => setShowDeleteAlertModal(true)}
                purpose="destructive"
                variant="outlined"
                type="button"
                disabled={isLoading}
                name={`Disconnect ${source.metadata.name}`}
                location="Data Sources"
              >
                Disconnect {source.metadata.name}
              </Button>
            </Bottom>
          )}
        </Container>
      </Sheet>

      {showDeleteAlertModal && (
        <DeleteSourceModal
          source={source}
          onDelete={handleDisconnect}
          onClose={() => setShowDeleteAlertModal(false)}
        />
      )}

      {showToggleSyncModal && (
        <ToggleSyncModal
          source={source}
          onToggleSync={handleToggleSync}
          onClose={() => setShowToggleSyncModal(false)}
        />
      )}

      {showSyncNowModal && (
        <ToggleSyncNowModal
          source={source}
          onToggleSyncNow={handleToggleSyncNow}
          onClose={() => setShowSyncNowModal(false)}
        />
      )}
    </>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  min-height: calc(100vh - 76px);
  overflow: auto;
  padding: ${p =>
    `24px ${p.isMobile ? 12 : 24}px 100px ${p.isMobile ? 12 : 24}px`};
  position: relative;

  & > div {
    margin-top: 16px;
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
`

const Property = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  margin-bottom: 6px;
`

const PropertyLabel = styled.span`
  font-weight: 600;
  margin-right: 8px;
`

const PropertyValue = styled.div``

const Link = styled.a<{ pink?: boolean }>`
  color: ${p => (p.pink ? p.theme.ContentAccentPrimary : 'inherit')};
`

const SyncButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 24px;
  gap: 16px;
`

const LoaderContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`

const Logo = styled.img<{ size?: number }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 4px;
`

const Bottom = styled.div<{ isMobile: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;

  button {
    ${p => (p.isMobile ? 'width: 100%;' : '')}
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

const DescriptionContainer = styled.p`
  font-size: 14px;
`
