import React from 'react'
import { Button, Label, standardIcons } from '@chordco/component-library'
import { FormControl, Select, SelectChangeEvent, MenuItem, Box } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { StreamConfig } from 'types/cdp'

type EventToolbarProps = {
  sources: StreamConfig[]
  filters: IncomingEventFilters
  onFiltersChange: (filters: IncomingEventFilters) => void
  onRefresh: (filters: IncomingEventFilters) => void
}

type EventStatusLevel = 'all' | 'errors'

export type IncomingEventFilters = {
  source?: StreamConfig
  statusLevel: EventStatusLevel
  dateFrom: string
  dateTo: string
}

export const IncomingEventsToolbar: React.FC<EventToolbarProps> = ({
  sources,
  filters,
  onFiltersChange,
  onRefresh,
}) => {
  const handleSiteChange = (event: SelectChangeEvent<string>) => {
    const updatedSource = sources.find(stream => stream.id === event.target.value) || sources[0]
    const updatedFilters = { ...filters, source: updatedSource }
    onFiltersChange(updatedFilters)
  }

  const handleStatusChange = (statusLevel: EventStatusLevel) => {
    const updatedFilters = { ...filters, statusLevel }
    onFiltersChange(updatedFilters)
  }

  const handleDateChange = (key: 'dateFrom' | 'dateTo') => (newValue: Moment | null) => {
    const updatedFilters = {
      ...filters,
      [key]: newValue ? moment.parseZone(newValue).utcOffset(0, true).toISOString() : '',
    }
    onFiltersChange(updatedFilters)
  }

  const handleRefresh = () => {
    onRefresh(filters)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        paddingTop: 1,
        paddingBottom: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <FormControl sx={{ minWidth: 100 }} size="small">
          <Label>Sites</Label>
          <Select value={filters.source?.id || ''} onChange={handleSiteChange}>
            {sources.map(stream => (
              <MenuItem key={stream.id} value={stream.id}>
                {stream.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 100 }} size="small">
          <Label>Status</Label>
          <Select
            value={filters.statusLevel}
            onChange={(event: SelectChangeEvent<string>) =>
              handleStatusChange(event.target.value as EventStatusLevel)
            }
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="errors">Errors</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 220 }} size="small">
          <Label>Start Date</Label>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              value={filters.dateFrom ? moment.parseZone(filters.dateFrom).utc() : null}
              onAccept={handleDateChange('dateFrom')}
              closeOnSelect={false}
              format="MMM DD, HH:mm"
              slotProps={{
                textField: { size: 'small' },
                actionBar: {
                  actions: ['clear', 'cancel', 'accept'],
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl sx={{ minWidth: 220 }} size="small">
          <Label>End Date</Label>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              value={filters.dateTo ? moment.parseZone(filters.dateTo).utc() : null}
              onAccept={handleDateChange('dateTo')}
              closeOnSelect={false}
              format="MMM DD, HH:mm"
              slotProps={{
                textField: { size: 'small' },
                actionBar: {
                  actions: ['clear', 'cancel', 'accept'],
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <Button
          type="button"
          purpose="secondary"
          onClick={handleRefresh}
          icon={standardIcons.Refresh}
          name="Refresh"
          location="CDP Incoming Events"
        >
          Refresh
        </Button>
      </Box>
    </Box>
  )
}
