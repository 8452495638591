import { ReactNode } from 'react'

import amplitudeIcon from './icons/amplitude'
import bigqueryIcon from './icons/bigquery'
import clickhouseIcon from './icons/clickhouse'
import devnullIcon from './icons/devnull'
import gcsIcon from './icons/gcs'
import hubspotIcon from './icons/hubspot'
import mixpanelIcon from './icons/mixpanel'
import facebookIcon from './icons/facebook'
import juneIcon from './icons/june'
import blazeIcon from './icons/blaze'
import mongodbIcon from './icons/mongodb'
import ga4Icon from './icons/ga4'
import postgresIcon from './icons/postgres'
import mysqlIcon from './icons/mysql'
import redshiftIcon from './icons/redshift'
import posthogIcon from './icons/posthog'
import segmentIcon from './icons/segment'
import s3Icon from './icons/s3'
import snowflakeIcon from './icons/snowflake'
import intercomIcon from './icons/intercom'
import webhookIcon from './icons/webhook'

export type DestinationType = {
  id: string
  title: string
  tags: string | string[]
  icon?: ReactNode
  description: ReactNode
}

export const coreDestinations: DestinationType[] = [
  {
    id: 'clickhouse',
    icon: clickhouseIcon,
    title: 'Clickhouse',
    tags: 'Datawarehouse',
    description:
      'ClickHouse is an open-source column-oriented database management system specialized for online analytical processing of queries (OLAP).',
  },
  {
    id: 'postgres',
    icon: postgresIcon,
    title: 'Postgres',
    tags: 'Datawarehouse',
    description: 'Postgres is a powerful, open source object-relational database system.',
  },
  {
    id: 'bigquery',
    icon: bigqueryIcon,
    title: 'BigQuery',
    tags: 'Datawarehouse',
    description: 'BigQuery is a cloud-based SQL data warehouse service developed by Google.',
  },
  {
    id: 'snowflake',
    title: 'Snowflake',
    tags: 'Datawarehouse',
    icon: snowflakeIcon,
    description: 'Snowflake is an independent a cloud data warehouse with compute-based pricing.',
  },
  {
    id: 'redshift',
    icon: redshiftIcon,
    title: 'Redshift',
    tags: 'Datawarehouse',
    description:
      'Amazon Redshift is a cloud data warehouse that is optimized for the analytical workloads of business intelligence (BI) and data warehousing (DWH).',
  },
  {
    id: 'mysql',
    icon: mysqlIcon,
    title: 'Mysql',
    tags: 'Datawarehouse',
    description: 'MySQL is a popular open source object-relational database system.',
  },
  {
    id: 's3',
    icon: s3Icon,
    title: 'Amazon S3',
    tags: 'Block Storage',
    description: 'S3 is a cloud file storage service by Amazon',
  },
  {
    id: 'gcs',
    icon: gcsIcon,
    title: 'Google Cloud Storage',
    tags: 'Block Storage',
    description: 'Google Cloud Storage is a cloud file storage service by Google',
  },
  {
    id: 'mixpanel',
    icon: mixpanelIcon,
    title: 'Mixpanel',
    tags: 'Product Analytics',
    description:
      'Mixpanel is a product analytics platform that provides insights into user behavior.',
  },
  {
    id: 'intercom',
    icon: intercomIcon,
    title: 'Intercom',
    tags: 'Product Analytics',
    description: 'Intercom is a customer messaging platform.',
  },
  {
    id: 'facebook-conversions',
    icon: facebookIcon,
    title: 'Facebook Conversions API',
    tags: 'Product Analytics',
    description: 'Facebook Conversion API is a tool for sending events to Facebook Ads Manager.',
  },
  {
    id: 'june',
    icon: juneIcon,
    title: 'June.so',
    tags: 'Product Analytics',
    description:
      'June.so is a product analytics platform that provides insights into user behavior.',
  },
  {
    id: 'braze',
    icon: blazeIcon,
    title: 'Braze',
    tags: 'Product Analytics',
    description:
      'Braze is a customer engagement platform used by businesses for multichannel marketing.',
  },
  {
    id: 'mongodb',
    icon: mongodbIcon,
    title: 'MongoDB',
    tags: 'Datawarehouse',
    description: 'MongoDB is a cross-platform NoSQL document-oriented database.',
  },
  {
    id: 'ga4',
    icon: ga4Icon,
    title: 'Google Analytics 4',
    tags: 'Product Analytics',
    description:
      'Google Analytics 4 is a service offered by Google that reports website traffic data and marketing trends.',
  },
  {
    id: 'posthog',
    icon: posthogIcon,
    title: 'Posthog',
    tags: 'Product Analytics',
    description: 'Posthog is an open-source product analytics tool.',
  },
  {
    id: 'amplitude',
    icon: amplitudeIcon,
    title: 'Amplitude',
    tags: 'Product Analytics',
    description: 'Amplitude is a product analytics platform',
  },
  {
    id: 'hubspot',
    icon: hubspotIcon,
    title: 'Hubspot',
    tags: 'CRM',
    description: 'Hubspot is a CRM.',
  },
  {
    id: 'devnull',
    icon: devnullIcon,
    title: '/dev/null',
    tags: 'Special',
    description:
      'This destination does not send any data anywhere. However, you can connect a function to this destination',
  },
  {
    id: 'segment-proxy',
    icon: segmentIcon,
    title: 'Segment',
    tags: 'Special',
    description: 'Forward events for to Segment-compatible endpoint.',
  },
  {
    id: 'webhook',
    icon: webhookIcon,
    title: 'Webhook',
    tags: 'Special',
    description:
      'Send data to any HTTP endpoint. You can use this destination to send data to Slack, Discord, or any other service that accepts HTTP requests. ',
  },
]

export const coreDestinationsMap = coreDestinations.reduce((acc, destination) => {
  acc[destination.id] = destination
  return acc
}, {} as Record<string, DestinationType>)
