import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import { AvailableUserReport, PublishLookResponse } from 'types'
import { PayloadAction } from '@reduxjs/toolkit'

interface PublishLookData {
  lookName: string
  lookDescription: string
  lookSlug: string
  replaceExisting?: boolean
}

export const usePublishLook = (onPublish: (report: AvailableUserReport) => void) => {
  const { publishLook } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const publish = async ({ lookName, lookDescription, lookSlug, replaceExisting = false }: PublishLookData) => {
    if (!publishLook) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await publishLook(lookName, lookDescription, lookSlug, replaceExisting)) as PayloadAction<{
        data: PublishLookResponse
      }>

      const look = response.payload.data.look

      if (look) {
        setHasSucceeded(true)
        onPublish(look)
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { publish, isLoading, hasSucceeded, hasFailed }
}
