import React, { useRef } from 'react'
import { Header } from 'components/common'
import { useResponsiveWidth } from 'hooks'
import styled from 'styled-components'
import { ServicesStatus } from './ServicesStatus'
import { Section } from 'components/systemHealth/Section'
import stripeLogo from 'assets/StripeLogo.png'
import { useGetSystemHealthData } from './utils'
import { Column, HowToResolvePill, StatusLine } from 'components/systemHealth/common'

export const SystemHealth: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const width = useResponsiveWidth(ref)
  const isSmall = width < 925

  const { stripeWebhooks, health, tenantId, loading, failed, retryFunctions } =
    useGetSystemHealthData()

  return (
    <>
      <Header title="System Health" />
      <Content ref={ref}>
        <ServicesStatus tenantId={tenantId} isSmall={isSmall} />

        <Section
          title="Payments"
          subTitle="Stripe"
          logo={stripeLogo}
          link={stripeWebhooks?.length ? stripeWebhooks[0].url : undefined}
          operatingCorrectly={health.payment}
          isSmall={isSmall}
          isLoading={loading.payment}
          hasFailed={failed.payment}
          retryFunction={retryFunctions.getPayment}
        >
          {stripeWebhooks?.length && (
            <Column isSmall={isSmall}>
              {stripeWebhooks.map(w => (
                <StatusLine
                  key={w.id}
                  status={w.status === 'enabled' ? 'enabled' : 'disabled'}
                  label="Events Webhook"
                />
              ))}
              {!health.payment && (
                <HowToResolvePill href="https://app.gitbook.com/o/-MZD-8ejv0l5kuSCuMCS/s/-ManTxOCOZxZsL5DmR5X/provisioning/provisioning-handbook/stripe-webhooks-autonomy" />
              )}
            </Column>
          )}
        </Section>
      </Content>
    </>
  )
}

const Content = styled.div`
  padding-top: 24px;
  padding-bottom: 64px;
  line-height: 1.75;
  white-space: nowrap;
  overflow: scroll;
  height: calc(100% - 72px);
`
