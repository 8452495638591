import React, { useMemo } from 'react'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Chip,
  Link as MuiLink,
} from '@mui/material'
import { Link as ExternalLinkIcon } from '@mui/icons-material'
import { Sheet } from '@chordco/component-library'
import { IncomingEvent } from 'components/chord-cdp/types'
import { JSONViewer } from 'components/chord-cdp/common/JSONViewer'
import { ConfigurationObjectLink, DestinationConfig } from 'types/cdp'

type IncomingEventDrawerProps = {
  event: IncomingEvent
  mappedDestinations?: ConfigurationObjectLink[]
  destinations?: DestinationConfig[]
  onClose: () => void
}

/**
 * Utility function to mask sensitive data in HTTP headers
 */
const maskSensitive = (key: string, value: string) =>
  key.toLowerCase() === 'authorization' ? '*** MASKED ***' : value

/**
 * Utility function to extract user details from the event
 */
const getUserDetails = (event: IncomingEvent) => [
  { name: 'User ID', value: event.userId || '' },
  { name: 'Email', value: event.email || '' },
  { name: 'Anonymous ID', value: event.anonymousId || '' },
]

/**
 * Utility function for destinations
 */
const getDestinations = (
  destinations: string[],
  mappedDestinations?: ConfigurationObjectLink[],
  destinationConfigs?: DestinationConfig[]
) => (
  <Box display="flex" flexWrap="wrap" gap={1}>
    {destinations.map(destination => {
      const mappedDestination = mappedDestinations?.find(mc => mc.id === destination)
      const destinationConfig = destinationConfigs?.find(d => d.id === mappedDestination?.toId)

      return (
        <Chip
          key={destination}
          label={destinationConfig?.name || destination}
          color="primary"
          size="small"
        />
      )
    })}
  </Box>
)

export const IncomingEventDrawer: React.FC<IncomingEventDrawerProps> = ({
  event,
  mappedDestinations,
  destinations,
  onClose,
}) => {
  const drawerData = useMemo(() => {
    const rows: { name: React.ReactNode; value: React.ReactNode }[] = []

    if (event) {
      rows.push({ name: 'Date (UTC)', value: event.date })
      rows.push({ name: 'Source', value: event.ingestType })
      rows.push({ name: 'Message ID', value: event.messageId })
      rows.push({ name: 'Type', value: event.type })
      rows.push({ name: 'Track Event Name', value: event.event?.event || '-' })

      // Status with color-coded chips
      rows.push({
        name: 'Status',
        value: (
          <Chip
            color={
              event.status === 'FAILED'
                ? 'error'
                : event.status === 'SUCCESS'
                ? 'primary'
                : 'warning'
            }
            label={event.status}
            size="small"
          />
        ),
      })

      // Error details if any
      if (event.error) rows.push({ name: 'Error', value: event.error })

      // Add user details
      rows.push(...getUserDetails(event))

      // Page details
      rows.push({ name: 'Page Title', value: event.pageTitle || '' })
      if (event.pageURL) {
        rows.push({
          name: 'Page URL',
          value: (
            <Box>
              <MuiLink href={event.pageURL} target="_blank" rel="noopener noreferrer">
                <ExternalLinkIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
                {event.pageURL}
              </MuiLink>
            </Box>
          ),
        })
      }

      // Destinations
      rows.push({
        name: 'Destinations',
        value: getDestinations(event.destinations, mappedDestinations, destinations),
      })

      // Chord CDP Domain and Write Key
      rows.push({ name: 'Chord CDP Domain', value: event.originDomain })
      rows.push({ name: 'Write Key', value: <Typography noWrap>{event.writeKey}</Typography> })

      // HTTP Headers
      rows.push({
        name: 'HTTP Headers',
        value: (
          <Table size="small" sx={{ border: '1px solid #e0e0e0', width: '100%' }}>
            <TableBody>
              {Object.entries(event.httpHeaders || {}).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell sx={{ fontWeight: 'bold', fontFamily: 'monospace' }}>{key}</TableCell>
                  <TableCell sx={{ fontFamily: 'monospace' }}>
                    {maskSensitive(key, value as string)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ),
      })

      // Event Payload
      rows.push({
        name: 'Event Payload',
        value: <JSONViewer data={event.event} />,
      })
    }

    return rows
  }, [event, mappedDestinations, destinations])

  return (
    <Sheet
      onClose={onClose}
      title="Event Details"
      isLarge={false}
      customWidth="70%"
      location="CDP Incoming Events Details"
      name="CDP Event Details"
    >
      <Box sx={{ padding: 2 }}>
        <Table>
          <TableBody>
            {drawerData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', verticalAlign: 'top' }}>
                  {row.name}
                </TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Sheet>
  )
}
