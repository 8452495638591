import React, { useState } from 'react'
import styled from 'styled-components'
import { Loading } from 'components/common'
import { DashboardEmbed } from './DashboardEmbed'
import useEmbedUrl from './useEmbedUrl'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import { useIsMobile } from 'hooks'
import { Button, H1, H2, standardIcons } from '@chordco/component-library'
import { LookerReportType, ReportCreator } from 'types'
import { LookEmbed } from './LookEmbed'
import useCanPinReport from 'redux/hooks/useCanPinReport'
import { UnpinModal } from 'components/analytics/reports/UnpinModal'
import usePinnedReport from 'redux/hooks/usePinnedReport'

interface RouteParams {
  slug: string
}

interface LocationState {
  title: string
  createdBy: ReportCreator
  type: LookerReportType
}

export const Report: React.FC = () => {
  const { slug } = useParams<RouteParams>()
  const location = useLocation<LocationState>()

  // If the title is not passed in the location state, use the slug as the title,
  // that's better than nothing...
  const { title, type } = location.state || {
    title: slug,
    type: 'dashboard',
  }

  const { embedUrls, isLoading } = useEmbedUrl(slug, type)

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()

  const { report, isPinned } = usePinnedReport(slug)

  const canPinUnpin = useCanPinReport()

  const [showUnpinModal, setShowUnpinModal] = useState(false)

  const closeUnpinModal = () => setShowUnpinModal(false)

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <Wrapper navOpen={navOpen}>
          <Header>
            <Heading>{title}</Heading>
            <ButtonsContainer>
              <Button
                purpose="tertiary"
                icon={standardIcons.Unpin}
                onClick={() => setShowUnpinModal(true)}
                disabled={!canPinUnpin}
                tooltip={!canPinUnpin ? 'Please contact an admin to unpin this report' : ''}
                tooltipDirection="input-bottom-right"
              >
                Unpin
              </Button>
              <Button
                purpose="secondary"
                icon={standardIcons.ArrowLeft}
                onClick={() => history.push('/analytics/pinned-reports')}
              >
                Back
              </Button>
            </ButtonsContainer>
          </Header>

          <Container>
            {isLoading && <Loading />}
            {!isLoading && type === 'dashboard' && (
              <DashboardContainer>
                <DashboardEmbed url={embedUrls[slug]} />
              </DashboardContainer>
            )}
            {!isLoading && type === 'look' && (
              <LookContainer>
                <LookEmbed url={embedUrls[slug]} />
              </LookContainer>
            )}
          </Container>

          {showUnpinModal && canPinUnpin && report && isPinned && (
            <UnpinModal
              report={report}
              onUnpin={() => {
                closeUnpinModal()

                // Redirect to pinned reports page since the report was unpinned
                history.push('/analytics/pinned-reports')
              }}
              onClose={closeUnpinModal}
            />
          )}
        </Wrapper>
      )}
    </NavigationContext.Consumer>
  )
}

const Wrapper = styled.div<{ navOpen: boolean }>`
  color: ${p => p.theme.ContentPrimary};
  height: 100vh;
  width: ${p => (p.navOpen ? 'calc(100vw - 280px)' : '100vw')};
  position: fixed;
  top: 0;
  right: 0;

  // Ensure the content is scrollable due to the fixed position
  overflow-y: scroll;
  overflow-x: hidden;
`

const Header = styled.div`
  padding: 24px 32px 0px 64px;
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const LookContainer = styled.div`
  padding: 48px 64px 0 64px;
  height: 100%;
`

const DashboardContainer = styled.div`
  padding-bottom: 48px;
  height: 100%;
`
