import React from 'react'
import { useIsMobile } from '../../hooks'
import { AuthWrapper, Form } from 'components/auth/AuthWrapper'
import styled from 'styled-components'
import Construction from 'assets/Construction.svg'
import {
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
} from 'components/settings/integrations/requests/Common'

export const Maintenance: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <AuthWrapper>
      <Form isMobile={isMobile}>
        <CenteredContent>
          <ImageContainer>
            <img src={Construction} alt="Construction" />
          </ImageContainer>
          <StyledH1> We are currently under maintenance </StyledH1>
          <StyleBody>
            We&apos;re making some updates and will be back up shortly. In the
            meantime, checkout our latest{' '}
            <Link href="https://chord.launchnotes.io/"> release notes </Link>or
            subscribe to our{' '}
            <Link href="https://status.chord.co/"> status page. </Link>
          </StyleBody>
        </CenteredContent>
      </Form>
    </AuthWrapper>
  )
}

const Link = styled.a`
  color: ${p => p.theme.ContentPrimary};
  text-decoration: underline;
`
