import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

interface Props extends IconProps {
  isSmall?: boolean
}

export const ChordCommerceFullLogo: React.FC<Props> = ({ fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width="386"
      height="48"
      viewBox="0 0 386 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.6198 26.399L39.6847 25.4655C35.0664 30.0752 27.5803 30.075 22.9647 25.4657C19.5285 22.034 18.6482 17.0148 20.33 12.7717C24.5808 11.0927 29.609 11.9706 33.0467 15.4021L33.5143 15.8688L41.0872 8.30974L40.6199 7.84302C35.4876 2.71755 27.1639 2.72051 22.0296 7.84279L8.75109 21.0968C3.61657 26.2218 3.61639 34.5301 8.7512 39.653C13.8855 44.7752 22.2091 44.7782 27.3415 39.6528L40.6198 26.399ZM22.9646 8.77634C27.426 4.32552 34.5638 4.1725 39.2044 8.32234L33.5027 14.0134C29.8547 10.7158 24.8014 9.82562 20.3958 11.3403L22.9646 8.77634ZM16.3268 15.4019L18.896 12.8374C17.3111 17.4309 18.3555 22.73 22.0293 26.3989C25.703 30.0676 31.012 31.1101 35.616 29.5268L33.0402 32.0978C28.4242 36.7006 20.9428 36.6965 16.3266 32.0911C11.7098 27.4852 11.7107 20.0149 16.3238 15.4049L16.3268 15.4019ZM9.68625 22.0302L12.2596 19.4615C10.6726 24.0574 11.7155 29.3573 15.3916 33.0248C19.0664 36.6909 24.3749 37.7346 28.9779 36.1525L26.4062 38.7195C21.7908 43.3287 14.3045 43.3267 9.68613 38.7193C5.06824 34.1122 5.06807 26.6398 9.68625 22.0302Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M102.485 17.0292C100.822 15.4033 98.6477 14.5903 95.9631 14.5903C92.9576 14.5903 90.6086 15.7517 88.916 18.0745V6.66371H84.1889V37.1505H88.916V25.8703C88.916 23.6346 89.4413 21.878 90.4919 20.6005C91.5715 19.2939 93.0451 18.6407 94.9126 18.6407C96.6635 18.6407 97.9912 19.1923 98.8958 20.2957C99.8296 21.3989 100.296 23.0104 100.296 25.13V37.1505H105.024V24.8687C105.024 21.2684 104.177 18.6552 102.485 17.0292Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M77.466 35.2341C79.2752 33.7534 80.3986 31.7209 80.8364 29.1368H76.0216C75.7298 30.4434 75.1024 31.4887 74.1395 32.2726C73.1766 33.0275 71.9655 33.4049 70.5065 33.4049C68.6098 33.4049 67.0778 32.7226 65.9107 31.358C64.7726 29.9643 64.2036 28.1641 64.2036 25.9575C64.2036 23.7798 64.7726 22.0087 65.9107 20.6441C67.0778 19.2794 68.6098 18.5971 70.5065 18.5971C71.9072 18.5971 73.089 18.9746 74.052 19.7294C75.0149 20.4843 75.6569 21.5006 75.9779 22.7781H80.8364C80.3986 20.2521 79.2897 18.2631 77.5098 16.8115C75.7298 15.3306 73.4391 14.5903 70.6378 14.5903C68.391 14.5903 66.4067 15.0694 64.6851 16.0274C62.9926 16.9566 61.6795 18.2777 60.7457 19.9908C59.8119 21.7039 59.3451 23.6927 59.3451 25.9575C59.3451 28.2222 59.7974 30.2256 60.702 31.9678C61.6357 33.7099 62.9488 35.06 64.6412 36.0181C66.3338 36.9472 68.2888 37.4118 70.5065 37.4118C73.337 37.4118 75.6568 36.6859 77.466 35.2341Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M119.478 37.4118C117.23 37.4118 115.231 36.9472 113.481 36.0181C111.759 35.06 110.417 33.7244 109.454 32.0112C108.52 30.2691 108.053 28.2658 108.053 26.001C108.053 23.7653 108.52 21.7909 109.454 20.0778C110.417 18.3357 111.759 16.9856 113.481 16.0274C115.231 15.0694 117.23 14.5903 119.478 14.5903C121.724 14.5903 123.709 15.0694 125.43 16.0274C127.181 16.9856 128.524 18.3357 129.457 20.0778C130.42 21.7909 130.901 23.7653 130.901 26.001C130.901 28.2658 130.42 30.2691 129.457 32.0112C128.524 33.7244 127.181 35.06 125.43 36.0181C123.709 36.9472 121.724 37.4118 119.478 37.4118ZM119.478 33.4049C121.461 33.4049 123.052 32.7226 124.248 31.358C125.474 29.9643 126.087 28.1786 126.087 26.001C126.087 23.8234 125.474 22.0523 124.248 20.6876C123.052 19.2939 121.461 18.5971 119.478 18.5971C117.493 18.5971 115.903 19.2939 114.706 20.6876C113.51 22.0523 112.912 23.8234 112.912 26.001C112.912 28.1786 113.51 29.9643 114.706 31.358C115.903 32.7226 117.493 33.4049 119.478 33.4049Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M146.652 19.2504V14.8516H145.601C143.88 14.8516 142.509 15.1564 141.487 15.7662C140.466 16.3469 139.634 17.1599 138.992 18.2052L138.467 14.8516H134.265V37.1505H138.992V25.9575C138.992 24.0121 139.43 22.4152 140.305 21.1667C141.181 19.8892 142.596 19.2504 144.551 19.2504H146.652Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M169.9 6.66371V37.1505H165.786L165.217 33.7969C163.437 36.2068 161.001 37.4118 157.908 37.4118C155.865 37.4118 154.041 36.9617 152.436 36.0616C150.831 35.1325 149.576 33.8114 148.672 32.0984C147.767 30.3563 147.315 28.3239 147.315 26.001C147.315 23.7363 147.767 21.7474 148.672 20.0343C149.606 18.2922 150.875 16.9566 152.48 16.0275C154.085 15.0694 155.894 14.5903 157.908 14.5903C159.542 14.5903 160.971 14.8951 162.197 15.5049C163.423 16.0855 164.415 16.8985 165.174 17.9438V6.66371H169.9ZM158.695 33.4485C160.651 33.4485 162.227 32.7662 163.423 31.4016C164.619 30.0369 165.217 28.2658 165.217 26.0882C165.217 23.8525 164.619 22.0523 163.423 20.6876C162.227 19.2939 160.651 18.5971 158.695 18.5971C156.74 18.5971 155.164 19.2939 153.968 20.6876C152.772 22.0523 152.173 23.8234 152.173 26.001C152.173 28.2077 152.772 30.0079 153.968 31.4016C155.164 32.7662 156.74 33.4485 158.695 33.4485Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M371.235 37.436C364.922 37.436 360.581 32.86 360.581 25.864C360.581 19.264 364.746 14.6 370.841 14.6C376.803 14.6 380.881 18.472 381.1 25.116C381.1 25.38 381.056 25.732 381.056 26.04H362.905V26.436C362.993 31.628 366.105 35.412 371.06 35.412C374.699 35.412 377.636 33.256 378.513 29.78H380.793C379.828 34.268 376.102 37.436 371.235 37.436ZM363.08 24.148H378.689C378.338 19.264 375.225 16.58 370.884 16.58C366.807 16.58 363.431 19.616 363.08 24.148Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M347.598 37.436C341.328 37.436 337.075 32.904 337.075 26.04C337.075 19.264 341.372 14.6 347.641 14.6C352.683 14.6 356.147 17.416 357.068 22.036H354.744C353.955 18.692 351.324 16.58 347.598 16.58C342.687 16.58 339.355 20.496 339.355 26.04C339.355 31.584 342.731 35.412 347.598 35.412C351.28 35.412 353.955 33.3 354.7 30H357.068C356.191 34.532 352.64 37.436 347.598 37.436Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M334.995 14.864H336.179V17.02H334.557C329.339 17.02 328.156 21.332 328.156 25.864V37.172H325.92V14.864H327.849L328.199 19.308C329.12 16.844 330.874 14.864 334.995 14.864Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M311.635 37.436C305.322 37.436 300.981 32.86 300.981 25.864C300.981 19.264 305.146 14.6 311.241 14.6C317.203 14.6 321.281 18.472 321.5 25.116C321.5 25.38 321.456 25.732 321.456 26.04H303.305V26.436C303.392 31.628 306.505 35.412 311.46 35.412C315.099 35.412 318.036 33.256 318.913 29.78H321.193C320.228 34.268 316.502 37.436 311.635 37.436ZM303.48 24.148H319.089C318.738 19.264 315.625 16.58 311.284 16.58C307.207 16.58 303.831 19.616 303.48 24.148Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M288.994 14.6C293.642 14.6 297.193 17.504 297.193 24.236V37.172H294.913V24.324C294.913 19.264 292.589 16.668 288.731 16.668C284.566 16.668 281.935 19.924 281.804 25.028V37.172H279.568V24.544C279.568 19.396 277.156 16.668 273.298 16.668C269.045 16.668 266.415 20.364 266.415 25.82V37.172H264.179V14.864H266.108L266.459 19.616C267.599 16.844 269.878 14.6 273.693 14.6C277.2 14.6 279.962 16.492 281.015 20.188C282.286 16.888 284.917 14.6 288.994 14.6Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M251.016 14.6C255.664 14.6 259.215 17.504 259.215 24.236V37.172H256.935V24.324C256.935 19.264 254.612 16.668 250.753 16.668C246.588 16.668 243.958 19.924 243.826 25.028V37.172H241.59V24.544C241.59 19.396 239.179 16.668 235.32 16.668C231.067 16.668 228.437 20.364 228.437 25.82V37.172H226.201V14.864H228.13L228.481 19.616C229.621 16.844 231.901 14.6 235.715 14.6C239.222 14.6 241.985 16.492 243.037 20.188C244.308 16.888 246.939 14.6 251.016 14.6Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M211.06 37.436C204.79 37.436 200.406 32.772 200.406 25.996C200.406 19.264 204.79 14.6 211.06 14.6C217.33 14.6 221.758 19.264 221.758 25.996C221.758 32.772 217.33 37.436 211.06 37.436ZM211.06 35.412C216.014 35.412 219.434 31.54 219.434 25.996C219.434 20.496 216.014 16.58 211.06 16.58C206.106 16.58 202.686 20.496 202.686 25.996C202.686 31.54 206.106 35.412 211.06 35.412Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M187.422 37.436C181.153 37.436 176.9 32.904 176.9 26.04C176.9 19.264 181.197 14.6 187.466 14.6C192.508 14.6 195.972 17.416 196.893 22.036H194.569C193.78 18.692 191.149 16.58 187.422 16.58C182.512 16.58 179.18 20.496 179.18 26.04C179.18 31.584 182.556 35.412 187.422 35.412C191.105 35.412 193.78 33.3 194.525 30H196.893C196.016 34.532 192.464 37.436 187.422 37.436Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
    </svg>
  )
}
