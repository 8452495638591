import { useSystemHealthData } from 'redux/state/systemHealth'
import { useEffect } from 'react'
import { useHasFailed, useIsIdle, useIsLoading } from 'redux/utils'
import { HealthStatusType } from 'components/systemHealth/common'
import { useTenantsData } from 'redux/state/tenants'

export const useGetSystemHealthData = () => {
  const {
    state: { currentTenant },
  } = useTenantsData()
  const tenantId = currentTenant?.id
  const tenantName = currentTenant?.metadata?.omsTenantName

  const {
    state: { stripeWebhooks, health },
    getStripeWebhooks,
  } = useSystemHealthData()

  const getPayment = () => {
    if (!stripeWebhooks && tenantId && tenantName) getStripeWebhooks(tenantId, tenantName)
  }

  useEffect(() => {
    getPayment()
  }, [tenantId])

  const isStoreSetupIdle = useIsIdle('getStoreSetup')
  const isStoreSetupLoading = useIsLoading('getStoreSetup')
  const areIntegrationsLoadingOrIdle = isStoreSetupIdle || isStoreSetupLoading

  const isGetStripeWebhooksLoading =
    useIsLoading('getStripeWebhooks') || areIntegrationsLoadingOrIdle
  const isGetStripeWebhooksFailed = useHasFailed('getStripeWebhooks') || !tenantId

  return {
    tenantId,
    stripeWebhooks,
    health,
    loading: {
      payment: isGetStripeWebhooksLoading,
    },
    failed: {
      payment: isGetStripeWebhooksFailed,
    },
    retryFunctions: {
      getPayment,
    },
  }
}

export const getHealthStatus = (state?: string): HealthStatusType => {
  switch (state) {
    case 'building':
      return 'inProgress'

    case 'ready':
      return 'enabled'

    case 'error':
      return 'disabled'

    default:
      return 'inProgress'
  }
}
