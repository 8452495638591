import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useIsMobile } from 'hooks'
import { Button, H1, H2, standardIcons } from '@chordco/component-library'
import { Loading } from 'components/common'
import { DashboardEmbed } from './DashboardEmbed'
import { ExploreEmbed } from './ExploreEmbed'
import useEmbedQuickstartUrl from './useEmbedQuickstartUrl'
import { CopyDashboardModal } from './CopyDashboardModal'
import { AvailableUserReport, LookerReportType } from 'types'
import { LookEmbed } from './LookEmbed'
import { CopyLookModal } from './CopyLookModal'
import useCanPinReport from 'redux/hooks/useCanPinReport'
import { UnpinModal } from 'components/analytics/reports/UnpinModal'
import { usePinReport } from './usePinReport'
import usePinnedReport from 'redux/hooks/usePinnedReport'

interface RouteParams {
  slug: string
}

interface LocationState {
  id: string
  title: string
  description: string
  type: LookerReportType
}

export const Quickstart: React.FC = () => {
  const { slug } = useParams<RouteParams>()
  const location = useLocation<LocationState>()

  const { id, title, description, type } = location.state

  if (!id || !title || !type) {
    // eslint-disable-next-line no-console
    console.error(
      'Warning: quickstart data is missing (id, title, or type):',
      location.state
    )

    const history = useHistory()
    history.push('/analytics/quickstarts')
    return null
  }

  const { embedQuickstartUrls, isLoading } = useEmbedQuickstartUrl(slug, type)

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()

  const [showCopyDashboardModal, setShowCopyDashboardModal] = useState(false)
  const [showCopyLookModal, setShowCopyLookModal] = useState(false)

  const handleDashboardCopied = (dashboard?: AvailableUserReport) => {
    setShowCopyDashboardModal(false)
    if (dashboard) {
      history.push(`/analytics/my-reports/${dashboard.slug}`)
    }
  }

  const handleLookCopied = (look?: AvailableUserReport) => {
    setShowCopyLookModal(false)
    if (look) {
      history.push(`/analytics/my-reports/${look.slug}`)
    }
  }

  const handleUseAsTemplate = () => {
    if (type === 'dashboard' || type === 'lookml_dashboard') {
      setShowCopyDashboardModal(true)
    } else if (type === 'look') {
      setShowCopyLookModal(true)
    }
  }

  const canPinUnpin = useCanPinReport()

  const { report, isPinned } = usePinnedReport(slug)

  const [showUnpinModal, setShowUnpinModal] = useState(false)

  const { pin, isLoading: isPinning } = usePinReport()

  const handlePinReport = async () => {
    await pin({
      reportId: id,
      reportType: type,
    })
  }

  const closeUnpinModal = () => setShowUnpinModal(false)

  return (
    <Wrapper addExtraPadding={type === 'look'}>
      <BackButton
        onClick={() => history.push('/analytics/quickstarts')}
        icon={standardIcons.ArrowLeft}
        variant="ghost"
      >
        Back
      </BackButton>
      <Header>
        <TextContainer>
          <Heading>{title}</Heading>
          <SubHeading>{description}</SubHeading>
        </TextContainer>
        <ButtonsContainer>
          {isPinned ? (
            <Button
              purpose="tertiary"
              icon={standardIcons.Unpin}
              onClick={() => setShowUnpinModal(true)}
              disabled={!canPinUnpin}
              tooltip={
                !canPinUnpin
                  ? 'Please contact an admin to unpin this report'
                  : ''
              }
              tooltipDirection="input-bottom-right"
            >
              Unpin
            </Button>
          ) : (
            <Button
              purpose="tertiary"
              icon={standardIcons.Pin}
              onClick={handlePinReport}
              disabled={!canPinUnpin || isPinning}
              isLoading={isPinning}
              tooltip={
                !canPinUnpin ? 'Please contact an admin to pin this report' : ''
              }
              tooltipDirection="input-bottom-right"
            >
              Pin
            </Button>
          )}

          <Button
            purpose="secondary"
            icon={standardIcons.Plus}
            onClick={handleUseAsTemplate}
          >
            Use Quickstart
          </Button>
        </ButtonsContainer>
      </Header>

      <Container>
        {isLoading && <Loading />}

        {!isLoading &&
          (type === 'dashboard' || type === 'lookml_dashboard') && (
            <DashboardEmbed url={embedQuickstartUrls[slug]} />
          )}

        {!isLoading && type === 'explore' && (
          <ExploreEmbed url={embedQuickstartUrls[slug]} />
        )}

        {!isLoading && type === 'look' && (
          <LookEmbed url={embedQuickstartUrls[slug]} />
        )}
      </Container>

      {showCopyDashboardModal && (
        <CopyDashboardModal
          id={id}
          title={title}
          reportType={type}
          description={description}
          onClose={() => setShowCopyDashboardModal(false)}
          onCopied={handleDashboardCopied}
        />
      )}

      {showCopyLookModal && (
        <CopyLookModal
          id={id}
          title={title}
          description={description}
          onClose={() => setShowCopyLookModal(false)}
          onCopied={handleLookCopied}
        />
      )}

      {showUnpinModal && canPinUnpin && isPinned && report && (
        <UnpinModal
          report={report}
          onUnpin={closeUnpinModal}
          onClose={closeUnpinModal}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ addExtraPadding: boolean }>`
  padding: ${p => (p.addExtraPadding ? '36px 64px 0 64px' : '0')};
  height: 100vh;
`

const BackButton = styled(Button)`
  padding-left: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  padding: 48px 0;
  height: 100%;
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const SubHeading = styled.div`
  font-size: 16px;
  color: ${p => p.theme.ContentTertiary};
`
