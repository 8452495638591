import React, { useCallback, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import {
  useTheme,
  standardIcons,
  Sheet,
  Spinner,
} from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { useCensus } from '../CensusContext'

import { ConnectDestinationSheet } from './ConnectDestinationSheet'
import { ConnectorBrowser } from '../components'

import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'

import { actions, reducer, initialState } from '../store'
import { CensusConnectLink, CensusConnector } from 'api/census/interfaces'

const { CloseX } = standardIcons

type Props = {
  onClose: () => void
}

export const AddDestinationSheet: React.FC<Props> = ({ onClose }) => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  const { addNotification } = useNotifications()

  const { censusClient } = useCensus()

  // Use useReducer hook to manage all our pagination state
  const [state, dispatch] = useReducer(reducer, initialState)

  const [selectedDestination, setSelectedDestination] =
    useState<CensusConnector>()

  /**
   * Fetches connectors from the Census API, using pagination.
   */
  const fetchConnectors = useCallback(
    async (page: number) => {
      dispatch(actions.fetchStart())

      try {
        const response = await censusClient?.getPaginatedConnectors(page)

        if (response) {
          const { data, pagination } = response
          const connectors = data ?? []

          dispatch(
            actions.fetchSuccess(
              connectors,
              pagination.page,
              pagination.nextPage,
              pagination.prevPage
            )
          )
        } else {
          dispatch(actions.fetchError(new Error('No data returned')))
        }
      } catch (error: any) {
        dispatch(actions.fetchError(error))
      }
    },
    [censusClient]
  )

  /**
   * Fetches allowed connectors from the Census API for the current tenant.
   */
  // const fetchAllowedConnectors = useCallback(async () => {
  //   dispatch(actions.fetchStart())

  //   try {
  //     const connectors = await censusClient?.getAllowedConnectors()
  //     dispatch(actions.fetchSuccess(connectors, 1, 1))
  //   } catch (error: any) {
  //     dispatch(actions.fetchError(error))
  //   }
  // }, [censusClient])

  /**
   * Fetch initial page of connectors on mount
   */
  // useEffect(() => {
  //   fetchAllowedConnectors()
  // }, [fetchAllowedConnectors])

  useEffect(() => {
    fetchConnectors(1)
  }, [fetchConnectors])

  /**
   * Fetch connectors for the next page
   */
  const handleNextPage = useCallback(() => {
    if (state.nextPage) {
      fetchConnectors(state.nextPage)
    }
  }, [state.nextPage, fetchConnectors])

  /**
   * Fetch connectors for the previous page
   */
  const handlePrevPage = useCallback(() => {
    if (state.previousPage) {
      fetchConnectors(state.previousPage)
    }
  }, [state.previousPage, fetchConnectors])

  const handleConnectSuccess = (connectLink: CensusConnectLink) => {
    // Start the Connect with Census external flow
    window.open(connectLink.uri, '_blank', 'noreferrer')
  }

  const handleConnectError = (message: string) => {
    addNotification({
      id: generateUUID(),
      type: 'warning',
      message: message,
    })
  }

  return (
    <Sheet
      title="Add Destination"
      onClose={onClose}
      customWidth="70%"
      isLarge={false}
      name="Add Destination"
      location="Data Activations"
    >
      <Container isMobile={isMobile}>
        {state.error && (
          <ErrorContainer>
            <div>An error occurred while fetching destinations. Please try again.</div>
            <span onClick={() => dispatch(actions.fetchError(undefined))}>
              <CloseX fill={theme.GREY_80} />
            </span>
          </ErrorContainer>
        )}

        {selectedDestination && (
          <ConnectDestinationSheet
            connector={selectedDestination}
            onClose={() => setSelectedDestination(undefined)}
            onConnectSuccess={handleConnectSuccess}
            onConnectError={handleConnectError}
          />
        )}

        {state.loading && (
          <LoaderContainer>
            <Spinner scale={30} />
          </LoaderContainer>
        )}

        {!state.loading && (
          <>
            {state.items && state.items.length > 0 ? (
              <ConnectorBrowser
                connectors={state.items as CensusConnector[]}
                onSelectConnector={setSelectedDestination}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
                showNextPageButton={state.nextPage !== null}
                showPreviousPageButton={state.previousPage !== null}
              />
            ) : (
              <Warning>No data destinations found</Warning>
            )}
          </>
        )}
      </Container>
    </Sheet>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  padding: ${p => (p.isMobile ? 12 : 24)}px;
  min-height: calc(100vh - 76px);
  overflow: auto;
  padding: ${p =>
    `8px ${p.isMobile ? 12 : 24}px 100px ${p.isMobile ? 12 : 24}px`};
  position: relative;

  & > div {
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const LoaderContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`

const ErrorContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${p => p.theme.RED_30};
  border-radius: 8px;
  color: ${p => p.theme.GREY_80};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;

  & > span {
    cursor: pointer;
  }
`

const Warning = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${p => p.theme.AMBER_30};
  padding: 12px;
  font-size: 12px;
  color: ${p => p.theme.GREY_80};
`
