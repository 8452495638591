import React from 'react'
import styled from 'styled-components'
import { Body, Button, H2, Modal } from '@chordco/component-library'
import CreateNewLook from 'assets/CreateNewLook.png'

interface CreateLookModalProps {
  onClose: () => void
  onCreateLook: () => void
}

export const CreateLookModal: React.FC<CreateLookModalProps> = ({
  onClose,
  onCreateLook,
}) => {
  return (
    <Modal
      onClose={onClose}
      title="Create New Look"
      width="640px"
      padding="0px"
    >
      <Content>
        <ImageContainer>
          <img
            src={CreateNewLook}
            alt="Create New Look"
            height="235px"
            width="480px"
          />
        </ImageContainer>

        <TextContainer>
          <H2>Create New Look</H2>
          <StyledBody>
            To create a new Look, begin by selecting one of our Quickstarts.
            This will give you a starting point that you can customize as
            needed. Would you like to continue?
          </StyledBody>
        </TextContainer>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button purpose="primary" onClick={onCreateLook}>
            Choose a Quickstart
          </Button>
        </BottomSection>
      </Content>
    </Modal>
  )
}

const Content = styled.div`
  padding: 0px 24px 0px 24px;
  border-top: 1px solid ${p => p.theme.BorderHairline};
`

const StyledBody = styled(Body)`
  text-align: center;
  line-height: 1.75;
`

const ImageContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
