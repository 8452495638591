import { PayloadAction } from '@reduxjs/toolkit'
import { useState } from 'react'
import { useLookerData } from 'redux/state/looker'
import { AvailableUserReport, ImportLookResponse } from 'types'

interface ImportLookData {
  reportSlug: string
  folderId: string
}

export const useImportLook = (
  onImported: (report?: AvailableUserReport) => void
) => {
  const { importReport } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const submit = async ({ reportSlug, folderId }: ImportLookData) => {
    if (!importReport) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await importReport(
        reportSlug,
        folderId,
        'look'
      )) as PayloadAction<{ data: ImportLookResponse }>

      if (response.payload.data?.look) {
        setHasSucceeded(true)
        onImported(response.payload.data.look)
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { submit, isLoading, hasSucceeded, hasFailed }
}
