import { createSlice } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import { useStateSliceAndDispatch } from 'redux/utils'
import type { SystemHealthState } from 'types/systemHealth'

const SLICE_NAME = 'systemHealth'

const initialState: SystemHealthState = {
  stripeWebhooks: undefined,
  health: {
    payment: true,
  },
}

const { getStripeWebhooks } = reduxApiClient

const systemHealthSlice = createSlice({
  name: 'systemHealth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStripeWebhooks.fulfilled, (state, action) => {
      state.stripeWebhooks = action.payload.data

      const allWebhooksEnabled = action.payload.data?.every(w => w.status === 'enabled')
      if (!allWebhooksEnabled) state.health.payment = false
    })
  },
})

export default systemHealthSlice.reducer

export const useSystemHealthData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    getStripeWebhooks: (tenantId: number, tenantName: string) =>
      dispatch(getStripeWebhooks({ tenantId, tenantName })),
  }
}
