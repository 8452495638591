import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'reportsFilter'

export enum ReportType {
  ALL_REPORTS = 'All Reports',
  DASHBOARDS = 'Dashboards',
  EXPLORES = 'Explores',
  LOOKS = 'Looks',
}

export enum ReportCategory {
  ALL_CATEGORIES = 'All Categories',
  SHARED_REPORTS = 'Shared Reports',
  CHORD_REPORTS = 'Chord Reports',
}

interface InitialState {
  selectedType: ReportType
  selectedCategory: ReportCategory
  searchTerm?: string
}

export interface ReportFilters {
  selectedType: ReportType
  selectedCategory: ReportCategory
  searchTerm?: string
}

const initialState: InitialState = {
  selectedType: ReportType.ALL_REPORTS,
  selectedCategory: ReportCategory.ALL_CATEGORIES,
  searchTerm: '',
}

const reportsFilterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<ReportFilters>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetFilters: () => initialState,
  },
})

export const { setFilters } = reportsFilterSlice.actions

export default reportsFilterSlice.reducer

export const useReportsFilter = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    setFilters: (filters: ReportFilters) => {
      dispatch(setFilters(filters))
    },
  }
}
